import type { SchoolEstablishmentResource } from 'src/common/types';
import * as actionTypes from '../ActionTypes';

export const fetchSchoolEstablishments = (
  schoolEstablishments: SchoolEstablishmentResource[],
  isLoading?: boolean,
  isEmptyList?: boolean,
  isAuthorize?: boolean
): {
  type: 'FETCH_SCHOOL_ESTABLISHMENTS';
  payload: { schoolEstablishments: SchoolEstablishmentResource[]; isLoading?: boolean; isEmptyList?: boolean; isAuthorize?: boolean;};
} => ({
  type: actionTypes.FETCH_SCHOOL_ESTABLISHMENTS,
  payload: {
    schoolEstablishments,
    isLoading,
    isEmptyList,
    isAuthorize,
  },
});

export const fetchSchoolEstablishment = (schoolEstablishment: SchoolEstablishmentResource, isLoading?: boolean) : { 
  type: 'FETCH_SCHOOL_ESTABLISHMENT'; 
  payload: { 
    schoolEstablishment: SchoolEstablishmentResource,
    isLoading?: boolean
  } 
} => ({
  type: actionTypes.FETCH_SCHOOL_ESTABLISHMENT,
  payload: {
    schoolEstablishment,
    isLoading
  },
});

export const deleteSchoolEstablishment = (id: number) : {
  type: 'DELETE_SCHOOL_ESTABLISHMENT';
  payload: {
    id: number;
  };
} => ({
  type: actionTypes.DELETE_SCHOOL_ESTABLISHMENT,
  payload: {
   id
  },
});