import React, { useEffect } from 'react';
import type { FC, ChangeEvent } from 'react';
import PropTypes from 'prop-types';
import type { ContentPublicationsResource, HandleChange } from 'src/common/types';
import { Box, TextField } from '@mui/material';
import { 
  labelConfig, 
  CaijInput, 
  FormikErrors, 
  FormikTouched, 
  CaijImage,
  CaijSwitch, 
  handleChangeSwitch, 
  CaijDatePicker,
  ContentPublicationsModel,
  DocLanguage,
  getOptionLabel
} from 'src/common';
import UploadImage from 'src/components/image/UploadImage';
import {$enum} from "ts-enum-util";
import useContentPublication from 'src/hooks/useContentPublication';
import useCollection from 'src/functions/hooks/collection';

interface ContentPublicationFormProps {
  model: ContentPublicationsModel,
  contentPublication: ContentPublicationsResource;
  errors: FormikErrors<ContentPublicationsResource>;
  touched: FormikTouched<ContentPublicationsResource>;
  onHandleBlur: (e: ChangeEvent<any>) => void;
  onHandleChange: HandleChange;
  onHandleChangeImageUrl:(name: string,imageUrl: string, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
}

const ContentPublicationForm: FC<ContentPublicationFormProps> = ({
  model,
  contentPublication,
  errors,
  touched,
  onHandleBlur,
  onHandleChange,
  onHandleChangeImageUrl,
  setFieldValue,
}) => {
  const { getContentTypes } = useContentPublication();
  const docCollection = useCollection();
  const { code, title, collection, cover, lang, authors, callnumber, publisher, visible, searchable, published, documentType, isFrequentlyConsulted } = contentPublication;
  const { Code, Authors, Titre, Collection, DocumentType, Callnumber, Published, Cover, Lang, IsFrequentlyConsulted, Visible, Searchable, Publisher } = model;

  useEffect(() => {
    if(!visible){
      setFieldValue('searchable', false);
    }
  },[visible])
 
  return (
    <>
      <CaijInput
        disabled={true}
        required
        name={Code.Name}
        id={Code.Name}
        value={code}
        helperText={touched.code && errors.code}
        error={Boolean(touched.code && errors.code)}
        label={Code.Label}
        InputLabelProps={{ shrink: true, required: true }}
        inputAttr={{maxLength: Code.MaxLength, 'data-testid': Code.Name }}
        onHandleBlur={onHandleBlur}
        onHandleChange={onHandleChange}
        setFieldValue={setFieldValue}
      />
      <CaijInput
        required
        name={Titre.Name}
        id={Titre.Name}
        value={title}
        helperText={touched.title && errors.title}
        error={Boolean(touched.title && errors.title)}
        label={Titre.Label}
        InputLabelProps={{ shrink: true, required: true }}
        inputAttr={{ maxLength: Titre.MaxLength, 'data-testid': Titre.Name}}
        onHandleBlur={onHandleBlur}
        onHandleChange={onHandleChange}
        setFieldValue={setFieldValue}
      />
     <Box>
        <CaijSwitch
          name={Visible.Name}
          label={Visible.Label}
          checked={visible}
          value={visible}
          sx={{mb:2}}
          inputProps={{ 'data-testid': Visible.Name }}
          onHandleChangeSwitch={handleChangeSwitch}
          setFieldValue={setFieldValue}
        />
      </Box>
      <Box>
        <CaijSwitch
          name={Searchable.Name}
          label={Searchable.Label}
          disabled={!visible}
          checked={searchable}
          value={searchable}
          sx={{mb:2}}
          inputProps={{ 'data-testid': Searchable.Name }}
          onHandleChangeSwitch={handleChangeSwitch}
          setFieldValue={setFieldValue}
        />
      </Box>
      <Box>
        <CaijSwitch
          name={IsFrequentlyConsulted.Name}
          label={IsFrequentlyConsulted.Label}
          checked={isFrequentlyConsulted}
          value={isFrequentlyConsulted}
          sx={{mb:2}}
          inputProps={{ 'data-testid': IsFrequentlyConsulted.Name }}
          onHandleChangeSwitch={handleChangeSwitch}
          setFieldValue={setFieldValue}
        />
      </Box>
      <Box mb={1}>
        <CaijInput
          name={Authors.Name}
          id={Authors.Name}
          value={authors}
          label={Authors.Label}
          helperText={touched.authors && errors.authors}
          error={Boolean(touched.authors && errors.authors)}
          InputLabelProps={{ shrink: true }}
          inputAttr={{ maxLength: Authors.MaxLength, 'data-testid': Authors.Name }}
          onHandleBlur={onHandleBlur}
          onHandleChange={onHandleChange}
          setFieldValue={setFieldValue}
        />
      </Box>
      { cover && (
          <Box mb={3}>
            <CaijImage id='cover' src={cover} alt={cover} />
          </Box>
        )
      }
      <Box mb={3}>
        <UploadImage
          imgLabel={Cover.Label}
          maxLength={Cover.MaxLength}
          resourceCode={model.ResourceCode}
          btnText={cover ? labelConfig.coverEditBtn : labelConfig.coverBtn}
          imgName={Cover.Name}
          onHandleChangeImageUrl={onHandleChangeImageUrl}
          setFieldValue={setFieldValue}
        />
      </Box>
      <TextField
        required
        label={Lang.Label}
        id={Lang.Name}
        name={Lang.Name}
        onChange={(e:ChangeEvent<HTMLInputElement>) => {
          setFieldValue(Lang.Name,e.target.value);
        }}
        select
        SelectProps={{ native: true }}
        value={lang}
        variant='outlined'
        InputLabelProps={{ shrink: true, required: true }}
        inputProps={{ 'data-testid': Lang.Name }}
        sx={{ marginBottom: 3, marginTop: 2 }}
      >
        <option disabled value=''>{getOptionLabel(Lang.Label, true)}</option>
        {
          $enum(DocLanguage).map((value, key) => <option value={key} key={key}>{value}</option>)
        }
      </TextField>
      <TextField
        label={Collection.Label}
        id={Collection.Name}
        name={Collection.Name}
        required
        onChange={(e: ChangeEvent<HTMLInputElement>) => onHandleChange(e,setFieldValue)}
        select
        SelectProps={{ native: true }}
        value={docCollection.docCollections?.some(d => d.code === collection) ? collection : ''}
        variant='outlined'
        InputLabelProps={{ shrink: true, required: true }}
        inputProps={{ 'aria-label': Collection.Name, 'data-testid': Collection.Name }}
        helperText={touched.collection && errors.collection}
        error={Boolean(touched.collection && errors.collection)}
        sx={{ mt: 2, mb: 3, width: '100%' }}
      >
        <option value='' disabled>{getOptionLabel(Collection.Label, true)}</option>
        {  docCollection.allChecked && docCollection.docCollections.map(({id, code, nameFr}) => (
              <option key={id} value={code}>
                {nameFr}
              </option>
            ))
        }
      </TextField>
      <TextField
        required
        label={DocumentType.Label}
        id={DocumentType.Name}
        name={DocumentType.Name}
        onChange={(e: ChangeEvent<HTMLInputElement>) => onHandleChange(e,setFieldValue)}
        onBlur={(e) => onHandleBlur(e)}
        select
        SelectProps={{ native: true }}
        value={documentType || ''}
        variant='outlined'
        InputLabelProps={{ shrink: true, required: true }}
        inputProps={{ 'aria-label': DocumentType.Name, 'data-testid': DocumentType.Name }}
        helperText={touched.documentType && errors.documentType}
        error={Boolean(touched.documentType && errors.documentType)}
        sx={{ mt: 2, mb: 3, width: '100%' }}
      >
        <option value='' disabled>{getOptionLabel(DocumentType.Label)}</option>
        { getContentTypes() }
      </TextField>
      <CaijInput
        required
        name={Publisher.Name}
        id={Publisher.Name}
        value={publisher}
        label={Publisher.Label}
        helperText={touched.publisher && errors.publisher}
        error={Boolean(touched.publisher && errors.publisher)}
        InputLabelProps={{ shrink: true, required: true }}
        inputAttr={{ maxLength: Publisher.MaxLength, 'data-testid': Publisher.Name }}
        onHandleBlur={onHandleBlur}
        onHandleChange={onHandleChange}
        setFieldValue={setFieldValue}
        sx={{ mb: 2 }}
      />
      <CaijDatePicker 
        name={Published.Name}
        required
        value={String(published)}
        label={Published.Label}
        inputProps={{"data-testid": Published.Name, 'aria-label': Published.Name}}
        helperText={touched.published && errors.published}
        onHandleChange={(l) => setFieldValue(Published.Name,l)}
      />
      <CaijInput
        name={Callnumber.Name}
        id={Callnumber.Name}
        value={callnumber}
        label={Callnumber.Label}
        helperText={touched.callnumber && errors.callnumber}
        error={Boolean(touched.callnumber && errors.callnumber)}
        InputLabelProps={{ shrink: true }}
        inputAttr={{ maxLength: Callnumber.MaxLength, 'data-testid': Callnumber.Name }}
        onHandleBlur={onHandleBlur}
        onHandleChange={onHandleChange}
        setFieldValue={setFieldValue}
      />
    </>
  );
};

ContentPublicationForm.propTypes = {
  contentPublication: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  onHandleBlur: PropTypes.func.isRequired,
  onHandleChangeImageUrl: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  onHandleChange: PropTypes.func.isRequired
};

export default ContentPublicationForm;


