import { SchoolEstablishmentModel } from 'src/common';
import * as Yup from 'yup';

const validateSchema = (model: SchoolEstablishmentModel) => {
  return Yup.object().shape({
    name: model.Name.required(true),
    emailDomains: Yup.array().of(
      Yup.object().shape({
        domain: model.Domain.required(true),
      })
    ),
  })
};

export default validateSchema;