import { Box, Container, Divider, Tab, Tabs } from '@mui/material';
import type { ChangeEvent, FC } from 'react';
import React, { useCallback, useEffect, useReducer, useState } from 'react';
import {
  AccessModel,
  setHash,
  useLocation,
  useNavigate,
  useParams,
  root,
  SchoolEstablishmentModel,
  fetchSchoolEstablishment,
  _schoolEstablishment,
  setEditHash,
} from 'src/common';
import Page from 'src/components/Page';
import LoadingScreen from 'src/components/loading/LoadingScreen';
import GenericHeader from 'src/components/header/GenericHeader';
import DetailsEditForm from './Forms/DetailsEditForm';
import Details from './Details';

const initialState = {
  isLoading: true,
  schoolEstablsement: {
    name: '',
    validateEmailDomain: false,
    notarialLawMasterOffer: false,
    emailDomains: []
  },
};

interface TabsSchoolEstablishment {
  value: string, label: string
}

const tabs: TabsSchoolEstablishment[] = [
  { value: 'details', label: 'Détails' },
];

const SchoolEstablishmentDetailsView: FC = () => {
  const model = new SchoolEstablishmentModel();
  const access = new AccessModel(model.ResourceCode);
  const location = useLocation();
  const [state, dispatch] = useReducer(_schoolEstablishment, initialState);
  const [editMode, setEditMode] = useState({
    details: false
  });
  const [currentTab, setCurrentTab] = useState<string>(location.search? tabs[3].value : tabs[0].value);
  const { id } = useParams();
  const navigate = useNavigate();
 
  const fetchData = useCallback(async () => {
    if(access.canEdit(id)){
      const model = new SchoolEstablishmentModel();
      model.PathName =  location.pathname;
      if(id && isNaN(+id)){
        navigate(model.Path.Home);
      }else if (id) {
        const schoolEstablishment = await model.getSchoolEstablishmentById(id);
        if (!model.error) {
          dispatch(fetchSchoolEstablishment(schoolEstablishment, false));
        }
      }
    }
  },[id, location]);

  const handleBrowserRefresh = () => {
    const hash = location.hash;
    if(hash){
      const removedHashSymbol = hash.substring(1, hash.length);
      const hashVal = removedHashSymbol.split('-');
      const tabVal = hashVal.length === 1 ? hashVal[0] : hashVal[1];
      const index = tabs.findIndex((tab: TabsSchoolEstablishment) => tab.value === tabVal);
      if(index !== -1){
        setCurrentTab(tabVal);
        if(removedHashSymbol.indexOf('edit') !== -1){
          setEditMode({...editMode, [tabVal]:true})
        }
      }else{
        setHash(tabs[0].value, navigate);
      }
    }else{
      setHash(tabs[0].value, navigate);
    }
  }
  
  useEffect(() => {
    if (!id || isNaN(+id)) {
      navigate(model.Path.Home);
    }else if(access.canRead()){
      (async () => await fetchData())();
      handleBrowserRefresh();
    }
  }, [id]);

  const { isLoading, schoolEstablishment } = state;

  if (isLoading) {
    return <LoadingScreen />;
  }

  model.Title = schoolEstablishment.name;

  const setEditModeSchoolEstablishment = (isEdit: boolean) => {
    setEditMode({
      ...editMode,
      [currentTab]: isEdit,
    });
  };

  const switchMode = (isEdit: boolean, value:string) => {
    setHash(value,navigate);
    setEditModeSchoolEstablishment(isEdit);
  };

  const handleTabsChange = async (event: ChangeEvent<any>, value: string): Promise<void> => {
    event.preventDefault();
    setHash(value, navigate);
    setEditModeSchoolEstablishment(false);
    setCurrentTab(value);
  };

  const reload = async (isEdit: boolean, value: string): Promise<void> => {
    setHash(value, navigate);
    await fetchData();
    setEditModeSchoolEstablishment(isEdit);
  };

  const editSchoolEstablishment = () => {
    setEditHash('edit', navigate);
    setEditModeSchoolEstablishment(true);
  };

  const renderContent = () => {
    switch (currentTab) {
      case tabs[0].value: //details
        if(editMode.details){
          return (
            <DetailsEditForm
              model={model}
              schoolEstablishment={schoolEstablishment}
              reload={(l) => reload(l, tabs[0].value)}
              onSwitchMode={l => switchMode(l, tabs[0].value)}
            />
          );
        }
        return <Details 
                  model={model} 
                  schoolEstablishment={schoolEstablishment} 
                />;
      default:
        return '';
    }
  };

  return (
    <Page sx={root} title={model.DetailPageTitle}>
      <Container maxWidth={false}>
        <GenericHeader 
          id={id} 
          model={model} 
          headerTitle={model.Title} 
          editMode={editMode.details}
          actions={['editDetail', 'delete']}
          onEditDetail={() => editSchoolEstablishment()}
        />
        <Box mt={2}>
          <Tabs onChange={handleTabsChange} scrollButtons='auto' value={currentTab} variant='scrollable' textColor='secondary'>
            {tabs.map(tab => (
              <Tab key={tab.value} label={tab.label} value={tab.value} />
            ))}
          </Tabs>
        </Box>
        <Divider />
        <Box mt={3}>{renderContent()}</Box>
      </Container>
    </Page>
  );
};

export default SchoolEstablishmentDetailsView;
