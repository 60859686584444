import React, { FC } from 'react';
import PropTypes from 'prop-types';
import { Grid, ListItem } from '@mui/material';
import type { DocumentResource } from 'src/common/types';
import { DocumentModel } from 'src/common';
import GeneralInfo from './GeneralInfo';
import ContentInfo from './ContentInfo';
import DateInfo from './DateInfo';
import VisibilityInfo from 'src/components/visibility/VisibilityInfo';

interface DetailsProps {
  model: DocumentModel;
  document: DocumentResource;
}

const noStyle = {
  width: '100%',
  justifyContent: 'flex-start',
  fontWeight: 'normal',
  justifyItems: 'flex-start',
  display: 'flex'
}

const Details: FC<DetailsProps> = ({model,document}) => {
  return (
    <Grid container spacing={3}>
      <Grid item md={8} xl={8} xs={12}>
        <ListItem sx={noStyle}>
          <GeneralInfo document={document} model={model} />
        </ListItem>
      </Grid>
      <Grid item md={2} xl={2} xs={12}>
        <ListItem sx={noStyle}>
          <VisibilityInfo content={document} model={model} />
        </ListItem>
        <ListItem sx={noStyle}>
          <ContentInfo document={document} model={model} />
        </ListItem>
      </Grid>
      <Grid item md={2} xl={2} xs={12}>
        <ListItem sx={noStyle}>
          <DateInfo document={document} model={model}/>
        </ListItem>
      </Grid>
   </Grid>
  );
}

Details.propTypes = {
  document: PropTypes.object.isRequired
};

export default Details;
