import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import type { 
  HandleChange, 
  SetFieldValue, 
  SubscriptionGroupQuickAccessDto, 
  SubscriptionGroupResource
} from 'src/common/types';
import { Box, Button, SvgIcon, Typography } from '@mui/material';
import {
  SubscriptionModel,
  CaijInput,
  Link as RouterLink,
  FormikErrors,
  FormikTouched
} from 'src/common';
import { PlusSquare as AddIcon, Trash as DeleteIcon  } from 'react-feather';

export interface QuickAccessProps {
  model: SubscriptionModel;
  subscriptionGroup: SubscriptionGroupResource;
  errors: FormikErrors<SubscriptionGroupResource>;
  touched: FormikTouched<SubscriptionGroupResource>;
  onHandleChange: HandleChange;
  setFieldValue: SetFieldValue;
}

const QuickAccess: FC<QuickAccessProps> = ({
  model,
  subscriptionGroup,
  errors,
  touched,
  onHandleChange,
  setFieldValue
}) => {
  const { QuickAccess, QuickAccessName, QuickAccessUrl } = model;
  const { quickAccess } = subscriptionGroup;
  const error = (errors.quickAccess as SubscriptionGroupQuickAccessDto[]);

  const deleteQuickAccess = (index: number) => {
    const newQuickAccess = [...quickAccess];
    newQuickAccess.splice(index,1);
    setFieldValue(QuickAccess, newQuickAccess);
  }

  return (
    <>
      { quickAccess && quickAccess.map((row, index) => (
          <Box key={row.id} sx={{ml: 1}}>
            <Typography sx={{padding: `${index != 0 ? '10px' : '0' } 0 10px 0`}}>{model.getQuickAccessTitle(index + 1)}</Typography>
            <CaijInput 
              required
              name={`${QuickAccess}.${index}.${QuickAccessName.Name}`}
              label={QuickAccessName.Label}
              value={row.name}
              error={Boolean((touched.quickAccess && (touched.quickAccess[index]?.name || false)) && (error && (error[index]?.name || false)))}
              helperText={((touched.quickAccess && (touched.quickAccess[index]?.name || false)) && (error && (error[index]?.name || false))) || ' '}
              InputLabelProps={{ shrink: true, required: true }}
              inputAttr={{ maxLength: QuickAccessName.MaxLength}}
              onHandleChange={onHandleChange}
              setFieldValue={setFieldValue}
            />
            <CaijInput
              required
              name={`${QuickAccess}.${index}.${QuickAccessUrl.Name}`}
              label={QuickAccessUrl.Label}
              value={row.url}
              error={Boolean((touched.quickAccess && (touched.quickAccess[index]?.url || false)) && (error && (error[index]?.url || false)))}
              helperText={((touched.quickAccess && (touched.quickAccess[index]?.url || false)) && (error && (error[index]?.url || false))) || ' '}
              InputLabelProps={{ shrink: true, required: true }}
              inputAttr={{ maxLength: QuickAccessUrl.MaxLength }}
              onHandleChange={onHandleChange}
              setFieldValue={setFieldValue}
            />
            <Button 
              startIcon={
                <SvgIcon fontSize='small'>
                  <DeleteIcon />
                </SvgIcon>
              }
              onClick={() => deleteQuickAccess(index)}
            >
              Supprimer l'accès
            </Button>
          </Box>
        ))
      }
      <Button
        to={'#'}
        component={RouterLink}
        onClick={(e:React.MouseEvent<HTMLAnchorElement>) => {
          let quickAccess = { name: '', url: ''};
          if(subscriptionGroup.quickAccess){
            setFieldValue(QuickAccess, [...subscriptionGroup.quickAccess, { name: '', url: ''}]);
          }else{
            setFieldValue(QuickAccess, [quickAccess]);
          }
        }}
        sx={{mt: 2, padding: '10px'}}
      >
        <Box display="flex" alignItems="center">
          <SvgIcon fontSize='small' sx={{mr: 0.8}}>
            <AddIcon />
          </SvgIcon>
          <Typography sx={{fontSize: '14px'}}>Ajouter un accès</Typography>
        </Box>
      </Button>
    </>
  )
};

QuickAccess.propTypes = {
  subscriptionGroup: PropTypes.object,
  setFieldValue: PropTypes.func.isRequired,
  onHandleChange: PropTypes.func.isRequired
};

export default QuickAccess;
