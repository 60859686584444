import { FC } from 'react';
import { SchoolEstablishmentModel } from 'src/common';
import CaijDialogs from '..';
import { BtnOk } from '../CaijDialog';

interface SchoolEstablishmentDialogProps {
  model: SchoolEstablishmentModel;
  open: boolean;
  submit?: () => void;
}

const SchoolEstablishmentDialog: FC<SchoolEstablishmentDialogProps> = ({ 
  model,
  open,
  submit
}) => {
  model.Dialog.Header = "Domaine d'adresse de courriel";
  model.Dialog.BtnText = BtnOk.VALIDATE_EMAIL_DOMAIN;
  return (
    <CaijDialogs
      dialog={model.Dialog}
      isOpen={open}
      onSubmit={() => (async () => {
        submit();
      })()}
    />
  );
}

export default SchoolEstablishmentDialog;