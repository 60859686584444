import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import type { SchoolEstablishmentResource } from 'src/common/types';
import {
  Divider,
  Table,
  TableBody
} from '@mui/material';
import { 
  tableRow, 
  SchoolEstablishmentModel
} from 'src/common';
import { tableCell } from 'src/styles/subscription';
import MyCard from 'src/components/card/MyCard';
import CardHeaderDetail from 'src/components/card/CardHeaderDetail';
import { 
  CaijTableRow, 
  CaijTableRowConfirm, 
} from 'src/components/table/CaijTableRow';

export interface SchoolEstablishmentInfoProps {
  model: SchoolEstablishmentModel,
  schoolEstablishment: SchoolEstablishmentResource;
}

const SchoolEstablishmentInfo: FC<SchoolEstablishmentInfoProps> = ({
  model,
  schoolEstablishment
}) => {
  const { name, notarialLawMasterOffer, validateEmailDomain } = schoolEstablishment;
  const { Name, ValidateEmailDomain, NotarialLawMasterOffer } = model;
  return (
    <MyCard>
      <CardHeaderDetail title='Établishment scolaire' />
      <Divider />
      <Table>
        <TableBody>
          <CaijTableRow label={Name.Label} sxTableCell={tableCell}>{name}</CaijTableRow>  
          <CaijTableRowConfirm 
            label={NotarialLawMasterOffer.Label} 
            sxTableCell={tableCell}
            confirm={notarialLawMasterOffer}
          />
          <CaijTableRowConfirm 
            label={ValidateEmailDomain.Label} 
            sxTableCell={tableCell}
            confirm={validateEmailDomain}
            sxTableRow={tableRow}
          />
        </TableBody>
      </Table>
    </MyCard>
  );
};

SchoolEstablishmentInfo.propTypes = {
  schoolEstablishment: PropTypes.object.isRequired
};

export default SchoolEstablishmentInfo;
