import React from 'react';
import type { SetFieldValue } from 'src/common/types';
import { Box } from '@mui/material';
import CaijDraftEditor from 'src/components/draftEditor/CaijDraftEditor';
import { FormModel } from 'src/model/Form';

type Model<T> = {
  DescriptionFr: T;
  DescriptionEn: T;
};

type Data<V> = {
  descriptionFr?: V;
  descriptionEn?: V;
};

interface CaijDescriptionProps<T,V> {
  model: T;
  data: V;
  allowedBackgroundColor?: boolean;
  setFieldValue: SetFieldValue;
}

const CaijDescription = <T extends Model<FormModel>,V extends Data<string>>({ 
  model, 
  data, 
  allowedBackgroundColor, 
  setFieldValue 
}: CaijDescriptionProps<T,V>) => {
  const { DescriptionFr, DescriptionEn } = model;
  return (
    <Box display={{md: "flex", lg:'flex', sm: 'block', xs: 'block'}}>
      <Box sx={{mr: {md: 2, lg:2, sm: 0, xs: 0}}}>
        <CaijDraftEditor
          title={DescriptionFr.Label}
          dataTestId={DescriptionFr.Name}
          value={data.descriptionFr}
          name={DescriptionFr.Name}
          setFieldValue={setFieldValue}
          allowedBackgroundColor={allowedBackgroundColor}
        />
      </Box>
      <Box>
        <CaijDraftEditor
          title={DescriptionEn.Label}
          dataTestId={DescriptionEn.Name}
          value={data.descriptionEn}
          name={DescriptionEn.Name}
          setFieldValue={setFieldValue}
          allowedBackgroundColor={allowedBackgroundColor}
        />
      </Box>
    </Box>
  );
};

export default CaijDescription;