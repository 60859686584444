import dayjs from 'dayjs';
import { 
  CustomerModel, 
  ErrorType, 
  formatDate, 
  INVALID_DATE, 
  labelConfig
} from 'src/common';
import { FormModel } from 'src/model/Form';
import * as Yup from 'yup';

const getExpiration = (memberExpiration: FormModel) => {
  return memberExpiration.dateRequired()
    .test(memberExpiration.Label, CustomerModel.formatError(ErrorType.invalid, memberExpiration.Label), (expiration: string) => {
      return expiration === INVALID_DATE ? false : true;
    })
    .test(memberExpiration.Label, CustomerModel.formatError(ErrorType.mustBeGreaterThanToday, memberExpiration.Label), (expiration: string) => {
      if((expiration && expiration.toString() !== INVALID_DATE)){
        return dayjs(new Date(expiration)).isAfter(new Date());
      }
      return true;
    });
}

export const validateEditProfileSchema = (model: CustomerModel) => {
  const { 
    MemberEmail, MemberFirstname, MemberLastname, MemberPhone, MemberPhoneExt, MemberCellPhone, 
    MemberOrganization, MemberJob, AddressName, AddressLine1, AddressLine2, AddressCity, AddressState, 
    AddressZip, AddressCountry, MemberAdditionalInfo, MemberExpiration, MemberExtIdentifier
  } = model;
  return Yup.object().shape({
    firstname: MemberFirstname.required(true),
    lastname: MemberLastname.required(true),
    phone: MemberPhone.nullable(true),
    phoneExt: MemberPhoneExt.nullable(true),
    cellPhone: MemberCellPhone.nullable(true),
    organization: MemberOrganization.nullable(true),
    job: MemberJob.nullable(true),
    extIdentifier: MemberExtIdentifier.nullable(true),
    birthdate: Yup.date().nullable().default(null)
      .test(labelConfig.birthdate, CustomerModel.formatError(ErrorType.invalid, labelConfig.birthdate), (birthdate: Date) => {
        if (birthdate) {
          var patt = /^\d{4}-\d{2}-\d{2}$/;
          return patt.test(formatDate(birthdate));
        }
        return true;
      }),
    email: MemberEmail.required(true, true),
   addresses: Yup.array().of(
      Yup.object().shape({
        name: AddressName.nullable(true),
        line1: AddressLine1.nullable(true),
        line2: AddressLine2.nullable(true),
        city: AddressCity.nullable(true),
        zip: AddressZip.nullable(true),
        state: AddressState.nullable(true),
        country: AddressCountry.nullable(true)
      })
    ),
    expiration: Yup.string()
      .transform((curr, orig) => orig === null ? '' : curr)
        .test(MemberExpiration.Label, CustomerModel.formatError(ErrorType.invalid, MemberExpiration.Label), (expiration: string) => {
          return expiration === INVALID_DATE ? false : true;
        }),
    additionalInfo: MemberAdditionalInfo.nullable(true)
  });
}

export const validateCreateSchema = (model: CustomerModel) => {
  const { MemberEmail, MemberFirstname, MemberLastname, MemberExtIdentifier } = model;
  return Yup.object().shape({
    firstname: MemberFirstname.required(true),
    lastname: MemberLastname.required(true),
    email: MemberEmail.required(true, true),
    extIdentifier: MemberExtIdentifier.nullable(true)
  });
}

export const validateCreateEndorsedSchema = (model: CustomerModel) => {
  const { MemberEmail, MemberFirstname, MemberLastname, MemberExpiration } = model;
  return Yup.object().shape({
    firstname: MemberFirstname.required(true),
    lastname: MemberLastname.required(true),
    email: MemberEmail.required(true, true),
    expiration: getExpiration(MemberExpiration)
  });
}

export const validateAccountExpireSchema = (model: CustomerModel) => {
  return Yup.object().shape({ expiration: getExpiration(model.MemberExpiration) });
}
