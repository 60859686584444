import React, { useState } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import type { 
  SchoolEstablishmentResource,
  SchoolEstablishmentResourceForEdit, 
} from 'src/common/types';
import {
  Authorize,
  btnSubmit,
  CaijButtonReset,
  CaijButtonSubmit,
  Formik,
  Mode,
  SchoolEstablishmentModel,
} from 'src/common';
import printMessage from 'src/views/errors/MessageError';
import MyCard from 'src/components/card/MyCard';
import { Box, CardContent } from '@mui/material';
import { Root } from 'src/components/styled';
import SchoolEstablishmentForm from 'src/components/schoolEstablishment';
import validateSchema from '../../Schema';
import SchoolEstablishmentDialog from 'src/components/dialog/schoolEstablishment';

export interface DetailsEditFormProps {
  model: SchoolEstablishmentModel;
  schoolEstablishment: SchoolEstablishmentResource;
  reload: (value: boolean) => Promise<void>;
  onSwitchMode: (isCancel: boolean) => void;
  onSubmit?: (values: SchoolEstablishmentResource) => void;
}

const DetailsEditForm: FC<DetailsEditFormProps> = ({
  model,
  schoolEstablishment,
  reload,
  onSwitchMode,
  onSubmit
}) => {
  const [isAuth, setIsAuth] = useState<boolean>();
  const [open, setOpen] = useState<boolean>(false);

  const switchMode = (isCancel: boolean) => {
    onSwitchMode(isCancel);
  };

  const handleSubmit = async (values: SchoolEstablishmentResource) => {
    if(onSubmit){
      onSubmit(values);
      return;
    }
    const model = new SchoolEstablishmentModel();
    if(!model.validateEmailDomain(values)){
      setOpen(true);
      return;
    }
    const submitData = Object.assign({},values) as SchoolEstablishmentResourceForEdit;
    if (submitData && submitData.id) {
      const response = await model.update(submitData.id, submitData);
      if (!model.error) {
        printMessage({
          status: response.status,
          message: response.message
        });
        await reload(false);
      }
    }
  };

  return (
    <Formik
      initialValues={{...schoolEstablishment}}
      validationSchema={validateSchema(model)}
      onSubmit={handleSubmit}
    >
      {({ errors, handleBlur, handleSubmit, handleChange, setFieldValue, values, touched, isSubmitting }) => (
        <form onSubmit={handleSubmit}>
          <MyCard>
            <CardContent>
              <Box display="flex" flexDirection="column" alignItems="flex-start">
                <Root>
                  <SchoolEstablishmentForm 
                    model={model} 
                    schoolEstablishment={values}
                    errors={errors}
                    touched={touched}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    SetFieldValue={setFieldValue}
                  />
                </Root>
              </Box>
              <Authorize
                resourceCode={model.ResourceCode}
                mode={Mode.edit}
                onIsAuth={(l) => setIsAuth(l)}
              >
                <CaijButtonSubmit disabled={!isAuth || isSubmitting} sx={btnSubmit} />
              </Authorize>
              <CaijButtonReset disabled={isSubmitting} onSwitchMode={switchMode} />
              <SchoolEstablishmentDialog model={model} open={open} submit={() => setOpen(false)} />
            </CardContent>
          </MyCard>
        </form>
      )}
    </Formik>
  );
};

DetailsEditForm.propTypes = {
  schoolEstablishment: PropTypes.object.isRequired,
  reload: PropTypes.func.isRequired,
  onSwitchMode: PropTypes.func.isRequired,
  onSubmit: PropTypes.func 
};

DetailsEditForm.defaultProps = {
  onSubmit: null
};

export default DetailsEditForm;
