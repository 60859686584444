import React, { useEffect, useState } from 'react';
import type { ChangeEvent, FC } from 'react';
import type { LawResource, Theme } from 'src/common/types';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  SvgIcon,
  Tab,
  Tabs,
  Typography
} from '@mui/material';
import {
  Language,
  dialog,
  CaijButton,
  Transition,
  TINY_MCE_SCRIPT_CDN,
  initTinyMCE,
  LawModel
} from 'src/common';
import { publishVersion } from 'src/styles/law';
import CaijAppBar from 'src/components/AppBar';
import { getPublishVersion } from 'src/utils/law';
import { Editor } from "@tinymce/tinymce-react";
import { List } from "react-feather";
import VersionHistoryDialog from './VersionHistory';
import HistoryModel from 'src/model/customer/History';

interface ILawHomePage<T, V> {
  page: T;
  modified: V ;
};

interface LawHomePageProps {
  open: boolean;
  law: LawResource;
  publish: (fr: string, en: string) => void,
  handleClose: () => void;
}

interface Tabs {
  value: string, label: string
}

const initialState = {
  page: {
    fr:'',
    en:''
  }, 
  modified: {
    fr: null, 
    en: null
  }
};

const LawHomePage: FC<LawHomePageProps> = ({
  open,
  law,
  publish,
  handleClose
}) => {
  const [currentTab, setCurrentTab] = useState<string>(Language.FR);
  const [lawHomePageValue, setLawHomePageValue] = useState<ILawHomePage<Partial<{fr: string, en: string}>, Partial<{fr: Date, en: Date}>>>(initialState);
  const [showVersionHistory, setShowVersionHistory] = useState<boolean>(false);
  const versionFr = law.lawHomePages?.find(s => s.lang === Language.FR);
  const versionEn = law.lawHomePages?.find(s => s.lang === Language.EN);

  useEffect(() => {
    if(open){
      setLawHomePageValue({
        ...lawHomePageValue, 
        page: {
          fr: versionFr?.page || '', 
          en: versionEn?.page || ''
        },
        modified: {
          fr: versionFr?.modified || null,
          en: versionEn?.modified || null
        }
      });
      setCurrentTab(Language.FR);
    }
  },[open]);

  const editModeTabs: Tabs[] = [
    { value: Language.FR, label: 'Française' },
    { value: Language.EN, label: 'Anglaise' }
  ];

  const handleSelectVersion = async (id: number) => {
    enum Lang { FR, EN };
    const model = new LawModel();
    const result = await model.getLawHistoryById(id);
    if(!model.error){
      const historyModel = new HistoryModel()
      let data: any = JSON.parse(result.stateAfter);
      historyModel.Change = new Map<string, any>(Object.entries(data));
      const { LawHomePages } = historyModel;
      const versionFr = LawHomePages?.find(s => +s.Lang === Lang.FR);
      const versionEn = LawHomePages?.find(s => +s.Lang === Lang.EN);
      setLawHomePageValue((prevState) => {
        return {
          ...prevState, 
          page : {
            fr: versionFr?.Page || '', 
            en: versionEn?.Page || '',
          },
          modified: {
            fr: new Date(versionFr?.Modified),
            en: new Date(versionEn?.Modified)
          }
        }
      });
      setShowVersionHistory(false);
    }
  }

  const handleTabsChange = (event: ChangeEvent<any>, tabValue: string): void =>  setCurrentTab(tabValue);

  return (
    <Dialog fullScreen open={open || false} disableEnforceFocus={true} TransitionComponent={Transition} onClose={handleClose}>
      <CaijAppBar title="Page d'accueil" handleClose={handleClose} />
      <DialogContent sx={dialog} dividers>
        <Box sx={{padding: '0 0 20px 0',backgroundColor: (theme: Theme) => theme.palette.background.paper}}>
          <Tabs onChange={handleTabsChange} scrollButtons="auto" value={currentTab} variant="scrollable" textColor="secondary">
            { editModeTabs.map((tab) => <Tab key={tab.value} label={tab.label} value={tab.value} />) }
          </Tabs>
        </Box>
        <Box>
          <Typography sx={{flexGrow: 1, padding: '0 0 25px 0'}} variant="h5" component="div">
            {currentTab === Language.FR ? `${law.titleFr} (${law.lawReference})` : `${law.titleEn} (${law.lawReferenceEn})`}
          </Typography>
          <Editor
            tinymceScriptSrc={TINY_MCE_SCRIPT_CDN}
            onEditorChange={(page) => {
              const item = { ...lawHomePageValue };
              if(currentTab === Language.FR){
                item.page.fr = page;
              }else{
                item.page.en = page;
              }
              setLawHomePageValue(item);
            }}
            init={initTinyMCE}
            value={currentTab === Language.FR ? lawHomePageValue.page.fr : lawHomePageValue.page.en}
          />
          <Typography variant="h6" sx={publishVersion}>{getPublishVersion(currentTab === Language.FR ? lawHomePageValue.modified.fr : lawHomePageValue.modified.en)}</Typography>
        </Box>
        <Box>
          <VersionHistoryDialog lawId={law.id} open={showVersionHistory} onClose={() => setShowVersionHistory(false)} handleSelectVersion={handleSelectVersion} />
        </Box>
      </DialogContent>
      <DialogActions sx={{ backgroundColor: 'background.paper', padding: '20px 0 0 0'}}>
        <Button variant="contained" color="info" sx={{mr: 1}} startIcon={<SvgIcon fontSize='small'><List /></SvgIcon>} onClick={() => setShowVersionHistory(true)}>Historique</Button>
        <CaijButton sx={{mr: 1}} variant="contained" onHandleClick={() => publish(lawHomePageValue.page.fr, lawHomePageValue.page.en)}>Publier</CaijButton>
        <Button variant="outlined" onClick={handleClose}>Annuler</Button>
      </DialogActions>
    </Dialog>
  );
};

export default LawHomePage;
