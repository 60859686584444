import React, { useEffect, useReducer } from 'react';
import type { FC } from 'react';
import { Box, Container } from '@mui/material';
import {
  useLocation,
  useParams,
  AccessModel,
  _contentPublication,
  fetchContentPublication,
  root,
  useNavigate
} from 'src/common';
import Page from 'src/components/Page';
import LoadingScreen from 'src/components/loading/LoadingScreen';
import ContentPublicationsModel from 'src/model/content/ContentPublications';
import ContentPublicationsEditForm from './ContentPublicationsEditForm';
import { ContentPublicationProvider } from 'src/contexts/content/ContentPublicationContext';
import GenericHeader from 'src/components/header/GenericHeader';
import parse from 'html-react-parser';

const ContentPublicationsEditView: FC = () => {
  const model = ContentPublicationsModel.getInstance();
  const access = new AccessModel(model.ResourceCode);
  const location = useLocation();
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(_contentPublication, { isLoading: false, contentPublication: {} });
  const { id } = useParams();
  
  useEffect(() => {
    const initialise = async () : Promise<void> => {
      if(access.canEdit(id)){
        const model = new ContentPublicationsModel();
        const pathName = location.pathname;
        if(id && isNaN(+id)){
          navigate(model.Path.Home);
        }else if (id) {
          model.PathName = pathName;
          const contentPublisher = await model.getContentPublicationById(+id);
          if (!model.error) {
            dispatch(fetchContentPublication(contentPublisher,true));
          }
        }
      }
    };
    (async () => await initialise())();
  }, [id, location]);

  const { contentPublication, isLoading } = state;

  if (!isLoading) return <LoadingScreen />

  model.Title = contentPublication.title ? parse(contentPublication.title) as string : '';

  return (
    <Page
      sx={root}
      title={model.getEditPageTitle(id)}
    >
      <Container maxWidth={false}>
        <GenericHeader 
          id={id} 
          model={model} 
          headerTitle={id ? model.Title : model.BtnAddText} 
        />
        <Box mt={3}>
          <ContentPublicationProvider>
              <ContentPublicationsEditForm 
                model={model}
                contentPublication={contentPublication} 
              />
          </ContentPublicationProvider>
        </Box>
      </Container>
    </Page>
  );
};

export default ContentPublicationsEditView;
