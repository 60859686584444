import {
  Success,
  CustomerModel,
  LanguageProfile,
  PendingChangeType,
  formatDate
} from 'src/common';
import type {
  CustomerHistoryResource, GetCustomerHistoryListAsyncOptionalParams,
  LawHomePageDto
} from 'src/common/types';
import { PagedList } from 'src/types/pagination';
import axios from 'src/utils/axios';
import { $enum } from 'ts-enum-util';

export interface CustomerAddressDto {
  Name?: string;
  Preferred?: boolean;
  Line1?: string;
  Line2?: string;
  City?: string;
  Zip?: string;
  State?: string;
  Country?: string;
};

export interface SubscriptionDto {
  Name?: string;
  Contact?: {
    Name?: string;
    Email?: string;
    Phone?: string;
  };
};

export interface GroupDto {
  id?: number;
  Name?: string;
  IsDefault?: boolean;
  Note?: string;
};

export interface LawDomainDto {
  Code?: string;
  NameFr?: string;
  NameEn?: string;
};

export interface EndorsedCustomerResource {
  NoCaij?: string;
  Fullname?: string;
};

interface CustomerPendingChangeDto {
  PendingValue?: string;
  ChangeType?: number;
};

interface LawHomePageResource {
  Page?: string;
  Modified?: string;
  Lang?: string;
};

export default class HistoryModel extends CustomerModel
{
  private _change: Map<string, any> = null;

  constructor(){
    super();
  }

  set Change(change: Map<string, any>){
    this._change = change;
  }

  get Change() : Map<string, any>{
    return this._change;
  }

  get FullName() : string {
    if(this._change && this._change.has('FullName')){
      return this._change.get('FullName');
    }
    return;
  }

  get FirstName() : string {
    if(this._change && this._change.has('Firstname')){
      return this._change.get('Firstname');
    }
    return;
  }

  get LastName() : string {
    if(this._change && this._change.has('Lastname')){
      return this._change.get('Lastname');
    }
    return;
  }

  get BirthDay() : string {
    if(this._change && this._change.has('Birthdate')){
      return formatDate(this._change.get('Birthdate'));
    }
  }

  get LogoUrl() : string {
    if(this._change && this._change.has('LogoUrl')){
      return this._change.get('LogoUrl');
    }
  }

  get Email() : string{
    if(this._change && this._change.has('Email')){
      return this._change.get('Email');
    }
    return;
  }

  get Phone() : string {
    if(this._change && this._change.has('Phone')){
      return this._change.get('Phone');
    }
    return;
  }

  get ExtPhone() : string {
    if(this._change && this._change.has('PhoneExt')){
      return this._change.get('PhoneExt');
    }
    return;
  }

  get CellPhone() : string {
    if(this._change && this._change.has('CellPhone')){
      return this._change.get('CellPhone');
    }
    return;
  }

  get Job() : string {
    if(this._change && this._change.has('Job')){
      return this._change.get('Job');
    }
    return;
  }

  get Organization() : string {
    if(this._change && this._change.has('Organization')){
      return this._change.get('Organization');
    }
    return;
  }

  get ExtIdentifier() : string {
    if(this._change && this._change.has('ExtIdentifier')){
      return this._change.get('ExtIdentifier');
    }
    return;
  }

  get NoCaij() : string {
    if(this._change && this._change.has('NoCaij')){
      return this._change.get('NoCaij');
    }
    return;
  }

  get Card() : string {
    if(this._change && this._change.has('Card')){
      return this._change.get('Card');
    }
    return;
  }

  get Language() : string {
    if(this._change && this._change.has('Language')){
      return $enum(LanguageProfile).getValueOrDefault(+this._change.get('Language') === 0 ? 'FR' : 'EN');
    }
    return;
  }

  get WfProfile() : string {
    if(this._change){
      return this._change.get('WfProfile');
    }
    return;
  }

  get WfLibrary(){
    if(this._change && this._change.has('WfLibrary')){
      return this._change.get('WfLibrary');
    }
    return;
  }

  get LcapCommercials() : Boolean {
    if(this._change && this._change.has('LcapCommercials')){
      return this._change.get('LcapCommercials');
    }
    return
  }
  
  get LcapNewsletter() : Boolean {
    if(this._change){
      return this._change.get('LcapNewsletter');
    }
    return;
  }

  get LcapImprovement() : Boolean {
    if(this._change && this._change.has('LcapImprovement')){
      return this._change.get('LcapImprovement');
    }
    return;
  }

  get Status() : string {
    if(this._change && this._change.has('Status')){
      return this._change.get('Status');
    }
  }

  get Role() : string {
    if(this._change && this._change.has('Role')){
      return this._change.get('Role');
    }
    return;
  }

  get Addresses() : Array<CustomerAddressDto> {
    if(this._change && this._change.has('Addresses')){
      return this._change.get('Addresses') as Array<CustomerAddressDto>;
    }
    return;
  }

  get Subscription() : SubscriptionDto {
    if(this._change && this._change.has('Subscription')){
      return this._change.get('Subscription') as SubscriptionDto;
    }
    return;
  }

  get Group() : GroupDto {
    if(this._change && this._change.has('SubscriptionGroup')){
      return this._change.get('SubscriptionGroup') as GroupDto;
    }
    return;
  }

  get LawDomains() : Array<LawDomainDto> {
    if(this._change && this._change.has('LawDomains')){
      return this._change.get('LawDomains') as Array<LawDomainDto>;
    }
    return;
  }

  get CustomerEndorsed() : Array<EndorsedCustomerResource> {
    if(this._change && this._change.has('CustomersEndorsed')){
      return this._change.get('CustomersEndorsed') as Array<EndorsedCustomerResource>;
    }
    return;
  }

  get PendingValue(){
    if(this._change && this._change.has('CustomerPendingChanges')){
      var customerPendingChanges = this._change.get('CustomerPendingChanges') as Array<CustomerPendingChangeDto>;
      if(customerPendingChanges){
        return customerPendingChanges.find(({ChangeType}) => ChangeType === PendingChangeType.Email)?.PendingValue;
      }
    }
    return;
  }

  get AccessBetaCaij(){
    if(this._change && this._change.has('AccessBetaCaij')){
      return this._change.get('AccessBetaCaij');
    }
    return;
  }
  
  get Expiration(){
    if(this._change && this._change.has('Expiration')){
      return formatDate(this._change.get('Expiration'));
    }
  }

  get Created(){
    if(this._change && this._change.has('Created')){
      return formatDate(this._change.get('Created'));
    }
  }

  get AdditionalInfo(){
    if(this._change && this._change.has('AdditionalInfo')){
      return this._change.get('AdditionalInfo');
    }
  }

  get LawHomePages() : Array<LawHomePageResource> {
    if(this._change && this._change.has('LawHomePages')){
      return this._change.get('LawHomePages') as Array<LawHomePageResource>;
    }
    return;
  }

  async getCustomerHistoryList(params : GetCustomerHistoryListAsyncOptionalParams) : Promise<PagedList<CustomerHistoryResource>> {
    let pagedList: PagedList<CustomerHistoryResource>;
    await axios.get<PagedList<CustomerHistoryResource>>(`${this.route}/history`,{params}).then(
      async response => {
        const { status, data } = response;
        if (status === Success) {
          pagedList = data;
        }
      },
      async error => {
        this.error = error;
        if(!this.skipHandleError){
          await this.handleError(this.error);
        }
      },
    );
    return pagedList;
  }

  async getCustomerHistory(date: Date, custId: string): Promise<CustomerHistoryResource[]> {
    let customerHistories: CustomerHistoryResource[];
    await axios.get<CustomerHistoryResource[]>(`${this.route}/${custId}/history/${date}`).then(
      async response => {
        const { status, data } = response;
        try {
          if (status === Success) {
            customerHistories = data;
          }
        } catch (ex) {
          this.error = { status, message: ex.message };
          await this.handleError(this.error);
        }
      },
      async error => {
        this.error = error;
        await this.handleError(this.error);
      },
    );
    return customerHistories;
  }

  async getCustomerNoteHistory(date: Date, custId: string): Promise<CustomerHistoryResource[]> {
    let customerHistories: CustomerHistoryResource[];
    await axios.get<CustomerHistoryResource[]>(`${this.route}/${custId}/historyCustomerNote/${date}`).then(
      async response => {
        const { status, data } = response;
        try {
          if (status === Success) {
            customerHistories = data;
          }
        } catch (ex) {
          this.error = { status, message: ex.message };
          await this.handleError(this.error);
        }
      },
      async error => {
        this.error = error;
        await this.handleError(this.error);
      },
    );
    return customerHistories;
  }
}
