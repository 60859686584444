import type { SchoolEstablishmentResource } from 'src/common/types';
import * as actionTypes from '../../actions/ActionTypes';

type FetchSchoolEstablishmentsAction = {
    type: 'FETCH_SCHOOL_ESTABLISHMENTS';
    payload: {
      schoolEstablishments: SchoolEstablishmentResource[];
      isLoading?: boolean;
      isEmptyList?: boolean;
      isAuthorize?: boolean;
    }
};

type FetchSchoolEstablishmentAction = {
  type: 'FETCH_SCHOOL_ESTABLISHMENT';
  payload: {
    schoolEstablishment: SchoolEstablishmentResource;
    isLoading?: boolean;
  };
};

type DeleteSchoolEstablishmentAction = {
  type: 'DELETE_SCHOOL_ESTABLISHMENT';
  payload: {
    id: number
  }
};

export type Action = FetchSchoolEstablishmentsAction | FetchSchoolEstablishmentAction | DeleteSchoolEstablishmentAction;

export interface SchoolEstablishmentReturnState {
  schoolEstablishments?: SchoolEstablishmentResource[];
  schoolEstablishment?:  SchoolEstablishmentResource;
  isLoading?: boolean;
  isEmptyList?: boolean;
  isAuthorize?: boolean;
}

interface SchoolEstablishmentState {
  schoolEstablishments: SchoolEstablishmentResource[];
  schoolEstablishment:  SchoolEstablishmentResource;
  isLoading: boolean;
  isEmptyList: boolean;
  isAuthorize: boolean;
}

const reducer = (state: SchoolEstablishmentState, action: Action) : SchoolEstablishmentReturnState => {
  switch (action.type) {
    case actionTypes.FETCH_SCHOOL_ESTABLISHMENTS: {
      const { schoolEstablishments, isLoading, isEmptyList, isAuthorize } = action.payload;
      return {
        ...state,
        schoolEstablishments,
        isLoading,
        isEmptyList,
        isAuthorize
      };
    }
    case actionTypes.FETCH_SCHOOL_ESTABLISHMENT: {
      const { schoolEstablishment, isLoading } = action.payload;
      return {
        ...state,
        schoolEstablishment,
        isLoading
      };
    }
    case actionTypes.DELETE_SCHOOL_ESTABLISHMENT: {
      return {
        ...state,
        schoolEstablishments: [...state.schoolEstablishments].filter(({id}) => id !== action.payload.id)
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;

