import { CustomerStatus, DatabankAppType, THEMES } from 'src/common';
import { EditorState, ContentState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import { DatabankResource, Item, KnownDatabankLinkType, Section } from 'src/common/types';
import { $enum } from 'ts-enum-util';
import { LabelColor } from 'src/components/Label';
import { Settings } from 'src/contexts/SettingsContext';
import useSettings from 'src/hooks/useSettings';

const firstLetterCap = (value: string) => {
  return value.charAt(0).toUpperCase() + value.slice(1)
}

const formatError = (searchValue: string, findValue: string | any[], replaceValue: string | any[]): string => {
  for (let i = 0; i < findValue.length; i++) {
    searchValue = searchValue.replace(new RegExp(findValue[i], 'gi'), replaceValue[i]);
  }
  return searchValue;
};

const formatString = (searchValue: string, findValue: string | any[], replaceValue: string | any[]) : string => formatError(searchValue,findValue,replaceValue);

const getEspaceMenuCAIJ = (mainMenu: Section[], index: number, espaceMenuCAIJ:string[]) => {
  if (typeof mainMenu[index] === 'object') {
    const subMenu = mainMenu[index];
    Object.keys(subMenu).forEach(prop => {
      if (prop === 'items') {
        const sections = subMenu[prop] as Item[];
        sections.forEach((section: Item) => {
          const { items } = section;
          if (items) {
            items.forEach((item: Item) => {
                espaceMenuCAIJ.push(item.resourceCode);
            });
          }else{
            if(section.resourceCode){
              espaceMenuCAIJ.push(section.resourceCode);
            }
          }
        });
      }
    });
  }
  if (index < mainMenu.length - 1) {
    index += 1;
    return getEspaceMenuCAIJ(mainMenu, index, espaceMenuCAIJ);
  }
  return null;
};

const pad = (n:number) => { return n < 10 ? '0' + n : n}

const DateTimeString = (d:Date): string => {
  return d.getFullYear()+'-' 
          + pad(d.getMonth()+1)+'-'
          + pad(d.getDate())+ ' '
          + pad(d.getHours())+':'
          + pad(d.getMinutes())+':'
          + pad(d.getSeconds())
}

const formatDate = (d: Date): string => {
  if (!d) return null;
    return new Date(d).toISOString().replace('T', ' ').substring(0, 10);
}

const formatDateTime = (d: Date): string => {
  if (!d) return null;
  return DateTimeString(new Date(d));
}

const formatDateHours = (d: Date): string => {
  if (!d) return null;
  return pad(d.getHours())+':'
        + pad(d.getMinutes());
}
const formatMSDateTimeString = (rawDate) =>  {
  console.log(rawDate.replace("/Date(", "").replace(")/", ""));
  var date = rawDate ? new Date(parseInt(rawDate.replace("/Date(", "").replace(")/", ""))) : null;
  return date ? date.getFullYear() + '-' + (date.getMonth() + 1 < 10 ? '0' : '') + (date.getMonth() + 1) + '-' + (date.getDate() < 10 ? '0' : '') + date.getDate() + ' � ' + date.getHours() + 'h' + (date.getMinutes() < 10 ? '0' : '') + date.getMinutes() : '';
}
const formatDateTimeZoneTZ = (date: Date) : Date => {
  if(date){
    return new Date(date.toString().replace('T', ' ').replace('Z', ' '));
  }
}

function formatDateTimeZoneTZ2(date: Date | string, startTime?: number, endTime?: number) : string {
  let custDate: string = '';
  if(date instanceof Date){
    custDate = date.toString().slice(0, 10);
    if(startTime && endTime){
      custDate += ' ' + date.toString().slice(startTime, endTime);
    }
  }else if(typeof date === 'string'){
    custDate = date.slice(0, 10);
    if(startTime && endTime){
      custDate += ' ' + date.toString().slice(startTime, endTime);
    }
  }
  return custDate;
};


const validateEmail = (email: string) : Boolean => {
  const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if(re.test(email)){
    return true;
  }
  return false;
}

const convertHTMltoEditor = (html: string): EditorState => {
  const contentBlock = htmlToDraft(html);
  if (contentBlock) {
    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
    const editorState = EditorState.createWithContent(contentState);
    return editorState;
  }
  return EditorState.createEmpty();
}

const generateNumber = (): string => {
  return ('000' + String(Math.random() * 1000000 + Math.random() * 1000000).substring(0, 6));
};

const deleteAction = () =>  {
  return {
    margin: 'none',
    color: (theme: any) => theme.palette.error.main,
    border: '1px solid',
    borderColor: (theme: any) => theme.palette.error.main,
  }
};

const getProxy = (databank: DatabankResource) : string => {
  if(databank.appType === DatabankAppType.Online){
    const result = databank.databankAccesses.find(a => a.proxy?.name)
    if(result) return `( ${result.proxy.name} )`;
  }
   return '';
};

const getCustomerStatusLabelColor = (status: string, settings?: Settings) : LabelColor => {
  switch($enum(CustomerStatus).getValueOrDefault(status)){
    case CustomerStatus.Inactive:
      return 'error';
    case CustomerStatus.Active:
      return 'success';
    case CustomerStatus.ActiveOnLogin:
    case CustomerStatus.ActiveUponApproval:
      return 'warning2';
    case CustomerStatus.Restreint:
      if(settings.theme === THEMES.ONE_DARK){
        return 'warning4';
      }
      return 'warning3';
  }
}

const getUrlTitle = (linkType:string): string => {
  if(linkType === KnownDatabankLinkType.Caij || linkType === KnownDatabankLinkType.Bibliomagis){
   return 'URL sans les informations du proxy';
  }
  return 'URL';
}

const getCardBalance = (cardBalance: number) => {
  return cardBalance !== 0 ? cardBalance.toFixed(2) : 0;
};

const getAmount = (value: number, allowedSign: boolean = false) => {
  const val1 = value?.toFixed(2);
  const val2 = value?.toFixed(2).toString().slice(1);
  if(value >= 0){
    return allowedSign ? `+ ${val1}` : val1;
  }else{
    return allowedSign ? `- ${val2}` : val2;
  } 
}

const getSubstringWithThreeDot = (value: string) => {
  if(value){
    const limit = 50;
    let newString = value.substring(0,limit);
    if(value.length > limit){
      newString = `${newString}...`;
    }
    return newString;
  }
}

const getOpacity = (isActive : boolean) => !isActive ? 0.4 : '';

const scrollDashBoardContentToTop = () => {
  const content = document.getElementById('dashBoardContent');
  if(content && content.scrollTop > 0){
    content.scrollTop = 0;
  }
}

const validateCode = (text: string) => {
  const accents = {
    "\\s" : "_",
    "[àáâãäå]" : "a",
    "æ" : "ae",
    "ç" : "c",
    "[èéêë]" : "e",
    "[ìíîï]" : "i",
    "ñ" : "n",
    "[òóôõö]" : "o",
    "œ" : "oe",
    "[ùúûü]" : "u",
    "[ýÿ]" : "y",
    "\\W" : ""
  };
  for(let prop in accents){
    text = text.replace(new RegExp(prop, 'g'),accents[prop])
  }
  return text;
}

function getOptionLabel(val : string, estUne: boolean = false) {
  return `Sélectionner ${estUne ? 'une' : 'un'} ${String(val).charAt(0).toLowerCase() + String(val).slice(1)}`;
}

function noRowsOverlayComponentParams() {
  return {
    noRowsMessageFunc: () => 'Aucune donnée trouvée'
  };
};

function getAgGridTheme(){
  const { settings } = useSettings();
  return settings.theme === THEMES.ONE_DARK ? "ag-theme-quartz-dark" : "ag-theme-quartz";
};

export {
  firstLetterCap,
  formatError,
  formatDate,
  formatDateTime,
  formatDateHours,
  getEspaceMenuCAIJ,
  validateEmail,
  convertHTMltoEditor,
  generateNumber,
  deleteAction,
  formatMSDateTimeString,
  formatDateTimeZoneTZ,
  formatDateTimeZoneTZ2,
  formatString,
  getProxy,
  getCustomerStatusLabelColor,
  getUrlTitle,
  DateTimeString,
  getCardBalance,
  getAmount,
  pad,
  getSubstringWithThreeDot,
  getOpacity,
  scrollDashBoardContentToTop,
  validateCode,
  getOptionLabel,
  noRowsOverlayComponentParams,
  getAgGridTheme
};
