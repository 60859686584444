import React, { FC } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import type { SchoolEstablishmentResource } from 'src/common/types';
import { SchoolEstablishmentModel } from 'src/common';
import SchoolEstablishmentInfo from './SchoolEstablishmentInfo';
import EmailDomainInfo from './EmailDomainInfo';

interface DetailsProps {
  model: SchoolEstablishmentModel,
  schoolEstablishment: SchoolEstablishmentResource;
}

const Details: FC<DetailsProps> = ({
  model,
  schoolEstablishment,
}) => (
  <Grid container spacing={3}>
    <Grid item lg={6} md={6} sm={12} xs={12}>
      <SchoolEstablishmentInfo model={model} schoolEstablishment={schoolEstablishment} />
    </Grid>
    <Grid item lg={6} md={6} sm={12} xs={12}>
      <EmailDomainInfo model={model} schoolEstablishment={schoolEstablishment} />
    </Grid>
  </Grid>
);

Details.propTypes = {
  schoolEstablishment: PropTypes.object.isRequired
};

export default Details;
