import { Box, Container, Divider, Tab, Tabs } from '@mui/material';
import type { ChangeEvent, FC } from 'react';
import React, { useCallback, useEffect, useReducer, useState } from 'react';
import {
  AccessModel,
  CustomerModel,
  fetchDetailsSubscription,
  Forbidden, 
  setEditHash,
  setHash,
  Unauthorized,
  useLocation,
  useNavigate,
  useParams,
  _databank, 
  _subscription,
  root,
  SbGroup,
  fetchDatabanks,
} from 'src/common';
import type { 
  CustomerResource,
  DatabankResource,
  SubscriptionResource
} from 'src/common/types';
import Page from 'src/components/Page';
import DatabankModel from 'src/model/Databank';
import SubscriptionModel from 'src/model/subscription/Subscription';
import Details from './Details';
import AccessForm from './Forms/AccessForm';
import DetailsEditForm from './Forms/DetailsEditForm';
//import AccessGroupListView from 'src/views/accessGroup/AccessGroupViewedListView';
//import AccessGroupEditView from 'src/views/accessGroup/AccessGroupViewedEditView';
import AccessGroupViewedDetailsView from 'src/views/subscription/subscription/SubscriptionDetailsView/AccessGroup/AccessGroupViewedDetailsView';
import AccessTypeList from 'src/components/access/AccessTypeList';
import CustomerListView from 'src/components/subscription/CustomerListView';
//import ManagerListView from 'src/views/subscription/SubscriptionDetailsView/Lists/ManagerListView';
import CustomerEditView from 'src/views/customer/customer/CustomerEditView/CustomerEditForm';
import { SubscriptionProvider } from 'src/contexts/SubscriptionContext';
import LoadingScreen from 'src/components/loading/LoadingScreen';
import { DoorAccessProfileProvider } from 'src/contexts/DoorAccessProfileContext';
import { UxpertiseProvider } from 'src/contexts/UxpertiseContext';
import AccessGroupEditView from './AccessGroup/AccessGroupViewedEditView';
import AccessGroupListView from './AccessGroup/AccessGroupViewedListView';
import { useAccessGroup } from 'src/functions/hooks/accessGroup';
import { EditAccessGroup } from 'src/types/accessGroup';
import GenericHeader from 'src/components/header/GenericHeader';

//#region Initialize State
const initialState = {
  subscription: {},
  accesses: [],
  databanks: [],
  docCollections: [],
};

const initialOthersState = {
  isEmptyList: true,
  isLoading: false,
  isAuthorize: false,
};

/*
interface Manager {
  customers: CustomerResource[],
  managers: CustomerResource[],
  pagination: Pagination,
  isEmptyList: boolean
};

const initialManagerState: Manager = {
  customers: [],
  managers: [],
  pagination: null,
  isEmptyList: true
};
*/
//#endregion

interface TabsSubscription {
  value: string, label: string
}

const tabs: TabsSubscription[] = [
  { value: 'details', label: 'Détails' },
  { value: 'accesses', label: 'Accès' },
  { value: 'groups', label: 'Groupes d\'Accès' },
  { value: 'members', label: 'Membres' },
 // { value: 'managers', label: 'Gestionnaire de compte' }
];

const getDatabanks = async () : Promise<[stauts: number, data:  DatabankResource[]]> => {
  const model = new DatabankModel();
  model.skipHandleError = true;
  const databanks = await model.getDatabanks();
  const { error } = model;
  return [error?.status, databanks];
};

const SubscriptionDetailsView: FC = () => {
  const model = SubscriptionModel.getInstance();
  const access = new AccessModel(model.ResourceCode);
  const location = useLocation();
  const { groupList, getGroupList, dispatchGroupList } = useAccessGroup();
  const [state, dispatch] = useReducer(_subscription, initialState);
  const [sDatabank, dispatchDatabank] = useReducer(_databank,{...initialOthersState, databanks:[]});
 // const [sManagers, setManagers] = useState<Manager>(initialManagerState);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [editMode, setEditMode] = useState({
    details: false,
    accesses: false,
    groups: false,
    members: false,
    managers: false
  });
  const [currentTab, setCurrentTab] = useState<string>(location.search? tabs[3].value : tabs[0].value);
  const { id, groupId : grpId} = useParams();
  const [groupId, setGroupId] = useState<number>(null);
  const [detailsPage, setDetailsPage] = useState<boolean>(false);
  const [isDefault, setIsDefault] =  useState<boolean>(false);
  const navigate = useNavigate();
 
  const getSubscriptionById = useCallback(async (databanks: DatabankResource[]) : Promise<void> => {
    if (!id || isNaN(+id)) {
      navigate(model.Path.Home);
    }else if(access.canRead()){
      const model = new SubscriptionModel();
      const subscription = await model.getSubscriptionById(id);
      if (!model.error) {
        dispatch(fetchDetailsSubscription(subscription, databanks));
      }
    }
  }, [id]);

/*
  const resetManagerState = () => {
    setManagers((prevState:Manager) => {
      return {
        ...prevState,
        customers:[],
        managers:[],
        pagination: null,
        isEmptyList: true
      }
    });
  }

  const getManagers = useCallback(async (params: GetCustomersAsyncOptionalParams) : Promise<void> => {
    const model = new CustomerModel;
    model.skipHandleError = true;
    const response = await model.getCustomers(params);
    const { error } = model;
    if(error){
      if (error.status != Forbidden && error.status != Unauthorized) {
        resetManagerState();
      }
    }else{
      const { data, pagination } = response;
      const custs = data.filter((customer:CustomerResource) => customer.role === CustomerRole.User);
      const managers = data.filter((customer:CustomerResource) => customer.role === CustomerRole.Admin);
      setManagers((prevState:Manager) => {
        return {
          ...prevState,
          customers: custs,
          managers,
          pagination,
          isEmptyList: !Array.isArray(managers) || managers.length === 0
        }
      });
    }
  },[]);
*/
//#endregion

  const fetchData = useCallback(async () => {
    const [databank] = await Promise.all([getDatabanks(),getGroupList(+id)]);
    dispatchDatabank(fetchDatabanks(databank[1], true, !Array.isArray(databank[1]) || databank[1].length === 0, databank[0] === Forbidden || databank[0] === Unauthorized ? false : true));
    await getSubscriptionById(databank[1]);
  },[]);

  const handleBrowserRefresh = () => {
    const hash = location.hash;
    const { pathname } = location;
    if(hash && !location.search){
      const removedHashSymbol = hash.substring(1, hash.length);
      const hashVal = removedHashSymbol.split('-');
      let tabVal = hashVal.length === 1 ? hashVal[0] : hashVal[1];
      const index = tabs.findIndex((tab: TabsSubscription) => tab.value === tabVal);
      if(index === 3 && pathname.indexOf('group') !== -1){
        const path = model.Path;
        tabVal = 'groups';
        if(location.pathname.indexOf('edit') !== -1){
          navigate(path.getSubscriptionGroupEdit(+id, +grpId), {replace: true});
          setCurrentTab(tabVal);
          setDetailsPage(false);
          setEditMode({
            ...editMode,
            [tabVal]: true,
          });
          setIsEditing(true);
        }else{
          navigate(path.getSubscriptionGroupDetail(+id, +grpId), {replace: true});
          setCurrentTab(tabVal);
          setDetailsPage(true);
          setIsDefault(isDefault);
        }
      }else if(index !== -1){
        setCurrentTab(tabVal);
        if(removedHashSymbol.indexOf('edit') !== -1){
          setIsEditing(true);
          setEditMode({...editMode, [tabVal]:true})
        }
      }else if(hashVal[0] === 'name' || hashVal[0] === 'email'){
        setIsEditing(true);
        setEditMode({...editMode, details:true})
      }else{
        setHash(tabs[0].value,navigate);
      }
    }else if(location.search){
      //do nothing
    }else{
      setHash(tabs[0].value,navigate);
    }
  }
  
  useEffect(() => {
    if(access.canRead()){
      const { hash, pathname, search } = location;
      model.PathName = search ? pathname + search : pathname + hash;
    }
  },[location]);

  useEffect(() => {
    (async () => {
      // await getManagers.apply(null,[params]);
      fetchData();
      handleBrowserRefresh();
    })();
  },[]);

  const { subscription } = state;

  if (Object.keys(subscription).length === 0) {
    return <LoadingScreen />;
  }

  model.Title = subscription.name;

  const setEditModeSubscription = (isEdit: boolean) => {
    setEditMode({
      ...editMode,
      [currentTab]: isEdit,
    });
    setIsEditing(isEdit);
  };

  const switchMode = (isEdit: boolean, value:string) => {
    setHash(value,navigate);
    setEditModeSubscription(isEdit);
  };

  const handleTabsChange = async (event: ChangeEvent<any>, value: string): Promise<void> => {
    setHash(value, navigate, model.Path.getSubscriptionDetail(+id));
    setEditModeSubscription(false);
    setCurrentTab(value);
    setDetailsPage(false);
  };

  const handleRefreshSubscription = async (tabVal: string, value?: SubscriptionResource): Promise<void> => {
    setHash(tabVal, navigate);
    if(tabVal === tabs[0].value)
      dispatch(fetchDetailsSubscription(value,sDatabank.databanks));
    else
      await getSubscriptionById(sDatabank.databanks);
    setEditModeSubscription(false);
  };

  const editSubscription = () : void => {
    if(!location.search)
       setEditHash('edit', navigate);
    if(groupId){
      setGroupId(null);
    }
    setEditModeSubscription(true);
  };

  const editAccesGroup = async (value: EditAccessGroup) : Promise<void> => {
    const path = model.Path;
    const { isEdit, groupId, detailsPage, isDefault} = value;
    if(detailsPage){
      navigate(path.getSubscriptionGroupDetail(+id,groupId),{replace: true});
      setDetailsPage(true);
      setIsDefault(isDefault);
    }else{
      if(isEdit){
        navigate(path.getSubscriptionGroupEdit(+id, groupId),{replace: true});
      }else{
        navigate(path.getSubscriptionGroupList(+id),{replace: true});
      }
      setDetailsPage(false);
    }
    setEditModeSubscription(isEdit);
    setGroupId(groupId);
  };

  const handleRefreshCustomer = async (isEdit: boolean, value: string): Promise<void> => {
    setHash(value, navigate);
   //await getManagers.apply(null,[params]);
    setEditModeSubscription(isEdit);
  };

  const renderContent = () => {
    switch (currentTab) {
      case tabs[0].value: //details
        if(editMode.details){
          return (
            <DetailsEditForm
              model={model}
              subscription={subscription}
              onHandleRefreshSubscription={(l) => handleRefreshSubscription(tabs[0].value, l)}
              onSwitchMode={l => switchMode(l, tabs[0].value)}
              dispatch={dispatchGroupList}
            />
          );
        }
        return <Details 
                  model={model} 
                  subscription={subscription} 
                  groupList={groupList}
                />;
      case tabs[1].value: //access
        if(editMode.accesses){
          return (
            <DoorAccessProfileProvider>
              <UxpertiseProvider>
                <AccessForm
                  model={model}
                  subscription={subscription}
                  onHandleRefreshSubscription={() => handleRefreshSubscription(tabs[1].value)}
                  onSwitchMode={l => switchMode(l, tabs[1].value)}
                />
              </UxpertiseProvider>
            </DoorAccessProfileProvider>
          );
        }
        return (
          <AccessTypeList 
            authorize={{databank: sDatabank.isAuthorize}}
            databanks={sDatabank.databanks}
            lists={subscription}
          />
        );
      case tabs[2].value: //access group
        if(editMode.groups){
          return ( 
            <AccessGroupEditView 
              model={model}
              subscription={subscription} 
              groupId={groupId || +grpId} 
              onEditAccessGroup={(l) => editAccesGroup(l)} 
              dispatch={dispatchGroupList}
            /> 
          );
        }else{
            if(detailsPage){
              return ( 
                <AccessGroupViewedDetailsView
                  model={model}
                  isDefault={isDefault}
                  subscription={subscription} 
                  groupId={groupId || +grpId} 
                  onEditAccessGroup={(l) => editAccesGroup(l)}
                /> 
              );
            }
            return ( 
              <AccessGroupListView 
                subscriptionId={subscription.id} 
                onEditSubscription={() => editSubscription()}
                onEditAccessGroup={(l) => editAccesGroup(l)} 
              /> 
            );
        }
        case tabs[3].value: //members
          if(editMode.members){
            return (
              <SubscriptionProvider withoutSubscription={true} customerSubscription={true}>
                <CustomerEditView 
                  model={CustomerModel.getInstance()}
                  customer={{ subscriptionId : +id, lawDomains : [], wfProfile: subscription?.wfProfile } as CustomerResource } 
                  onHandleRefreshCustomer={(l) => handleRefreshCustomer(l, tabs[3].value)}
                  onSwitchMode={(l) => switchMode(l, tabs[3].value)}
                />
              </SubscriptionProvider>
            )
          }else{
            return (
              <CustomerListView
                model={model}
                subscriptionId={+id}
                isActive={subscription.active}
                onEditSubscription={editSubscription}
              />
            )
          }
        /*
          case tabs[4].value :
            return ( 
              <ManagerListView
                subscription={subscription}
                customers={sManagers.customers}
                managers={sManagers.managers}
                onHandleRefreshCustomer={() => handleRefreshCustomer(false, tabs[4].value)}
                isAuthorize={sCustomer.isAuthorize}
              />
            );
        */
      default:
        return '';
    }
  };

  return (
    <Page sx={root} title={model.DetailPageTitle}>
      <Container maxWidth={false}>
        <GenericHeader 
          id={id} 
          model={model} 
          headerTitle={model.Title} 
          titleBadge={{label: SbGroup[subscription.group]}}
          editMode={editMode.details || editMode.accesses}
          actions={(currentTab != tabs[2].value && currentTab != tabs[3].value) && ['editDetail']}
          onEditDetail={() => editSubscription()}
        />
        <Box mt={2}>
          <Tabs onChange={handleTabsChange} scrollButtons='auto' value={currentTab} variant='scrollable' textColor='secondary'>
            {tabs.map(tab => (
              <Tab key={tab.value} label={tab.label} value={tab.value} />
            ))}
          </Tabs>
        </Box>
        <Divider />
        <Box mt={3}>{renderContent()}</Box>
      </Container>
    </Page>
  );
};

export default SubscriptionDetailsView;
