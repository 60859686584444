import React, { useEffect, useState } from 'react';
import type { Dispatch, FC } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Box, CardContent, Grid, Typography } from '@mui/material';
import {
  Formik,
  handleChange,
  Mode,
  CaijInput,
  SubscriptionModel,
  CaijButtonSubmit,
  CaijButtonReset,
  Authorize,
  btnSubmit,
  FormikHelpers,
  CaijTextarea,
  CaijCard,
  ACCOUNT_TITLE,
  addGroup,
  updateGroup,
  QUICK_ACCESS,
  getOptionLabel,
  SUBSCRIPTION_GROUP_WELCOM_MESSAGE
} from 'src/common';
import type {
  SubscriptionResource,
  SubscriptionGroupResourceForEdit,
  SubscriptionGroupResource,
  WfProfileDto,
} from 'src/common/types';
import SubscriptionAccessGroup from 'src/components/access/SubscriptionAccessGroup';
import printMessage from 'src/views/errors/MessageError';
import MyCard from 'src/components/card/MyCard';
import { useEnabledSymProfiles } from 'src/functions/hooks/symphony';
import AccountForm from 'src/components/subscription/Forms/AccountForm';
import { EditAccessGroup } from 'src/types/accessGroup';
import { Action } from 'src/store/reducers/subscription/SubscriptionReducer';
import QuickAccess from 'src/components/subscription/Forms/QuickAccess';
import CaijDraftEditor from 'src/components/draftEditor/CaijDraftEditor';
import { Root } from 'src/components/styled';

export interface AccessGroupEditFormProps {
  model: SubscriptionModel;
  allowedInsert: boolean;
  group: SubscriptionGroupResource
  subscription: SubscriptionResource;
  onEditAccessGroup: (value: EditAccessGroup) => void;
  getGroup: (groupId: number, subscriptionId: number, allowedInsert?: boolean) => Promise<void>;
  dispatch: Dispatch<Action>;
}

const AccessGroupEditForm: FC<AccessGroupEditFormProps> = ({
  model,
  allowedInsert,
  group,
  subscription,
  onEditAccessGroup,
  getGroup,
  dispatch
}) => {
  const [isExpireOnValid, setIsExpireOnValid] = useState<boolean>(true);
  const { AccessGroupName, AccessGroupNote, AccessGroupWfProfile, QuickAccessName, QuickAccessUrl, WelcomeMessageFr, WelcomeMessageEn } = model;
  const [selectedGroupProducts, setSelectedGroupProducts] = useState<{ ids: number[], selected: boolean }>({
    ids: [],
    selected: false
  });
  const [selectedGroupDatabanks, setSelectedGroupDatabanks] = useState<{ ids: number[], selected: boolean }>({
    ids: [],
    selected: false
  });
  const [selectedGroupDocCollections, setSelectedGroupDocCollections] = useState<{ ids: number[], selected: boolean }>({
    ids: [],
    selected: false
  });
  const [selectedGroupParameters, setSelectedGroupParameters] = useState<Map<number, string>>();
  const enabledSymProfile = useEnabledSymProfiles();
  
  useEffect(() => {
		if(allowedInsert){
      dispatch(addGroup(group));
    }
	},[allowedInsert]);

  const handleSubmit = async (values: SubscriptionGroupResource, formikHelpers: FormikHelpers<typeof values>) => {
    formikHelpers.validateForm(values);
    const model = new SubscriptionModel();
    let submitData = values as SubscriptionGroupResourceForEdit;
    let { accountRenewMode } = submitData;
    if(!model.validateOnApprovalRenewMode(accountRenewMode))
      submitData.formUrl = "";
    if (submitData && values.id) {
      model.SelectedProductParameters = selectedGroupParameters;
      model.saveListAccessGroupRecords(submitData, values, selectedGroupProducts, selectedGroupDatabanks , selectedGroupDocCollections);
      const response = await model.updateGroup(subscription.id, group.id, submitData);
      const { error } = model;
      if (!error) {
        const { status, message } = response;
        printMessage({status,message});
        dispatch(updateGroup(values.id, values));
        onEditAccessGroup({isEdit: false});
      }
    } else {
      submitData = values as SubscriptionGroupResourceForEdit;
      model.SelectedProductParameters = selectedGroupParameters;
      model.saveListAccessGroupRecords(submitData, values, selectedGroupProducts, selectedGroupDatabanks, selectedGroupDocCollections)
      const response = await model.insertGroup(subscription.id, submitData);
      const { error } = model;
      if (!error) {
        const { status, message, data: {groupId} } = response;
        printMessage({status,message});
        await getGroup(groupId, subscription.id, true);
        onEditAccessGroup({isEdit: false});
      }
    }
  }
  
  const { isLoading, isEmptyList, profiles } = enabledSymProfile;

  return (
    <Formik
      initialValues={{...group}}
      validationSchema={Yup.object().shape({
        name: AccessGroupName.required(true),
        wfProfile: AccessGroupWfProfile.nullable(true),
        quickAccess: Yup.array().of(
          Yup.object().shape({
            name: QuickAccessName.required(true),
            url: QuickAccessUrl.required(true),
          })
        ),
      })}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={handleSubmit}
    >
      {({errors, handleSubmit, setFieldValue, values, touched, isSubmitting}) => (
        <form onSubmit={handleSubmit}>
          <MyCard>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item md={12} sm={12} xs={12}>
                    <Typography variant='h5' sx={{color:'text.primary', mb: 3}}>
                      {group.id ? "Éditer un groupe" : "Ajouter un groupe"}
                    </Typography>
                    <CaijInput
                      name={AccessGroupName.Name}
                      required
                      value={values.name}
                      label={AccessGroupName.Label}
                      error={Boolean(touched.name && errors.name)}
                      helperText={touched.name && errors.name}
                      onHandleChange={handleChange}
                      InputLabelProps={{ shrink: true, required: true }}
                      inputAttr={{maxLength: AccessGroupName.MaxLength, 'data-testid' : AccessGroupName.Name}}
                      setFieldValue={setFieldValue}
                    />
                  </Grid>
                  <Grid item md={12} sm={12} xs={12}>
                      {(!group.id || (group.id && !group.isDefault)) && (
                        <CaijInput
                          label={AccessGroupWfProfile.Label}
                          id={AccessGroupWfProfile.Name}
                          name={AccessGroupWfProfile.Name}
                          select
                          value={values.wfProfile || ''}
                          variant='outlined'
                          InputLabelProps={{ shrink: true }}
                          error={Boolean(touched.wfProfile && errors.wfProfile)}
                          helperText={(touched.wfProfile && errors.wfProfile) || ' '}
                          inputAttr={{ 'aria-label': AccessGroupWfProfile.Name, 'data-testid': AccessGroupWfProfile.Name }}
                          sx={{ mt: 1, mb: 2 }}
                          onHandleChange={handleChange}
                          setFieldValue={setFieldValue}
                        >
                          <option value=''>{getOptionLabel(AccessGroupWfProfile.Label)}</option>
                          {isLoading &&
                            !isEmptyList &&
                            profiles.map((profile: WfProfileDto) => (
                              <option value={profile.name} key={profile.name}>
                                {profile.name}
                              </option>
                            ))}
                        </CaijInput>
                      )}
                      <CaijTextarea
                        name={AccessGroupNote.Name}
                        id={AccessGroupNote.Name}
                        rows="4"
                        margin="none"
                        label={AccessGroupNote.Label}
                        value={values.note}
                        InputLabelProps={{ shrink: true }}
                        onHandleChange={handleChange}
                        setFieldValue={setFieldValue}
                      />
                  </Grid>
                  <Grid item md={6} sm={12} xs={12}>
                    <CaijCard title={ACCOUNT_TITLE} sx={{mb:3}}>
                      <AccountForm
                        model={model}
                        subscriptionGroup={values}
                        onHandleChange={handleChange}
                        setFieldValue={setFieldValue}
                        setIsExpireOnValid={setIsExpireOnValid}
                      />
                    </CaijCard>
                  </Grid>
                  <Grid item md={12} sm={12} xs={12}>
                    <CaijCard title={SUBSCRIPTION_GROUP_WELCOM_MESSAGE}>
                    <Box display={{md: "flex", lg:'flex', sm: 'block', xs: 'block'}}>
                      <Box sx={{mr: {md: 2, lg:2, sm: 0, xs: 0}}}>
                        <CaijDraftEditor
                          title={WelcomeMessageFr.Label}
                          dataTestId={WelcomeMessageFr.Name}
                          value={values.welcomeMessageFr}
                          name={WelcomeMessageFr.Name}
                          setFieldValue={setFieldValue}
                          allowedBackgroundColor={false}
                        />
                      </Box>
                      <Box>
                        <CaijDraftEditor
                          title={WelcomeMessageEn.Label}
                          dataTestId={WelcomeMessageEn.Name}
                          value={values.welcomeMessageEn}
                          name={WelcomeMessageEn.Name}
                          setFieldValue={setFieldValue}
                          allowedBackgroundColor={false}
                        />
                      </Box>
                    </Box>
                    </CaijCard>
                  </Grid>
                  <Grid item md={12} sm={12} xs={12}>
                    <Root>
                      <CaijCard title={QUICK_ACCESS}>
                        <QuickAccess
                          model={model}
                          touched={touched}
                          errors={errors}
                          subscriptionGroup={values}
                          onHandleChange={handleChange}
                          setFieldValue={setFieldValue}
                        />
                      </CaijCard>
                    </Root>
                  </Grid>
                  <Grid item md={6} sm={12} xs={12}>
                    { group.id && !group.isDefault && !allowedInsert && (
                        <SubscriptionAccessGroup
                          subscription={subscription}
                          group={group}
                          setSelectedGroupProducts={setSelectedGroupProducts}
                          selectedGroupProducts={selectedGroupProducts}
                          setSelectedGroupDatabanks={setSelectedGroupDatabanks}
                          selectedGroupDatabanks={selectedGroupDatabanks}
                          setSelectedGroupDocCollections={setSelectedGroupDocCollections}
                          selectedGroupDocCollections={selectedGroupDocCollections}
                          setSelectedGroupParameters={setSelectedGroupParameters}
                        />
                    )}
                    <Authorize
                      resourceCode={model.ResourceCode}
                      mode={subscription.id ? Mode.edit : Mode.add}
                    >
                      <CaijButtonSubmit disabled={isSubmitting || !isExpireOnValid} sx={btnSubmit} />
                    </Authorize>
                    <CaijButtonReset disabled={isSubmitting} onSwitchMode={(l) => onEditAccessGroup({isEdit:l, groupId:group.id})} />
                  </Grid>
              </Grid>
            </CardContent>
          </MyCard>
        </form>
      )}
    </Formik>
  );
};

AccessGroupEditForm.propTypes = {
  group: PropTypes.object.isRequired,
  subscription: PropTypes.object.isRequired,
  onEditAccessGroup: PropTypes.func.isRequired
};

export default AccessGroupEditForm;
