import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import type { ContentPublicationsResource } from 'src/common/types';
import {
  Box,
  SvgIcon,
} from '@mui/material';
import {ContentPublicationsModel, labelConfig} from 'src/common';
import { FileText as FileTextIcon } from 'react-feather';
import CaijTypography from 'src/components/typography';
import { useDocument } from 'src/functions/hooks/document';
import CaijLabel from 'src/components/label/CaijLabel';
import { getNameFr } from 'src/views/content/document/DocumentDetailsView/Details/ContentInfo';

export interface ContentInfoProps {
  contentPublication: ContentPublicationsResource;
  model: ContentPublicationsModel;
}

const ContentInfo: FC<ContentInfoProps> = ({model, contentPublication}) => {
  const { sCollection, sDocumentType } = useDocument();
  const { collection, documentType, publisher } = contentPublication;
  return (
    <Box sx={{mb:'2em'}} display='flex'>
      <SvgIcon fontSize='small'>
        <FileTextIcon />
      </SvgIcon>
      <Box display='flex' marginLeft='0.5em' flexDirection='column'>
        <Box display='flex' alignItems='center'>
          <CaijTypography sx={{ fontWeight: 'bold' }}>Contenus</CaijTypography>
        </Box>
        { collection && sCollection.isLoading && <CaijLabel label={model.Collection.Label}>{getNameFr(sCollection.docCollections, collection)}</CaijLabel> }
        { sDocumentType.allChecked && <CaijLabel label={labelConfig.contentType}>{getNameFr(sDocumentType.documentTypes,documentType)}</CaijLabel> }
        { publisher && <CaijLabel label={model.Publisher.Label}>{publisher}</CaijLabel> }
      </Box>
    </Box>
  );
};

ContentInfo.propTypes = {
  contentPublication: PropTypes.object.isRequired
};

export default ContentInfo;