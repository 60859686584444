import { ErrorConfig, KnownDatabankLinkType } from './common/types';

export const auth0Config = {
  client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
};

export const errorConfig : ErrorConfig = {
  max: '&1 doit contenir au maximum &2 caractères',
  required: '&1 est obligatoire',
  invalid: '&1 est invalide',
  exist: '&1 existe déjà',
  numeric: '&1 doit être numérique',
  extension: '&1',
  endDateMustBeLater: '&1 doit être plus tard que la date de début',
  mustBeGreaterThanToday: "&1 doit être plus tard que la date d'aujourd'hui",
  mustBeGreaterThanZero: '&1 doit être supérieur que zéro',
  deletedMessage: '&1 doit être écrit « supprimer » non sensible à la casse'
};

export const googleApiKey = { 
  key: 'AIzaSyB2ZqwMsckSBqF0KtpBaxPjwch8EP04pFw',
};

export const apiConfig = {
  url: process.env.REACT_APP_CAIJ_API_URL,
  route: {
    me: '/employee/me',
    employee: '/employee'
  },
};

export const labelConfig = {
  warning: 'Avertir',
  code: 'Code',
  nameFr: 'Nom',
  nameEn: 'Name',
  resourceName: 'Nom',
  resourceCode: 'Code',
  description: 'Description',
  contactName: 'Nom',
  primarySubscription: 'Abonnement Principal',
  startDate: 'Date de début',
  endDate: 'Date de fin',
  active: 'Actif',
  inactive: 'Inactif',
  contactEmail: 'Courriel',
  contactPhone: 'Téléphone',
  contactCellphone: 'Téléphone Cellulaire',
  contactPhoneExt: 'Poste',
  line1: 'Ligne d\'adresse 1',
  line2: 'Ligne d\'adresse 2',
  noWarning: 'Non Avertir',
  city: 'Ville',
  state: 'Province/État',
  zip: 'Code postal',
  country: 'Pays',
  firstname: 'Prénom',
  lastname: 'Nom de famille',
  role: 'Rôle',
  card: 'Carte',
  libraryAccessEnabled: 'Logiciel sur les postes de travail',
  webAccessEnabled: 'En ligne',
  note: 'Note interne (non public)',
  accessNote: 'Note interne',
  libraryAccessUrl: 'URL',
  libraryApp: 'Logiciels sur poste seulement',
  libraryAccessInfoFr: 'Conditions particulières à afficher',
  libraryAccessInfoEn: 'Particulars terms to display',
  webAccessUrl: 'URL',
  workstations: 'Nombre de poste de travail',
  photocopypost: 'Nombre de poste de photocopie',
  telephonepost: 'Nombre de poste de téléphone',
  libraryType: 'Type de bibliothèque',
  cloakroom: 'Vestiaire',
  noCloakroom: 'Non Vestiaire',
  tollfree: 'Fax sans frais',
  longitude: 'Longitude',
  latitude: 'Latitude',
  searchLocation: 'Rechercher location',
  searchEmployee: 'Rechercher employé(e)',
  detailsFr: 'Détail',
  detailsEn: 'Detail',
  requireQty: 'Quantité requise',
  cloakroomInfo: 'Information vestiaire',
  type: 'Type',
  logoBtnFr: 'Ajouter un logo',
  logoEditBtnFr: 'Remplacer un logo',
  logoBtnEn: 'Add a logo',
  logoEditBtnEn: 'Replace a logo',
  imageBtnFr: 'Ajouter une image',
  imageEditBtnFr: 'Remplacer une image',
  imageBtnEn: 'Add image',
  imageEditBtnEn: 'Replace image',
  cover: 'Page couverture',
  coverBtn: 'Ajouter une page couverture',
  coverEditBtn: 'Remplacer une page couverture',
  image: 'Image',
  no: 'Non',
  title: 'Titre',
  url: 'Url',
  secured: 'Sécurisé',
  rank: 'Rang',
  logo: 'Logo',
  status: 'Statut',
  customerStatus: 'Statut du compte',
  data: 'Donnée',
  workingHours: 'Heure(s) de travail',
  subscription: 'Abonnement',
  noCaij: 'Numéro CAIJ',
  noCard: 'No. Carte',
  yes: 'Oui',
  job: 'Emploi',
  birthdate: 'Date de naissance',
  organization: 'Organisation',
  lcapCommercials: 'Envois commerciaux',
  lcapNewsletter: 'Infolettres',
  lcapImprovement: 'Sondages et améliorations',
  emailVerified: 'Courriel vérifié',
  emailNotVerified: 'Courriel non vérifié',
  defaultLibrary: 'Bibliothèque',
  preferred: 'Préférée',
  notPreferred: 'Non Préférée',
  verify: 'Vérifié',
  notVerifiy: 'Non Vérifié',
  require: 'Requis',
  notRequire: 'Non Requis',
  balance: 'Solde',
  language: 'Langue de préférence',
  yesIWantIt: "Oui, j'accepte",
  yesIPreferedIt: 'Oui, je préfère',
  visits: 'Visites en bibliothèque',
  fees: 'Frais en cours',
  holds: 'Prêts en cours',
  library: 'Bibliothèque',
  totalHolds: 'Total de prêts',
  access: 'Date',
  cost: 'Coût',
  noDataToPresent: 'Aucunes données à présenter',
  inWait: 'En attente de paiement',
  amountOwed: 'Total',
  hour: 'Heure',
  amount: 'Montant',
  operation: 'Opérations',
  change: 'Modification',
  doneBy: 'Fait par',
  titleFr: 'Titre',
  titleEn: 'Title',
  messageEn: 'Message (English)',
  messageFr: 'Message (Français)',
  closable: "Permettra la fermeture (le message pourra être fermé par l'utilisateur)",
  visible: 'Sera Actif (le message sera affiché durant la période de temps sélectionnée)',
  isVisible: 'Visible',
  isInvisible: 'Pas visible',
  isClosable: 'Fermable',
  isNotClosable: 'Pas fermable',
  customerAllowed: 'Ajout de membre possible',
  permissions: 'Permissions',
  public: 'Public',
  authentication: 'Authentication requise',
  private: 'Privé',
  enabled: 'Active',
  dayOfTheWeek: 'Journée de la semaine',
  contentType: 'Types de contenu',
  fr: 'Française',
  en: 'English',
  webAccess: "Accès à l'Espace CAIJ",
  mobileAccess: 'Accès à Mon CAIJ',
  memberGuaranteeCount: 'Nombre de cautionnés possible par membre de cet abonnment',
  hasContent: 'Cet abonnement a du contenu qui lui appartient',
  expired: 'Fin du cautionnement',
  juridiction: 'Juridiction',
  coverage: 'Couverture',
  otherCodes: 'Autres Codes',
  requiredParameter: 'Paramètre requis',
  accountExpiryRequired: 'Fin du compte',
  accessType: 'Type d\'accès',
  groups: 'Groupe',
  endorsed: 'Cautionneur',
  password: 'Mot de passe',
  cardNum: 'Numéro de la carte',
  transactionId: 'Transaction ID',
  accountType: 'Type de compte',
  endorsedBy: 'Cautionné',
  createdDate: 'Date de création',
  publishing: 'Publier immédiatement le message',
  wfLibrary: 'Bibliothèque Symphony',
  wfProfile: 'Profil Symphony',
  cellPhone: 'Cellulaire',
  lawName: 'Nom de la loi',
  updatedDate: 'Date de mise à jour',
  lawType: 'Type',
  abreviation: 'Abréviation',
  filterCaseLaw: 'Jurisprudence',
  lawIndexingKeyword: "Mot-clé d'indexation de la loi",
  doorCardProfile: 'Kantech - Profils de cartes',
  parent: 'Parent',
  lawDomain: 'Domaine de droit',
  expiryDate: 'Date d\'expiration',
  statusInfo: 'Information statut',
  groupCategory: 'Groupe catégorie',
  blocked: 'Compte restreint',
  collection: 'Collection',
  authors: 'Auteur',
  searchable: 'Indexer pour la recherche',
  publishedDate: 'Date de publication',
  PublicationVisible: 'Visible',
  publication: 'Publication',
  documentStore: 'Magasin de document',
  documentStoreInfo: 'Informations sur la banque de documents',
  identifier: 'Identifiant',
  rssDate: 'Date RSS',
  otherLangDocId: 'Autre langue du document ID',
  law: 'Lois annotées',
  metadatas: 'Metadatas',
  docLanguage: 'Langue du document',
  documentType: 'Type de document',
  documentId: 'Document ID',
  databankAccessType: 'Type d\'accès',
  databankLinkType:'Type d\'url',
  urlLibrary: 'URL pour la bibliothèque',
  ezproxyCollName: 'Ezproxy',
  accessGroup: "Groupe d'accès",
  groupType: "Type de groupe",
  pendingValue: "Courriel en attente",
  archived: "Archivé",
  archivedDate: "Date d'archivage",
  clientName: "Nom du client"
};

export const jurisdictionConfig = {
   'ab': 'Alberta',
   'bc': 'Colombie-Britannique',
   'ca': 'Canada (fédéral)',
   'mb': 'Manitoba',
   'nb': 'Nouveau-Brunswick',
   'nl': 'Terre-Neuve-et-Labrador',
   'ns': 'Nouvelle-Écosse',
   'nt': 'Territoires du Nord-Ouest',
   'nu': 'Nunavut',
   'on': 'Ontario',
   'pe': 'Île-du-Prince-Édouard',
   'qc': 'Québec',
   'sk': 'Saskatchewan',
   'yk': 'Yukon'
};

let ObjDatabankLinkTypeList = new Object
ObjDatabankLinkTypeList[KnownDatabankLinkType.None] = '';
ObjDatabankLinkTypeList[KnownDatabankLinkType.Caij] = 'Via proxy CAIJ';
ObjDatabankLinkTypeList[KnownDatabankLinkType.Bibliomagis] = 'Via proxy Bibliomagis';
ObjDatabankLinkTypeList[KnownDatabankLinkType.Direct] = 'Direct';
ObjDatabankLinkTypeList[KnownDatabankLinkType.Clavardage] = 'Via Clavardage';
export const DatabankLinkTypeList = ObjDatabankLinkTypeList;

export const initTinyMCE : any = {
  plugins: 'print preview paste importcss searchreplace autolink save directionality code visualblocks visualchars fullscreen link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons',
  toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl',
  toolbar_mode: 'floating',
  autosave_retention: "60m",
  width: "100%",
  height: 800,
  language: 'fr_FR',
  content_langs: [
    { title: 'French', code: 'fr' },
    { title: 'English', code: 'en' }
  ],
  invalid_styles: {
    '*': 'font-family line-height height', // Global invalid styles
    'a': 'color text-decoration text-transform' // Link specific invalid styles
  },
}