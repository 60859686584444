import { TableBody, Tooltip } from "@mui/material";
import PropTypes from 'prop-types';
import { FC, useEffect, useState } from "react";
import { 
    EmptyList, 
    EnhancedTableHead,
    Label, 
    labelConfig, 
    formatDate,
    FIRST_PAGE,
    getOpacity,
    NavigateFunction
} from "src/common";
import { DocumentOptionResource, SearchFilter, Sort } from "src/common/types";
import MyCard from "src/components/card/MyCard";
import CaijTable from "src/components/table/CaijTable";
import { CaijTableCell } from "src/components/table/CaijTableCell";
import SpecialFilesModel from "src/model/content/SpecialFiles";
import CaijDialogs from "src/components/dialog";
import useAuth from "src/hooks/useAuth";
import CaijTablePagination from "src/components/pagination/CaijTablePagination";
import CaijTableCellAction from "src/components/table/CaijTableCellAction";
import { Paged } from "src/types/pagination";
import { CaijTableRowClickable } from "src/components/table/CaijTableRowClickable";
import useSort from "src/functions/hooks/sort";

export interface ResultsProps {
    model: SpecialFilesModel;
    paged: Paged;
    specialFiles: DocumentOptionResource[];
    isEmptyList: boolean;
    onDeleteSpecialFile: (id: string) => Promise<void>;
    onRemoveLock: (id: string) => Promise<void>;
    navigate: NavigateFunction;
}

const Results: FC<ResultsProps> = ({
    model,
    paged,
    specialFiles,
    isEmptyList,
    onDeleteSpecialFile,
    onRemoveLock,
    navigate
}) => {
    const path = model.Path;
    const [selectedRow, setSelectedRow] = useState([]); 
    let currentPage = paged.currentPage - 1;
    const [filter, setFilter] = useState<SearchFilter>({page: currentPage, sortedBy: '', sort: null});
    const { user } = useAuth();
    const { order, orderBy, setOrderBy, handleRequestSort } = useSort();

    useEffect(() => setOrderBy('title'),[]);

    useEffect(() => {
        const urlParams = new URLSearchParams(location.search);
        const { Filters: { page, sort, sortedBy } } = model;
        let pageVal = FIRST_PAGE, sortVal = '', sortedByVal = '';
        for (const [key, value] of urlParams.entries()) {
          switch(key){
            case page:
              pageVal = +value;
              break;
            case sortedBy:
                sortedByVal = value;
                break;
            case sort:
                sortVal = value;
                break;
          }
        }
        setFilter((prevState: SearchFilter) => {
          return {
            ...prevState,
            page: +pageVal,
            sortedBy: sortedByVal,
            sort: sortVal as Sort
          }
        });
    },[location.search]);
  
    const renderTableCell = (row: DocumentOptionResource) => {
        const opacity = getOpacity(row.visible);
        return (
            <>
                <CaijTableCell opacity={opacity}>{row.title}</CaijTableCell>
                <CaijTableCell opacity={opacity}>{formatDate(row.published)}</CaijTableCell>
                <CaijTableCell>
                    <Label color={row.metadatas?.archived ? 'success' : 'error'}>
                        <Tooltip title={row.metadatas?.archived ? formatDate(row.metadatas.archived) : ""}>
                            <div>{row.metadatas?.archived ? labelConfig.yes : labelConfig.no}</div>
                        </Tooltip>
                    </Label>
                </CaijTableCell>
                <CaijTableCell allowedLabel active={row.visible} txtActive={labelConfig.yes} txtInActive={labelConfig.no} />
                <CaijTableCellAction 
                    resourceCode={model.ResourceCode}
                    pageEditUrl={path.getEdit(row.documentId)}
                    handleToggle={() => SpecialFilesModel.handleToggle(row.documentId, selectedRow, setSelectedRow)}
                    documentLocks={row.documentLocks}
                    user={user}
                    handleRemoveLock={() => onRemoveLock(row.documentId)}
                >
                    <CaijDialogs
                        dialog={model.Dialog}
                        isOpen={selectedRow.indexOf(row.documentId) !== -1}
                        onSubmit={async () => {
                            await onDeleteSpecialFile(row.documentId);
                            setSelectedRow([]);
                        }}
                        setSelectedRow={setSelectedRow}
                    />
                </CaijTableCellAction>
            </>
            )
    }

    if (isEmptyList) {
        return (
            <EmptyList />
        );
    }

    return (
        <MyCard>
            <CaijTable>
                <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={(e,p) => handleRequestSort<SpecialFilesModel>(e,p,model,filter,navigate)}
                    headCells={model.getHeadCells()}
                />
                <TableBody>
                    {   specialFiles.map((row: DocumentOptionResource, index: number) => (
                                <CaijTableRowClickable key={index} path={path.getDetail(row.documentId)}>
                                    {renderTableCell(row)}
                                </CaijTableRowClickable>
                           )
                        )
                    }
                </TableBody>
            </CaijTable>
            <CaijTablePagination
                paged={paged} 
                model={model} 
                filter={filter} 
                page={currentPage}
                navigate={(model) => navigate(model.getUrlEncode.apply(model,model.getParams()))}
            />
        </MyCard>
    )
}

Results.propTypes = {
    specialFiles: PropTypes.array.isRequired,
    isEmptyList: PropTypes.bool
}

Results.defaultProps = {
    isEmptyList: null
}

export default Results;