import { useEffect, useReducer } from "react";
import { DocCollectionModel, fetchDocCollections, Forbidden, Unauthorized, _docCollection } from "src/common";
import { DocumentCollectionResource } from "src/common/types";
import { FETCH_DOCUMENT_COLLECTIONS } from "src/store/actions/ActionTypes";

export interface Collection {
    docCollections: DocumentCollectionResource[];
    isEmptyList: boolean;
    isLoading: boolean;
    isAuthorize: boolean;
}

export default function useCollection() {
    const initialState:Collection = {
      docCollections: [],
      isEmptyList: true,
      isLoading: false,
      isAuthorize: false,
    };
    const [state, dispatch] = useReducer(_docCollection, initialState);
    useEffect(() => {
      const initialise = async () => {
        const model = new DocCollectionModel();
        model.skipHandleError = true;
        const collections = await model.getDocCollections();
        const { error } = model;
        if (error) {
          if (error.status === Forbidden || error.status === Unauthorized) {
            dispatch(fetchDocCollections(collections, true, false, false));
          }else{
            dispatch(fetchDocCollections(collections, true, true, true));
          }
        } else {
          dispatch(fetchDocCollections(collections, true, !Array.isArray(collections) || collections.length === 0, true));  
        }
      }
      ;
      (async () => await initialise())();
      return () => { dispatch({type: FETCH_DOCUMENT_COLLECTIONS, payload: initialState}); }
    },[]);
    const { docCollections, isEmptyList, isLoading, isAuthorize } = state;
    return { docCollections, isEmptyList, isLoading, isAuthorize, allChecked: (!isEmptyList && isLoading && isAuthorize)};
};
