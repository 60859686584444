import {
  Success,
  NoContent,
  MSG_NO_CONTENT_ERROR,
  Created,
} from 'src/common';
import type {
  CreateResponse,
  Error,
  SchoolEstablishmentResource,
  SchoolEstablishmentResourceForCreate,
  SchoolEstablishmentResourceForEdit
} from 'src/common/types';
import AppModel from '../App';
import axios from 'src/utils/axios';
import { BtnOk } from 'src/components/dialog/CaijDialog';
import { FormModel } from '../Form';
import printMessage from 'src/views/errors/MessageError';

export default class SchoolEstablishmentModel extends AppModel 
{
  private static _instance: SchoolEstablishmentModel;
  readonly Name = new FormModel('name','Nom', 320);
  readonly ValidateEmailDomain = new FormModel('validateEmailDomain','Validation du courriel pour un des domaines spécifiés');
  readonly NotarialLawMasterOffer = new FormModel('notarialLawMasterOffer','Maitrise en droit notariale est offerte par cet établissement');
  readonly Domain = new FormModel('domain','Domaine', 255);
  
  constructor(){
    super('/schoolestablishment');
    this.initialize();
  }

  private initialize(){
    this._resourceCode = 'SCHOOL';
    this._headerTitle = 'Liste des établissements scolaire';
    this._btnAddText = 'Ajouter un établissement scolaire';
    this.Path.PathName = '/school-establishment';
    this.Dialog.Header = "Supprimer l'établishement scolaire";
    this.Dialog.BtnText = BtnOk.DELETED;
    this.Dialog.Name = "l'établishement scolaire";
  }

  get Section(){
    return {
      resourceCode: this._resourceCode, 
      title: 'Établissements scolaire',
      href: this.Path.Home,
      visible: true
    }
  }

  get EmailDomain(){
    return 'emailDomains';
  }

  getHeadCells(){
    return [
      {
        id: this.Name, width: '58%', numeric: false, disablePadding: false, label: this.Name.Label
      },
      {
        id: this.NotarialLawMasterOffer, width: '22%', numeric: true, disablePadding: false, label: this.NotarialLawMasterOffer.Label
      },
      {
        id: this.ValidateEmailDomain, width: '20%', numeric: true, disablePadding: false, label: this.ValidateEmailDomain.Label
      },
      {
        id: '', width: '0%'
      }
    ];
  };

  static getInstance(): SchoolEstablishmentModel {
    if (!SchoolEstablishmentModel._instance) {
      SchoolEstablishmentModel._instance = new SchoolEstablishmentModel();
    }
    return SchoolEstablishmentModel._instance;
  }

  getEmailDomainTitle(idx: number){
    return `Domaine ${idx}`;
  }

  async getSchoolEstablishments(): Promise<SchoolEstablishmentResource[]> {
    let schoolEstablishments: SchoolEstablishmentResource[];
    await axios.get<SchoolEstablishmentResource[]>(this.route).then(async response => {
      const { status, data } = response;
      try {
          if (status === Success) {
            schoolEstablishments = data;
          } else if (status === NoContent) {
            throw new Error(MSG_NO_CONTENT_ERROR);
          }
      } catch (ex) {
          this.error = { status, message: ex.message };
          if(!this.skipHandleError){
            await this.handleError(this.error);
          }
        }
    },async error => {
        this.error = error;
        if(!this.skipHandleError){
          await this.handleError(this.error);
        }
      },
    );
    return schoolEstablishments;
  }

  async getSchoolEstablishmentById(id: string): Promise<SchoolEstablishmentResource> {
    let schoolEstablishment: SchoolEstablishmentResource;
    await axios.get<SchoolEstablishmentResource>(`${this.route}/${+id}`).then(async response => {
      const { status, data } = response;
      try {
          if (status === Success) {
            schoolEstablishment = data;
          } else if (status === NoContent) {
            throw new Error(MSG_NO_CONTENT_ERROR);
          }
        } catch (ex) {
          this.error = { status, message: ex.message };
          if(!this.skipHandleError){
            await this.handleError(this.error);
          }
        }
      },
      async error => {
        this.error = error;
        if(!this.skipHandleError){
          await this.handleError(this.error);
        }
      },
    );
    return schoolEstablishment;
  }

  async update(id: number, submitData: SchoolEstablishmentResourceForEdit): Promise<Error> {
    let result: Error;
    await axios.put<string, Error>(`${this.route}/${id}`, submitData).then(async response => {
        const { status } = response;
        if (status === Success) {
          result = response;
          result.message = 'Établissement scolaire modifié.';
        }
      },
      async error => {
        this.error = error;
        await this.handleError(this.error);
      },
    );
    return result;
  }

  async create(submitData: SchoolEstablishmentResourceForCreate): Promise<CreateResponse> {
    let result: CreateResponse;
    await axios.post<SchoolEstablishmentResourceForCreate, CreateResponse>(this.route, submitData).then(async response => {
        const { status } = response;
        if (status === Created) {
          result = response;
          result.message = 'Établissement scolaire créé.';
        } 
      },
      async error => {
        this.error = error;
        await this.handleError(this.error);
      },
    );
    return result;
  }

  async delete(id: number, allowedRedirect: boolean = false): Promise<boolean> {
    let success: boolean = false;
    await axios.delete<any, Error>(`${this.route}/${id}`).then(response => {
      const { status } = response;
				if (status === Success) {
					printMessage({
						status: status, 
						message: 'Établissement scolaire supprimé.'
					});
					if(allowedRedirect)
          	this.redirect(this.Path.Home);
					success = true;
				}
      },
      async error => {
        this.error = error;
        await this.handleError(this.error);
      },
    );
    return success;
  }

  validateEmailDomain(values:SchoolEstablishmentResource){
    return (values.validateEmailDomain && values.emailDomains.length === 0) ? false : true;
  } 
     
}
