import {
  Book as BookIcon,
  Folder as FolderIcon,
  User as UserIcon,
  Users as UsersIcon,
  Award as AwardIcon,
  Home as HomeIcon,
  Database as DatabaseIcon,
  Archive as ArchiveIcon,
  FileText as FileTextIcon,
  Box as BoxIcon,
  Type as TypeIcon,
  Eye as EyeIcon,
  Mail as MessageIcon,
  Gift as GiftIcon,
  BookOpen as BookOpenIcon,
  Bookmark as BookmarkIcon,
  Filter as FilterIcon,
  Codesandbox as CodesandboxIcon,
  Layers as SymphonyIcon,
  Mail as EmailTemplateIcon,
  CreditCard as CardIcon,
  Columns as KantechIcon,
  Key as ApiKeyIcon,
  FilePlus as DocumentIcon,
  File as PublicationIcon,
  Settings as SettingsIcon,
  Briefcase as UxpertisesIcon,
  Edit as EditIcon,
  Cloud as CoveoIcon,
  Clipboard as SourceIcon,
  Archive as HistoryIcon,
  Inbox as LogsIcon,
  Compass as CompassIcon,
  Paperclip as CodesNotAssignedIcon,
  Briefcase as CollectionIcon,
  BookOpen as SchoolEstablishmentIcon
} from 'react-feather';

import { 
  AppModel,
  ApiKeyModel, 
  CardRequestPendingModel,
  CommunauteJuridiqueModel,
  ContentMostViewedModel, 
  ContentPublicationsModel,  
  CustomerModel, 
  DatabankModel, 
  DocCollectionModel, 
  DocumentModel, 
  DoorAccessProfileModel, 
  EmailTemplateModel, 
  EmployeeModel, 
  LawDomainFieldModel, 
  LawModel, 
  LawPracticeFieldModel, 
  LibraryModel, 
  LibraryServiceModel, 
  LibraryTypeModel, 
  MessagePlaceholderModel,
  ProductModel, 
  ReperageModel, 
  SubscriptionModel, 
  SymphonyModel, 
  TranslationModel, 
  TribunalModel, 
  TribunalTypeModel,
  JugesAClasserModel,
  PendingApprovalModel,
  UxpertiseModel,
  SearchEngineModel,
  DocumentTypeModel,
  TribunalCodesNotAssignedModel,
  PartnersModel,
  SchoolEstablishmentModel,
  SpecialFilesModel,
  DocCollectionsModel,
  ResourceModel
} from 'src/common';
import { Section } from 'src/common/types';

export const sections: Section[] = [
  {
    items: [
      {
        ...AppModel.Section,
        icon: HomeIcon,
      }
    ],
  },
  {
    subheader: 'GESTION',
    items: [
      {
        title: 'Abonnements',
        icon: AwardIcon,
        items: [
          {
            ...SubscriptionModel.getInstance().Section,
            icon: AwardIcon
          },
          {
             ...SchoolEstablishmentModel.getInstance().Section,
             icon: SchoolEstablishmentIcon
          }
        ] 
      },
      {
        ...DatabankModel.getInstance().Section,
        icon: DatabaseIcon,
      },
      {
        title: 'Bibliothèques',
        icon: ArchiveIcon,
        items: [
          {
            ...LibraryModel.getInstance().Section,
            icon: ArchiveIcon,
          },
          {
            ...LibraryServiceModel.getInstance().Section,
            icon: BoxIcon
          },
          {
            ...LibraryTypeModel.getInstance().Section,
            icon: TypeIcon
          }
        ],
      },
      {
        ...CardRequestPendingModel.getInstance().Section2
      },
      {
        title: 'Contenus',
        icon: FileTextIcon,
        items: [
          {
            ...DocCollectionModel.getInstance().Section,
            icon: FolderIcon
          },
          {
            ...DocCollectionsModel.getInstance().Section,
            icon: CollectionIcon
          },
          {
            ...CommunauteJuridiqueModel.getInstance().Section,
            icon: EditIcon,
          },
          {
            ...DocumentModel.getInstance().Section,
            icon: DocumentIcon
          },
          {
            ...SpecialFilesModel.getInstance().Section,
            icon: DocumentIcon
          },
          {
            ...LawModel.getInstance().Section,
            icon: BookIcon
          },
          {
            ...PartnersModel.getInstance().Section,
            icon: UsersIcon,
          },
          {
            ...ContentPublicationsModel.getInstance().Section,
            icon: PublicationIcon
          },
          {
            ...ContentMostViewedModel.getInstance().Section,
            icon: EyeIcon
          },
          {
            ...DocumentTypeModel.getInstance().Section,
            icon: TypeIcon,
          }
        ],
      },
      {
        ...LawDomainFieldModel.getInstance().Section,
        icon: BookmarkIcon
      },
      {
        ...EmailTemplateModel.getInstance().Section,
        icon: EmailTemplateIcon
      },
      {
        title: 'Membres',
        icon: UsersIcon,
        items: [
          {
            ...LawPracticeFieldModel.getInstance().Section,
            icon: BookOpenIcon
          },
          {
            ...CardRequestPendingModel.getInstance().Section,
            icon: CardIcon
          },
          {
            ...PendingApprovalModel.getInstance().Section,
            icon: UsersIcon
          },
          {
            ...JugesAClasserModel.getInstance().Section,
            icon: UsersIcon
          },
          {
            ...CustomerModel.getInstance().Section,
            icon: UsersIcon
          },
          /*{
            title: UPLOAD_CUSTOMER,
            icon: UploadIcon,
            href: pathConfig.uploadCustomer,
          }*/
        ]
      },
      {
        ...MessagePlaceholderModel.getInstance().Section,
        icon: MessageIcon
      },
      {
        ...ProductModel.getInstance().Section,
        icon: GiftIcon
      },
      {
        ...ReperageModel.getInstance().Section,
        icon: CompassIcon
      },
      {
        ...TranslationModel.getInstance().Section,
        icon: FilterIcon
      },
      {
        title: 'Tribunaux',
        icon: CodesandboxIcon,
        items: [
          {
            ...TribunalCodesNotAssignedModel.getInstance().Section,
            icon: CodesNotAssignedIcon
          },
          {
            ...TribunalModel.getInstance().Section,
            icon: CodesandboxIcon
          },
          {
            ...TribunalTypeModel.getInstance().Section,
            icon: TypeIcon
          }
        ],
      },
    ]
  },
  {
    subheader: 'PRÉFÉRENCES SYSTÈME',
    items: [
      {
        ...ApiKeyModel.getInstance().Section,
        icon: ApiKeyIcon
      },
      {
        title: 'Coveo',
        icon: CoveoIcon,
        items: [
          {
            ...SearchEngineModel.getInstance().SectionHistory,
            icon: HistoryIcon
          },
          {
            ...SearchEngineModel.getInstance().SectionLogs,
            icon: LogsIcon
          },
          {
            ...SearchEngineModel.getInstance().Section,
            icon: SourceIcon
          },
        ]
      },
      {
        ...DoorAccessProfileModel.getInstance().Section,
        icon: KantechIcon
      },
      {
        ...UxpertiseModel.getInstance().Section,
        icon: UxpertisesIcon
      },
      {
        ...ResourceModel.getInstance().Section
      },
      {
        ...SymphonyModel.getInstance().Section,
        icon: SymphonyIcon
      },
      {
        ...EmployeeModel.getInstance().Section,
        icon: UserIcon
      },
      { title: 'Resources',
        visible: false,
        items: [
          {
            resourceCode: 'RESOURCE_GROUP',
            title: 'Gestion des groupes',
            href: '',
            icon: UsersIcon,
            visible: false
          },
          {
            resourceCode: 'RESOURCE',
            title: 'Ressources',
            icon: SettingsIcon,
            href: 'management/resource',
            visible: false
          }
        ]
      }
    ],
  }
];