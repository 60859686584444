import type { FC } from 'react';
import { AppBar, IconButton, Toolbar, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { CaijTooltip } from 'src/common';

interface CaijAppBarProps {
  title?: string;
  extra?: string;
  handleClose: () => void;
}

const CaijAppBar: FC<CaijAppBarProps> = ({ title, extra, handleClose }) => (
  <AppBar sx={{ position: 'relative', flexDirection: 'row', alignItems: 'center' }}>
    { title ? <Typography sx={{flexGrow: 1, marginLeft: '15px'}} variant="h6" component="div">{title}</Typography> : <div style={{flexGrow: 1}}/> }
    <Toolbar>
      {extra && <Typography variant="h6" component="div" sx={{marginRight: '25px'}}>{extra}</Typography>}
      <CaijTooltip title="Fermer">
        <IconButton
          edge="start"
          color="inherit"
          onClick={handleClose}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>
      </CaijTooltip>
    </Toolbar>
  </AppBar>
);

export default CaijAppBar;
