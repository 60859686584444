import React, { useState, useEffect } from 'react';
import type { FC, ChangeEvent } from 'react';
import PropTypes from 'prop-types';
import { 
  Box, 
  Button, 
  SvgIcon,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from '@mui/material';
import { XSquare as CancelIcon } from 'react-feather';
import { 
  AccessModel,
  CaijButton,
  CaijButtonDelete,
  dialogTitle, 
  formatDateTime, 
  labelConfig, 
  MessageModel,
  _customer
} from 'src/common';
import { EndorsedCustomerResource } from 'src/common/types';
import ModifyCustomerEndorsed from '../customer/customer/Details/ModifyCustomerEndorsed';
import CaijDateTimePicker from '../date/CaijDateTimePicker';

interface CaijDialogProps {
  header?: string;
  body?: string;
  isOpen: boolean;
  endDate?: string;
  startDate?: string;
  resourceCode?: string;
  btnText?: string;
  name?: string;
  disabled?: boolean;
  endorser?: EndorsedCustomerResource;
  allowedReadAccessForDeletion?: boolean;
  onSubmit?: (value: boolean) => void;
  onSubmitPublisherMessage?: (value: string) => void;
  onSubmitCustomerEndorsed?: (value: number) => void;
}

const enum Color { success = 'success', none = 'none'}

export enum BtnOk {
  DELETED = "supprimer",
  RESETPASSWORD = 'Réinitialiser',
  DISABLED = 'Désactiver',
  SENDEMAIL = 'Envoyer',
  APPROVED = 'Approuver',
  PUBLIER = 'Publier',
  ACTIVATE_STATUS = 'activer',
  RESTRICT_STATUS = 'Restreindre',
  DEACTIVATE_STATUS= 'Désactiver le compte',
  DELETED_STATUS = 'Supprimer',
  REACTIVATE_STATUS= 'Lever la restriction',
  PROXY = 'Oui',
  MODIFY_CUSTOMER_ENDORSED = 'Transférer',
  VALIDATE_EMAIL_DOMAIN = 'ValidateEmailDomain'
};

const dialogContent = { 
  backgroundColor: 'background.paper',
  paddingBottom: 0
};

export interface ICaijDialog {
  endDate: string;
  error: boolean;
  helperText: string;
  customerId: number;
};

interface IButton {
  color?:Color;
  btnText?: string; 
  disabled?: boolean; 
  callback?: () => void;
  name?:string;
  style: { marginBottom: string, marginLeft: string, marginRight: string };
};

function getBody(btnText: string, body: string, endorser: EndorsedCustomerResource, setState: React.Dispatch<React.SetStateAction<ICaijDialog>>){
  if(btnText === BtnOk.DELETED || btnText === BtnOk.RESETPASSWORD){
   return (
      <Typography variant="body2" component="span">{body} <span style={{fontWeight:'bold'}}>Cette action est irréversible.</span></Typography>
    )
  }else if(btnText === BtnOk.PROXY){
    return (
      <>
        <span style={{display: 'flex', flexDirection: 'column'}}>
          <Typography variant="body2" component="span">{body}</Typography>
        </span>
        <span>
          <Typography variant="body2" component="span">Si celle-ci n'est pas configurée, les membres ne pourront pas y accéder</Typography>
        </span>
      </>
    )
  }else if(btnText === BtnOk.MODIFY_CUSTOMER_ENDORSED){
    return (
      <ModifyCustomerEndorsed endorser={endorser} setState={setState} />
    )
  }else{
    return ( 
      <Typography variant="body2" component="span">{body}</Typography>
    );
  }
}

function renderButton(btn: IButton){
  const { name, color, disabled, btnText, callback, style } = btn;
  if(color === Color.none){
    return (
      <Box sx={style}>
        <CaijButtonDelete disabled={disabled} onDelete={callback}>
          {`${btnText} ${name || ''}`}
        </CaijButtonDelete>
      </Box>
    )
  }else if(color === Color.success){
    return (
      <Box sx={style}>
        <CaijButton variant="outlined" color="success" disabled={disabled} onHandleClick={callback}>
          {`${btnText} ${name || ''}`}
        </CaijButton>
      </Box>  
    ) 
  }
}

const CaijDialog: FC<CaijDialogProps> = ({ 
  resourceCode, 
  header, 
  body, 
  isOpen, 
  endDate,
  startDate,
  endorser,
  allowedReadAccessForDeletion,
  onSubmitCustomerEndorsed,
  onSubmitPublisherMessage,
  onSubmit,
  ...rest
}) => {
  const [open, setOpen] = useState(false);
  const [state, setState] = useState<ICaijDialog>({
    endDate: formatDateTime(new Date(endDate)),
    error: false,
    helperText: '',
    customerId: null
  });
  const { btnText } = rest;
  const access = new AccessModel(resourceCode);
  const accessDeleted = access.UserAdmin || (access.UserUser && access.Deleted);
  const accessEdition = access.UserAdmin || (access.UserUser && access.Edition);
  const accessGestion = access.UserAdmin || (access.UserUser && access.Gestion);

  useEffect(() => {
    if(isOpen === true) {
      setState((prevState: ICaijDialog) => {
        return {
          ...prevState,
          error: false,
          helperText: ''
        }
      });
      setOpen(true);
    }
  }, [isOpen]);

  const handleClose = () => {
    setOpen(false);
    onSubmit ? onSubmit(false) : '';
    onSubmitPublisherMessage ? onSubmitPublisherMessage('') : '';
    onSubmitCustomerEndorsed ? onSubmitCustomerEndorsed(null) : '';
  };

  const handleDateChange = (endDate: string) => {
    setState((prevState: ICaijDialog) => {
      return {
        ...prevState,
        endDate
      }
    });
  };

  const setHelperText = (text: string, error: boolean) => {
    setState((prevState: ICaijDialog) => {
      return {
        ...prevState,
        helperText: text,
        error
      }
    });
  }

  function setMessageError(error: boolean, helperText: string) : void {
    if(helperText === MessageModel.StartDate){
      setHelperText(`Date de fin doit être plus tard que la date de début « ${helperText} »`, error);
    }else if(helperText === MessageModel.Today){
      setHelperText(`Date de fin doit être plus tard que la date d'ajourd'hui « ${helperText} »`,error);
    }
  }

  const submitDate = () => {
    if(!MessageModel.endDateMessageMustBeGreaterThanStartDateMessage(startDate, state.endDate)){
      MessageModel.StartDate = startDate;
      setMessageError(true,MessageModel.StartDate);
    }else if(!MessageModel.endDateMessageMustBeGreaterThanToday(state.endDate)){
      setMessageError(true,MessageModel.Today);
    }else{
      MessageModel.EndDate = state.endDate 
      onSubmitPublisherMessage(MessageModel.EndDate);
      setOpen(false);
    }
  }

  const handleSubmit = () => {
    onSubmit(true);
    setOpen(false);
  };

  const handleSubmitCustomerEndorsed = () => {
    state.customerId ? onSubmitCustomerEndorsed(state.customerId) : '';
    setOpen(false);
  };

  const renderButtons = () => {
    const btn: IButton = {
      ...rest,
      callback: handleSubmit,
      color: Color.success, 
      style: { marginBottom: '10px', marginLeft: '10px', marginRight: '5px' }
    };
    
    switch(btn.btnText){
      case BtnOk.PUBLIER:
        if(accessEdition){
          return renderButton({...btn,callback: submitDate, name:''});
        }
        break;
      case BtnOk.DELETED:
        if(accessDeleted || allowedReadAccessForDeletion){
          return renderButton({...btn,color: Color.none});
        }
        break;
      case BtnOk.ACTIVATE_STATUS:
      case BtnOk.REACTIVATE_STATUS:
        if(accessEdition || accessGestion){
          return renderButton(btn);
        }
        break;
      case BtnOk.DEACTIVATE_STATUS:
      case BtnOk.RESTRICT_STATUS:
        if(accessEdition || accessGestion){
          return renderButton({...btn,color: Color.none});
        }
        break;
      case BtnOk.DELETED_STATUS:
        if(accessGestion){
          return renderButton({...btn,color: Color.none});
        }
        break;
      case BtnOk.RESETPASSWORD:
        return renderButton(btn);
      case BtnOk.SENDEMAIL:
        return renderButton(btn);
      case BtnOk.PROXY:
        return renderButton({...btn, name: ''});
      case BtnOk.MODIFY_CUSTOMER_ENDORSED:
        if(accessEdition){
          return renderButton({...btn,callback: handleSubmitCustomerEndorsed,disabled: !state.customerId});
        }
        break;
      case BtnOk.DISABLED:
        return renderButton({...btn, color: Color.none});
      case BtnOk.VALIDATE_EMAIL_DOMAIN:
        return (
          <CaijButton variant="outlined" color="success" onHandleClick={btn.callback}>
            Ok
          </CaijButton>
        );
      default:
        if(accessEdition){
          return renderButton(btn);
        }
    }
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={(event: ChangeEvent<any>, reason) => {
          if (reason !== 'backdropClick') {
            handleClose();
          }
        }}
        aria-labelledby='form-dialog-title'
        maxWidth='md'
      >
        <DialogTitle sx={dialogTitle} id='form-dialog-title'>{header?.toUpperCase()}</DialogTitle>
        <DialogContent sx={dialogContent} dividers>
          <Box sx={{ whiteSpace: 'nowrap', marginBottom: '0.7em' }}>
            { getBody(btnText, body, endorser, setState) }
          </Box>
          {
            btnText === BtnOk.PUBLIER && (
              <Box mb={2} mt={3}>
                <CaijDateTimePicker
                  name='endDate'
                  label={labelConfig.endDate}
                  value={String(state.endDate)}
                  helperText={state.helperText}
                  onHandleChange={(l) => handleDateChange(l.toString())}
                />
              </Box>
            )   
          }
        </DialogContent>
        <DialogActions sx={{backgroundColor: 'background.paper', paddingTop: 2,flexDirection:'row', justifyContent:"center"}}>
          { renderButtons() }
          { btnText !== BtnOk.VALIDATE_EMAIL_DOMAIN && (
              <Box>
                <Button
                  type='reset'
                  onClick={(e) => {
                    e.stopPropagation(); 
                    handleClose();
                  }}
                  variant='outlined'
                  startIcon={
                    <SvgIcon fontSize='small'>
                      <CancelIcon />
                    </SvgIcon>
                  }
                  sx={{marginRight: '10px', marginBottom: '10px'}}
                >
                  {btnText === BtnOk.PROXY ? 'Non' : 'Annuler'}
                </Button>
              </Box>
            )
          }
        </DialogActions>
      </Dialog>
    </>
  );
}

CaijDialog.propTypes = {
  header: PropTypes.string,
  body: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  endDate: PropTypes.string,
  name: PropTypes.string,
  startDate:PropTypes.string,
  resourceCode: PropTypes.string,
  onSubmit: PropTypes.func,
  onSubmitPublisherMessage: PropTypes.func,
  onSubmitCustomerEndorsed: PropTypes.func,
  btnText: PropTypes.string
};

CaijDialog.defaultProps = {
  endDate: '',
  btnText: 'supprimer',
  disabled: false
};

export default CaijDialog;
