import React, { useState, Children } from 'react';
import type { FC, ReactNode, ReactPortal } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, IconButton, SvgIcon } from '@mui/material';
import { CaijTableCell } from './CaijTableCell';
import {
  Authorize,
  Mode,
  CustomerRole,
  EmployeeRole,
  btnSubmit,
  useNavigate,
  GroupType,
  CaijTooltip,
  AccessModel
} from 'src/common';
import {
  Edit as EditIcon,
  Unlock as UnlockIcon
} from 'react-feather';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import { DocumentLockResource, SubscriptionGroupResource, User } from 'src/common/types';

interface IRenderDeleteIconProps{
  access: AccessModel;
  handleOpen: (value: boolean) => void;
}

export interface IHandleEditAccessGroup {
  isEdit: boolean, 
  groupId: number, 
  detailsPage?: boolean, 
  isDefault?: boolean
}

interface CaijTableCellActionProps {
  resourceCode: string;
  pageEditUrl?: string;
  coveoHistoryUrl?: string;
  lawDomainFieldChildUrl?: string;
  showSubscription?: boolean;
  showRemoveIcon?: boolean;
  user?: User;
  showActiveEmployee?: boolean;
  documentLocks?: DocumentLockResource;
  group?: SubscriptionGroupResource;
  children?: ReactNode;
  disableEdit?:boolean;
  handleToggle?: () => void;
  handleRemoveLock?: () => void;
  handleEditAccessGroup?: (value: IHandleEditAccessGroup) => void;
}

const CaijTableCellAction : FC<CaijTableCellActionProps> = ({ 
  pageEditUrl,
  coveoHistoryUrl,
  lawDomainFieldChildUrl,
  resourceCode, 
  handleToggle, 
  handleRemoveLock,
  handleEditAccessGroup,
  children,
  disableEdit,
  ...rest
}) => {
  const navigate = useNavigate();
  const [isEditAuth, setIsEditAuth] = useState<boolean>();
  const [isDeleteAuth, setIsDeleteAuth] = useState<boolean>();
  const { user, showSubscription, showActiveEmployee, documentLocks, showRemoveIcon, group } = rest;
  const childrens: ReactPortal[] = [];

  if(Children.count(children) > 0){
    Children.forEach(children, (child: ReactPortal, index: number) => childrens.push(child));
  }

  const renderEditIcon = () => {
    if(!disableEdit){
      if(pageEditUrl){
        return (
          <CaijTooltip title="Éditer" disabled={!isEditAuth}>
            <IconButton disabled={!isEditAuth} onClick={(e) => {
              e.stopPropagation();
              window.open(pageEditUrl, '_self')
            }}>
              <SvgIcon fontSize='small'>
                <EditIcon />
              </SvgIcon>
            </IconButton>
          </CaijTooltip>
        )
      }else if(group){
        return (
          <CaijTooltip title="Éditer" disabled={!isEditAuth}>
            <IconButton disabled={!isEditAuth} onClick={(e) =>{
              e.stopPropagation();
              handleEditAccessGroup({isEdit: true, groupId: group.id})
            }}>
              <SvgIcon fontSize='small'>
                <EditIcon />
              </SvgIcon>
            </IconButton>
          </CaijTooltip>
        )
      }
    }
  }

  const renderDeleteIcon = (child: ReactPortal) => {
    if(group){
      const disabled = !isDeleteAuth || (group.isDefault || (group.groupType == GroupType.Endorsed));
      return (
        <>
          <Authorize
            resourceCode={resourceCode}
            mode={Mode.delete}
            onIsAuth={l => setIsDeleteAuth(l)}
          >
            <CaijTooltip title="Supprimer" disabled={disabled}>
              <IconButton disabled={disabled} onClick={(e) => {
                e.stopPropagation();
                handleToggle(); 
              }}>
                <SvgIcon fontSize='small'>
                  <DeleteIcon />
                </SvgIcon>
              </IconButton>
            </CaijTooltip>
          </Authorize>
          {child}
        </>
      )
    }else if(!showSubscription){
      const disabled = !isDeleteAuth || !showActiveEmployee;
      return (
        <>
          <Authorize
            resourceCode={resourceCode}
            mode={Mode.delete}
            onIsAuth={l => setIsDeleteAuth(l)}
          >
            <CaijTooltip title="Supprimer" disabled={disabled}>
              <IconButton disabled={disabled} onClick={(e) => {
                e.stopPropagation();
                handleToggle(); 
              }}>
                <SvgIcon fontSize='small'>
                  <DeleteIcon />
                </SvgIcon>
              </IconButton>
            </CaijTooltip>
          </Authorize>
          {child}
        </>
      )
    }else if(showSubscription && user.employee.role.toLowerCase() === CustomerRole.Admin.toLowerCase()){
      return (
        <>
          <CaijTooltip title="Supprimer">
            <IconButton onClick={(e) => {
              e.stopPropagation();
              handleToggle();
            }}>
              <SvgIcon fontSize='small'>
                <DeleteIcon />
              </SvgIcon>
            </IconButton>
          </CaijTooltip>
          {child}
        </>
      )
    }
  }

  return (
    <>
     <CaijTableCell>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'right',
            alignItems: 'center'
          }}
        > 
          { documentLocks && user.employee.role === EmployeeRole.Admin && (
              <Button
                variant="contained"
                onClick={(e) => {
                  e.stopPropagation();
                  handleRemoveLock()
                }}
                startIcon={
                  <SvgIcon fontSize='small'>
                    <UnlockIcon />
                  </SvgIcon>
                }
                sx={btnSubmit}
              >
                Déverrouiller
              </Button>
            )
          }
          { lawDomainFieldChildUrl && (
              <Button
                sx={{minWidth: '150px'}}
                onClick={(e) => { e.stopPropagation(); navigate(lawDomainFieldChildUrl)}}
              >
                Voir liste des fils
              </Button>
            )
          }
          <Authorize
            resourceCode={resourceCode}
            mode={Mode.edit}
            onIsAuth={l => setIsEditAuth(l)}
          >
            { renderEditIcon() }
          </Authorize>
          { coveoHistoryUrl && (
              <Button
                sx={{minWidth: '140px'}}
                onClick={() => navigate(coveoHistoryUrl)}
              >
                Voir l'historique
              </Button>
            )
          }
          { childrens[1]?.props['id'] === 'coveoSource' ? childrens[1] : '' }
          { showRemoveIcon && renderDeleteIcon(childrens[0]) }
        </Box>
      </CaijTableCell>
    </>
  );
};

CaijTableCellAction.defaultProps = {
  showActiveEmployee: true,
  showRemoveIcon: true
}

CaijTableCellAction.propTypes = {
  resourceCode: PropTypes.string.isRequired
};

export default CaijTableCellAction;

export function RenderDeleteIcon({access, handleOpen} : IRenderDeleteIconProps){
  return (
    <CaijTooltip title="Supprimer" disabled={!access.Deleted} marginTop="10px">
      <IconButton disabled={!access.Deleted} onClick={() => handleOpen(true)}>
        <SvgIcon fontSize='small'>
          <DeleteIcon />
        </SvgIcon>
      </IconButton>
    </CaijTooltip>
  )
}
