import React, { useState } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import { TableBody } from '@mui/material';
import {
  SchoolEstablishmentModel,
  EnhancedTableHead,
  getEmptyListSearch
} from 'src/common';
import type { SchoolEstablishmentResource } from 'src/common/types';
import CaijDialogs from 'src/components/dialog';
import CaijTableCellAction from 'src/components/table/CaijTableCellAction';
import MyCard from 'src/components/card/MyCard';
import CaijTable from 'src/components/table/CaijTable';
import { CaijTableCell, CaijTableCellConfirm } from 'src/components/table/CaijTableCell';
import { CaijTableRowClickable } from 'src/components/table/CaijTableRowClickable';

export interface ResultsProps {
  model: SchoolEstablishmentModel;
  isEmptyList: boolean;
  deleteSchoolEstablishment: (value: number) => Promise<void>;
  schoolEstablishments: SchoolEstablishmentResource[];
}

const Results: FC<ResultsProps> = ({ model, isEmptyList, deleteSchoolEstablishment, schoolEstablishments }) => {
  const path = model.Path;
  const [selectedRow, setSelectedRow] = useState([]);
  const headCells = model.getHeadCells();
  return (
    <MyCard>
      <CaijTable>
        <EnhancedTableHead headCells={headCells} />
        <TableBody>
          { isEmptyList ? getEmptyListSearch(headCells.length) : schoolEstablishments.map((row:SchoolEstablishmentResource, index) => (
               <CaijTableRowClickable key={index} path={path.getDetail(row.id)}>
                  <CaijTableCell>{row.name}</CaijTableCell>
                  <CaijTableCellConfirm confirm={row.notarialLawMasterOffer} cellAlign="center" />
                  <CaijTableCellConfirm confirm={row.validateEmailDomain} cellAlign="center" />
                  <CaijTableCellAction 
                    pageEditUrl={path.getEdit(row.id)}
                    resourceCode={model.ResourceCode}
                    handleToggle={() => SchoolEstablishmentModel.handleToggle(row.id, selectedRow, setSelectedRow)}
                  >
                    <CaijDialogs
                      dialog={model.Dialog}
                      isOpen={selectedRow.indexOf(row.id) !== -1}
                      onSubmit={async () => {
                        await deleteSchoolEstablishment(row.id);
                        setSelectedRow([]);
                      }}
                      setSelectedRow={setSelectedRow}
                    />
                  </CaijTableCellAction>
                </CaijTableRowClickable>
            ))
          }
          </TableBody>
      </CaijTable>
    </MyCard>
  );
};

Results.propTypes = {
  schoolEstablishments: PropTypes.array
};

export default Results;
