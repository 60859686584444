import {
  Success,
  NoContent,
  MSG_NO_CONTENT_ERROR,
  Created
} from 'src/common';
import type {
  Error,
  CreateResponse,
  LawResource,
  LawResourceForCreate,
  LawResourceForEdit,
  LawHistoryResource
} from 'src/common/types';
import AppModel from '../App';
import axios from 'src/utils/axios';
import { FormModel } from '../Form';
import { BtnOk } from 'src/components/dialog/CaijDialog';

type TypeResourceEdit = LawResourceForCreate | LawResourceForEdit;

export default class LawModel extends AppModel
{
  private static _instance: LawModel;
  readonly Code = new FormModel('code','Code',45);
  readonly Jurisdiction = new FormModel('jurisdiction','Juridiction',45);
  readonly Type = new FormModel('type','Type',45);
  readonly TitleFr = new FormModel('titleFr','Titre (Français)',500);
  readonly TitleEn = new FormModel('titleEn','Titre (English)',500);
  readonly Abreviation = new FormModel('abreviation','Abréviation',200);
  readonly LawReference = new FormModel('lawReference','Référence législative (Française)', 500);
  readonly LawReferenceEn = new FormModel('lawReferenceEn','Référence législative (Anglais)', 500);
  readonly FilterCaseLaw = new FormModel('filterCaseLaw','Jurisprudence',45);
  readonly IndexingKeyword = new FormModel('indexingKeywordInput',"Mot-clé d'indexation de la loi",200);
  readonly Published = new FormModel('published','Publier');
  readonly Annotated = new FormModel('annotated','Annotations');
  readonly QueryCaseLaw = new FormModel('queryCaseLaw','Requête pour la relance en jurisprudence');
  readonly QueryDoctrine = new FormModel('queryDoctrine','Requête pour la relance en doctrine');
  
  constructor(){
    super('/content/law');
    this.initialize();
  }

  private initialize(){
    this._resourceCode = 'LAW';
    this._headerTitle = 'Liste des lois annotées';
    this._btnAddText = 'Ajouter une loi annotée';
    this.Path.PathName = '/contents/annotated-laws';

     //Dialog
     this.Dialog.Header = 'Supprimer la loi annotée';
     this.Dialog.BtnText = BtnOk.DELETED;
     this.Dialog.Name = 'la loi annotée';
  }

  get Section(){
    return {
      resourceCode: this._resourceCode, 
      title: 'Lois annotées',
      href: this.Path.Home,
      visible: true
    }
  }

  get LawDomain(){
    return new FormModel("lawDomain","Domaine de droit");
  }

  getHeadCells(){ 
    return [
      {
        id: this.Code.Name, width: '10%', numeric: false, disablePadding: false, label: this.Code.Label
      },
      {
        id: this.TitleFr.Name, width: '55%', numeric: false, disablePadding: false, label: this.TitleFr.Label
      },
      {
        id: this.Type.Name, width: '15%', numeric: false, disablePadding: false, label: this.Type.Label
      },
      {
        id: 'version', width: '15%', numeric: false, disablePadding: false, label: 'Dernière version'
      },
      {
        id: '', width: '5%'
      }
    ];
  };

  static getInstance(): LawModel {
    if (!LawModel._instance) {
      LawModel._instance = new LawModel();
    }
    return LawModel._instance;
  }

  async getLaws(): Promise<LawResource[]> {
    let laws: LawResource[];
    await axios.get<LawResource[]>(this.route).then(async (response) => {
      const { status, data } = response;
      try {
        if (status === Success) {
          laws = data;
        } else if (status === NoContent) {
          throw new Error(MSG_NO_CONTENT_ERROR);
        }
      } catch (ex) {
        this.error = { status, message: ex.message };
        await this.handleError(this.error);
      }
    },
    async (error) => {
      this.error = error;
      if(!this.skipHandleError){
        await this.handleError(this.error);
      }
    });
    return laws;
  }

  async getLawHistory(id: number): Promise<LawHistoryResource[]> {
    let histories: LawHistoryResource[];
    await axios.get<LawHistoryResource[]>(`${this.route}/history`, { params: { id: id.toString() } }).then(async (response) => {
      const { status, data } = response;
      try {
        if (status === Success) {
          histories = data;
        } else if (status === NoContent) {
          throw new Error(MSG_NO_CONTENT_ERROR);
        }
      } catch (ex) {
        this.error = { status, message: ex.message };
        await this.handleError(this.error);
      }
    },
    async (error) => {
      this.error = error;
      if(!this.skipHandleError){
        await this.handleError(this.error);
      }
    });
    return histories;
  }

  async getLawHistoryById(id: number): Promise<LawHistoryResource> {
    let history: LawHistoryResource;
    await axios.get<LawHistoryResource>(`${this.route}/history/${id}`).then(async (response) => {
      const { status, data } = response;
      try {
        if (status === Success) {
          history = data;
        } else if (status === NoContent) {
          throw new Error(MSG_NO_CONTENT_ERROR);
        }
      } catch (ex) {
        this.error = { status, message: ex.message };
        await this.handleError(this.error);
      }
    },
    async (error) => {
      this.error = error;
      if(!this.skipHandleError){
        await this.handleError(this.error);
      }
    });
    return history;
  }

  async getLawById(id: string): Promise<LawResource> {
    let law: LawResource;
    await axios.get<LawResource>(`${this.route}/${+id}`).then(async (response) => {
      const { status, data } = response;
      try {
        if (status === Success) {
          law = data;
        } else if (status === NoContent) {
          throw new Error(MSG_NO_CONTENT_ERROR);
        }
      } catch (ex) {
        this.error = { status, message: ex.message };
        await this.handleError(this.error);
      }
    },
    async (error) => {
      this.error = error;
      await this.handleError(this.error);
    });
    return law;
  }

  async updateLaw(submitData: TypeResourceEdit): Promise<Error> {
    let result: Error;
    await axios.put<LawResourceForEdit,Error>(`${this.route}/${submitData.id}`,submitData).then(async (response) => {
      const { status } = response;
      try {
        if (status === Success) {
          result = response;
          result.message = 'Loi modifiée.';
        } else if (status === NoContent) {
          throw new Error(MSG_NO_CONTENT_ERROR);
        }
      } catch (ex) {
        this.error = { status, message: ex.message };
        await this.handleError(this.error);
      }
    }, async (error) => {
      this.error = error;
      await this.handleError(this.error);
    });
    return result;
  }

  async insertLaw(submitData: TypeResourceEdit): Promise<CreateResponse> {
    let result: CreateResponse;
    await axios.post<LawResourceForCreate, CreateResponse>(this.route,submitData).then((response) => {
      const { status } = response;
      if (status === Created) {
        result = response;
        result.message = 'Loi créée.'
      }
    }, async (error) => {
      this.error = error;
      await this.handleError(this.error);
    });
    return result;
  }

  async deleteLaw(id: number): Promise<Error> {
    let result: Error;
    await axios.delete<any, Error>(`${this.route}/${id}`).then(response => {
        const { status } = response;
        if (status === Success) {
          result = response;
          result.message = 'Loi annotée supprimée.';
        }
      },
      async error => {
        this.error = error;
        await this.handleError(this.error);
      },
    );
    return result;
  }
}
