import React, { useState } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import { CardContent, Grid } from '@mui/material';
import type { 
  Error, 
  CreateResponse, 
  SchoolEstablishmentResource,
  SchoolEstablishmentResourceForEdit,
  SchoolEstablishmentResourceForCreate
} from 'src/common/types';
import {
  Formik,
  Authorize,
  CaijButtonSubmit,
  CaijButtonReset,
  Mode,
  btnSubmit,
  SchoolEstablishmentModel
} from 'src/common';
import MyCard from 'src/components/card/MyCard';
import SchoolEstablishmentForm from 'src/components/schoolEstablishment';
import validateSchema from '../Schema';
import SchoolEstablishmentDialog from 'src/components/dialog/schoolEstablishment';

export interface SchoolEstablishmentEditFormProps {
  model: SchoolEstablishmentModel;
  schoolEstablishment: SchoolEstablishmentResource;
  onSubmit?: (values: SchoolEstablishmentResource) => void;
}

async function redirect(model: SchoolEstablishmentModel, response: Error | CreateResponse, id: number) : Promise<void>{
  await model.redirect(model.Path.Home,{
    status: response.status,
    message: response.message
  });
};

const SchoolEstablishmentEditForm: FC<SchoolEstablishmentEditFormProps> = ({
  model,
  schoolEstablishment,
  onSubmit
}) => {
  const [isAuth, setIsAuth] = useState<boolean>();
  const [open, setOpen] = useState<boolean>(false);
  
  const handleSubmit = async (values: SchoolEstablishmentResource) => {
    if(onSubmit){
      onSubmit(values);
      return;
    }
    const model = new SchoolEstablishmentModel();
    if(!model.validateEmailDomain(values)){
      setOpen(true);
      return;
    }
    let submitData = values as SchoolEstablishmentResourceForEdit;
    if (submitData && submitData.id) {
        const response = await model.update(submitData.id, submitData);
        if (!model.error) {
          await redirect(model, response, submitData.id);
        }
    } else {
      submitData = values as SchoolEstablishmentResourceForCreate;
      delete submitData.id;
      const response = await model.create(submitData);
      if (!model.error) {
        await redirect(model, response, response.data.id);
      }
    }
  };

  return (
    <Formik
      initialValues={{...schoolEstablishment }}
      validationSchema={validateSchema(model)}
      onSubmit={handleSubmit}
    >
      {({ values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue, isSubmitting }) => (
        <form onSubmit={handleSubmit}>
          <MyCard>
            <CardContent>
              <Grid container spacing={2}>
                <SchoolEstablishmentForm 
                  model={model} 
                  schoolEstablishment={values}
                  errors={errors}
                  touched={touched}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  SetFieldValue={setFieldValue}
                />
              </Grid>
              <Authorize
                resourceCode={model.ResourceCode}
                mode={schoolEstablishment.id ? Mode.edit : Mode.add}
                onIsAuth={(l) => setIsAuth(l)}
              >
                <CaijButtonSubmit disabled={!isAuth || isSubmitting} sx={btnSubmit} />
              </Authorize>
              <CaijButtonReset disabled={isSubmitting} pathName={model.Path.Home} />
              <SchoolEstablishmentDialog model={model} open={open} submit={() => setOpen(false)} />
            </CardContent>
          </MyCard>
        </form>
      )}
    </Formik>
  );
};

SchoolEstablishmentEditForm.propTypes = {
  schoolEstablishment: PropTypes.object.isRequired,
  onSubmit: PropTypes.func
};

SchoolEstablishmentEditForm.defaultProps = {
  onSubmit: null
};

export default SchoolEstablishmentEditForm;
