import React, { useEffect, useReducer } from 'react';
import type { FC } from 'react';
import { Box, Container } from '@mui/material';
import {
  useLocation,
  AccessModel,
  root,
  fetchSchoolEstablishments,
  _schoolEstablishment,
  deleteSchoolEstablishment
} from 'src/common';
import Page from 'src/components/Page';
import Results from './Results';
import LoadingScreen from 'src/components/loading/LoadingScreen';
import GenericHeader from 'src/components/header/GenericHeader';
import SchoolEstablishmentModel from 'src/model/subscription/SchoolEstablishment';
import { BtnOk } from 'src/components/dialog/CaijDialog';

const initialState = {
  schoolEstablishments: [],
  isLoading: true,
  isEmptyList: true,
  isAuthorize: false
};

const SchoolEstablishmentListView: FC = () => {
  const model = new SchoolEstablishmentModel();
  const access = new AccessModel(model.ResourceCode);
  const location = useLocation();
  const [state, dispatch] = useReducer(_schoolEstablishment, initialState);
  
  const getSchoolEstablishments = async () : Promise<void> => {
    if(access.canRead()){
      model.PathName = location.pathname;
      const schoolEstablishments = await model.getSchoolEstablishments();
      if (!model.error) {
        dispatch(fetchSchoolEstablishments(schoolEstablishments, false,!Array.isArray(schoolEstablishments) || schoolEstablishments.length === 0, true));
      }
    }
  }

  useEffect(() => {
    (async () => await getSchoolEstablishments())();
  }, []);

  const handleDeleteSchoolEstablishment: (id: number) => Promise<void> = async (id) => {
    if(id && access.canDelete()){
      if(await model.delete(id))
        dispatch(deleteSchoolEstablishment(id));
    }
  };

  const { schoolEstablishments, isEmptyList, isLoading } = state;

  if (isLoading) {
    return <LoadingScreen />;
  }

  model.Dialog.Header = "Supprimer l'établissement scolaire";
  model.Dialog.BtnText = BtnOk.DELETED;
  model.Dialog.Name = "l'établissement scolaire";

  return (
    <Page sx={root} title={model.PageTitle}>
      <Container maxWidth={false}>
        <GenericHeader model={model} actions={['add']} headerTitle={model.HeaderTitle} />
        <Box mt={3}>
          <Results
            schoolEstablishments={schoolEstablishments}
            isEmptyList={isEmptyList}
            deleteSchoolEstablishment={handleDeleteSchoolEstablishment}
            model={model}
          />
          </Box>
      </Container>
    </Page>
  );
};

export default SchoolEstablishmentListView;
