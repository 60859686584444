import React, { useCallback, useState, useEffect, useReducer } from 'react';
import type { FC, ChangeEvent } from 'react';
import type { Theme } from 'src/common/types';
import {
  Box,
  Container,
  Divider,
  Tab,
  Tabs
} from '@mui/material';
import {
  useParams,
  useLocation,
  fetchDetailsContentPublication,
  _contentPublication,
  AccessModel,
  useNavigate,
  setHash,
  root,
  setEditHash
} from 'src/common';
import Page from 'src/components/Page';
import ContentPublicationsModel from 'src/model/content/ContentPublications';
import DetailsEditForm from './Forms/DetailsEditForm';
import Details from './Details';
import LoadingScreen from 'src/components/loading/LoadingScreen';
import { ContentPublicationProvider } from 'src/contexts/content/ContentPublicationContext';
import GenericHeader from 'src/components/header/GenericHeader';
import parse from 'html-react-parser';

const box = {
  color: (theme: Theme) => theme.palette.text.primary,
  backgroundColor: (theme: Theme) => theme.palette.background.paper,
  padding: '1em',
};

const container = {
  backgroundColor: (theme: Theme) => theme.palette.background.paper,
};

interface Tabs {
  value: string, label: string
}

const ContentPublicationsDetailsView: FC = () => {
  const model = ContentPublicationsModel.getInstance();
  const access = new AccessModel(model.ResourceCode);
  const location = useLocation();
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(_contentPublication, { contentPublication: {} });
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [editMode, setEditMode] = useState({
    details: false,
    subscription: false,
  });
  const [currentTab, setCurrentTab] = useState<string>('details');
  const { id } = useParams();

  const tabs : Tabs[] = [
    { value: 'details', label: 'Détails' }
  ];

  const handleBrowserRefresh = () => {
    const hash = location.hash;
    if(hash){
      const removedHashSymbol = hash.substring(1, hash.length);
      const hashVal = removedHashSymbol.split('-');
      const tabVal = hashVal.length === 1 ? hashVal[0] : hashVal[1];
      const index = tabs.findIndex((tab: Tabs) => tab.value === tabVal);
      if(index !== -1){
        setCurrentTab(tabVal);
        if(removedHashSymbol.indexOf('edit') !== -1){
          setIsEditing(true);
          setEditMode({...editMode, [tabVal]:true})
        }
      }else{
        setHash(tabs[0].value, navigate);
      }
    }else{
      setHash(tabs[0].value, navigate);
    }
  }

  const getContentPublicationsById = useCallback(async () : Promise<void> => {
    if (access.canRead()) {
      const model = new ContentPublicationsModel();
      model.PathName = location.pathname;
      const contentPublication = await model.getContentPublicationById(+id);
      if (!model.error) {
        dispatch(fetchDetailsContentPublication(contentPublication));
      }
    }
  }, [id, location]);

  useEffect(() => {
    (async () => {
      handleBrowserRefresh();
      await getContentPublicationsById();
    })();
  }, []);

  const setEditModeContentPublication = (isEdit: boolean) => {
    setEditMode({
      ...editMode,
      [currentTab]: isEdit
    });
    setIsEditing(isEdit);
  };

  const switchMode = (isEdit: boolean, value: string) => {
    setHash(value, navigate);
    setEditModeContentPublication(isEdit);
  };

  const handleTabsChange = (event: ChangeEvent<any>, value: string): void => {
    setHash(value, navigate);
    setEditModeContentPublication(false);
    setCurrentTab(value);
  };

  const handleRefreshContentPublication = async (value: string) => {
    setHash(value, navigate);
    await getContentPublicationsById();
    setEditModeContentPublication(false);
  };

  const editContentPublication = () => {
    setEditHash('edit', navigate);
    setEditModeContentPublication(true);
  };
  
  const { contentPublication } = state;

  if (Object.keys(contentPublication).length === 0) {
    return <LoadingScreen />;
  }
  
  model.Title = `${String(parse(contentPublication.title))} - ${contentPublication.code}`;

  const renderContent = () => {
    switch (currentTab) {
      case 'details':
        if (editMode.details) {
          return (
            <ContentPublicationProvider>
                <DetailsEditForm
                  model={model}
                  contentPublication={contentPublication}
                  onHandleRefreshContentPublication={() => handleRefreshContentPublication('details')}
                  onSwitchMode={(l) => switchMode(l, 'details')}
                />
            </ContentPublicationProvider>
          );
        }
        return (
          <Details model={model} contentPublication={contentPublication} />
        );
      default:
        return '';
    }
  };

  model.Title = contentPublication.title ? parse(contentPublication.title) as string : '';

  return (
    <Page
      sx={root}
      title={model.DetailPageTitle}
    >
      <Container maxWidth={false}>
        <GenericHeader 
          id={id} 
          model={model} 
          headerTitle={model.Title} 
          actions={['editDetail','delete']}
          editMode={editMode.details}
          titleBadge={{label: contentPublication.code}}
          onEditDetail={() => editContentPublication()}
        />
        <Box mt={4} sx={container}>
          <Tabs
            onChange={handleTabsChange}
            scrollButtons="auto"
            value={currentTab}
            variant="scrollable"
            textColor="secondary"
          >
            {tabs.map((tab) => (
              <Tab
                key={tab.value}
                label={tab.label}
                value={tab.value}
              />
            ))}
          </Tabs>
        </Box>
        <Divider />
        <Box sx={box}>
          { renderContent() }
        </Box>
      </Container>
    </Page>
  );
};

export default ContentPublicationsDetailsView;
