import type { Error, Filter, SearchFilter } from 'src/common/types';
import { 
  Success, 
  Created, 
  Unauthorized, 
  Forbidden,  
  formatError, 
  errorConfig, 
  ErrorType, 
  formatDate,
  formatDateTime,
  ServiceUnavailable,
  CustomerModel,
  SubscriptionModel,
  ProductModel,
  NotFound,
  BadRequest,
  Conflit,
  InternalServerError,
  DialogModel,
  FIRST_PAGE,
  SearchEngineModel
} from 'src/common';
import LocalStorageModel from './LocalStorage';
import printMessage from 'src/views/errors/MessageError';
import { NavigateFunction } from 'react-router';
import PathModel from './Path';
import { FormModel } from './Form';
import dayjs from 'dayjs';

//#region PageModel
export class PageModel
{
  protected _btnAddText: string;
  private _title: string;
  protected _headerTitle: string;
  protected _breadCrumbsEditText: string;
  static readonly HOME = 'Accueil';
  static readonly ADMIN_CAIJ_TITLE: string | undefined = process.env.REACT_APP_ADMIN_CAIJ_TITLE;
  readonly ADMIN_CAIJ = ` - ${PageModel.ADMIN_CAIJ_TITLE}`;
  
  readonly AddressName = new FormModel('name',"Nom de l'adresse", 255);
  readonly AddressLine1 = new FormModel('line1',"Ligne d'adresse 1", 200);
  readonly AddressLine2 = new FormModel('line2',"Ligne d'adresse 2", 200);
  readonly AddressCity = new FormModel('city',"Ville", 200);
  readonly AddressState = new FormModel('state',"Province/État", 45);
  readonly AddressZip = new FormModel('zip',"Code postal", 45);
  readonly AddressCountry = new FormModel('country',"Pays", 75);
 
  constructor(){}

  get BackToHome(){
    return `Retour à l'${PageModel.HOME}`;
  }

  set HeaderTitle(headerTitle: string){
    this._headerTitle = headerTitle;
  }

  get HeaderTitle(){
    return this._headerTitle;
  }

  get PageTitle(){
    return this._headerTitle + this.ADMIN_CAIJ;
  }

  get BtnAddText(){
    return this._btnAddText;
  }

  set Title(value: string){
    this._title = value;
  }

  get Title(){
    return this._title;
  }

  get BreadCrumbsEditText(){
    return this._breadCrumbsEditText;
  }

  get DetailPageTitle(){
    return this.Title + this.ADMIN_CAIJ;
  }

  getEditPageTitle(id: number | string){
    if(!id){
      return this.BtnAddText + this.ADMIN_CAIJ;
    }else{
      return this.Title + this.ADMIN_CAIJ;
    }
  }

  getEditMessagePageTitle(value:boolean){
    if(!value){
      return this.HeaderTitle + this.ADMIN_CAIJ;
    }else{
      return this.BtnAddText + this.ADMIN_CAIJ;
    }
  }
}
//#endregion

//#region BaseModel
export class BaseModel extends PageModel
{
  private _route: string;
  constructor(route: string){
    super();
    if(route){
      this._route = route;
    }
  }
  protected get route(): string {
    if (this._route) {
      return this._route;
    }
  }
}
//#endregion

export default class AppModel extends BaseModel
{
  //private
  private _err: Error;
  private _errStatus: number;
  private readonly PATH_NAME= 'pathName';
  private _skipHandleError: boolean;
  private _queryParam: Map<string,string>;
  private _delaySendEmail = 500;
  private static _instanceApp: AppModel;
  private _filters: Filter = {
    page: 'page',
    itemPerPage: 'itemPerPage',
    query: 'query',
    status: 'status',
    collection: 'collection',
		docId: 'docId',
		docTitle: 'docTitle',
    type: 'type',
    sort: 'sort',
    accessType: 'accessType',
    jurisdiction: 'jurisdiction', 
    tribunalType: 'tribunalType',
    delayAccountCustomer: 'delayAccountCustomer',
		subscription: 'subscription',
    firstName: 'firstName',
    lastName: 'lastName',
		extIdentifier: 'extIdentifier',
		card: 'card',
		email: 'email',
		toggle: 'toggle',
		noCaij: 'noCaij',
    group: 'group',
    coveoSourceId: 'coveoSourceId',
    name: 'name',
    coveoIdentifier: 'coveoIdentifier',
    task: 'task',
    startDate: 'startDate',
    endDate: 'endDate',
    sortedBy: 'sortedBy'
  };
  private _params: any[];

  //Protected
  protected _dialog: DialogModel;
  protected _resourceCode: string;
  protected _path: PathModel;
  protected _localStorage: LocalStorageModel;

  readonly Searchable = new FormModel('searchable','Indexer pour la recherche');
	readonly Visible = new FormModel('visible', "Visible sur l'espace CAIJ");

  constructor(route?:string) {
    super(route)
    this.error = null;
    this._localStorage = new LocalStorageModel();
    this.skipHandleError = false;
    this._queryParam = new Map<string,string>();
    this._dialog = new DialogModel();
    this._path = new PathModel(); 
    this._headerTitle = "Portail d'administration du CAIJ";
    this._params = new Array(Object.keys(this._filters).length);
  }

  static getInstance(): AppModel {
		if (!AppModel._instanceApp) {
			AppModel._instanceApp = new AppModel();
		}
		return AppModel._instanceApp;
	}

  static get Section(){
    return {
      title: PageModel.HOME,
      href: '/app',
      visible: true
    }
  }

  get PathName(): string {
    if (this._localStorage.hasItem(this.PATH_NAME)) {
      return this._localStorage.get(this.PATH_NAME);
    }
    return '';
  }

  set PathName(pathName: string) {
    if (pathName) {
      this._localStorage.add(this.PATH_NAME, pathName);
    }
  }

  get error(): Error {
    return this._err;
  }

  set error(err: Error) {
    this._err = err;
  }

  get errorStatus(): number {
    return this._errStatus;
  }

  set errorStatus(errStatus: number) {
    this._errStatus = errStatus;
  }

  get skipHandleError(): boolean {
    return this._skipHandleError;
  }

  set skipHandleError(value: boolean) {
    this._skipHandleError = value;
  }

  set QueryParam(queryParam: {key: string, value: string}) {
    this._queryParam.set(queryParam.key, queryParam.value);
  }
  
  set Dialog(dialog: DialogModel) {
    this._dialog = dialog;
  }

  get Dialog() {
    this._dialog.ResourceCode = this._resourceCode;
    return this._dialog;
  }

  get ResourceCode(){
    return this._resourceCode;
  }
 
  get Filters(){
    return this._filters;
  }

  get Path(){
    return this._path;
  }
  
  get HeaderBtnAdd() {
    return null;
  }

  get HeaderBtnEdit() {
    return null;
  }

  get HeaderBtnDelete() {
    return null;
  }

  setParams(key: number, value: any){
    this._params[key] = value;
  }

  getParams(){
    return this._params;
  }

  getQueryParam(key: string): string  {
      if(this._queryParam.has(key)){
        return this.decode(this._queryParam.get(key))
      }
      return '';
  }

  static get Today() : string {
    return formatDate(new Date());
  }
  
  static get TodayTime() : string {
    return formatDateTime(new Date());
  }

  encode(value: any){
    return value ? encodeURIComponent(value) : '';
  }

  decode(value: any){
    return value ? decodeURIComponent(value) : '';
  }

  waitFor(callback: any, delay: number) : Promise<void> {
    return new Promise(resolve => setTimeout(() => {
      if(callback)
        resolve(callback());
    }, delay))
  };

  async handle404Error(href: string): Promise<void> {
    let match = /\/wc\//.exec(href);
    if(match){
      await this.redirect(href.slice(0, match.index));
    }else{
      match = /[a-zA-Z0-9]+\/edit$/.exec(href);
      if(match){
        await this.redirect(href.slice(0, match.index));
      }else{
        const index = href.lastIndexOf('/');
        await this.redirect(href.slice(0, index));
      }
    }
  }
  
  async handle503Error(status: number){
    if(status === ServiceUnavailable){
      await this.redirect('/503');
    }
  }

  async handle400Error(error: Error){
    if(error.status === BadRequest){
      printMessage(error);
    }
  }

  async handleError(error: Error): Promise<void> {
    if (error) {
      const { status } = error;
      await this.handle503Error(status);
      if(status === Unauthorized)
        await this.redirect('/login', error);
      else if(status === Forbidden)
        printMessage(error);
      else if(status === NotFound){
        printMessage(error);
        this.handle404Error(window.location.href);
      }else if(status === Conflit || status === InternalServerError){
        printMessage(error);
      }
      this.handle400Error(error);
    }
  }

  async redirect(path: string, error?: Error): Promise<void>{
    if(error) printMessage(error);
    function fn(){
      window.location.href = path || PathModel.Root;
    }
    await this.waitFor(fn, 1000);
  };

  async redirectEmailMessage(status: number, message: string){
    setTimeout(async () => {
      await this.redirect(this.Path.Home,{status,message});
    },this._delaySendEmail);
  }

  async delete(id: string|number, allowedRedirect: boolean = false){
    return null;
  }

  printEmailMessage(status: number, message: string){
    setTimeout(async () => {
      printMessage({status,message});
    },this._delaySendEmail);
  }

  printError(err: Error): void {
    if (err){
      const { status, message, errors } = err;
      if (!(status == Success || status == Created)) {
        if (message) {
          console.error(message);
        } else if (errors){
          Object.keys(errors).forEach(error => {
            errors[error].forEach((value: string) => {
              console.error(value);
            });
          });
        }
      } else if (typeof err === 'string') {
        console.error(err);
      } else {
        console.error(err.message);
      }
    }
  }

  resetError(): void {
    this.error = null;
  }

  static formatError(type: ErrorType, name: string, maxLenght?: number) : string {
    switch(type){
      case ErrorType.extension:
        return formatError(errorConfig.extension, ['&1'], [name]);
      case ErrorType.required:
        return formatError(errorConfig.required, ['&1'], [name]);
      case ErrorType.max:
        return formatError(errorConfig.max, ['&1', '&2'], [name, maxLenght]);
      case ErrorType.invalid:
        return formatError(errorConfig.invalid, ['&1'], [name]);
      case ErrorType.exist:
        return formatError(errorConfig.exist, ['&1'], [name]);
      case ErrorType.numeric: 
        return formatError(errorConfig.numeric, ['&1'], [name]);
      case ErrorType.endDateMustBeLater:
        return formatError(errorConfig.endDateMustBeLater, ['&1'], [name]);
      case ErrorType.mustBeGreaterThanZero: 
        return formatError(errorConfig.mustBeGreaterThanZero, ['&1'], [name]);
      case ErrorType.deletedMessage:
        return formatError(errorConfig.deletedMessage, ['&1'], [name]);
      case ErrorType.mustBeGreaterThanToday:
        return formatError(errorConfig.mustBeGreaterThanToday, ['&1'], [name]);
      default: return '';
    } 
  };  

  static verifyImageUrlLength(val1: string, val2: number) : boolean {
    if(val1 && val1.length > val2){
      return false;
    }
    return true;
  }

  static verifyWord(value:string) : boolean {
    if (value) {
      var patt = /^[a-zA-Z0-9_]+$/;
      return patt.test(value) ? true : false;
    }
    return true;
  }

  static handleToggle = (value: (number | string), selectedRow:any[], setSelectedRow: (value: React.SetStateAction<number[]>) => void) => {
    const currentIndex = selectedRow.indexOf(value);
    const newChecked = [...selectedRow];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setSelectedRow(newChecked);
  };

  static endDateMustBeGreaterThanStartDate(startDate: Date, endDate: Date) : boolean{
    if(endDate){
      return formatDate(endDate) > formatDate(startDate);
    }
    return true;
  }

  static endDateMustBeGreaterThanOrEqualStartDate(startDate: Date, endDate: Date) : boolean{
    if(endDate){
      return formatDate(endDate) >= formatDate(startDate);
    }
    return true;
  }

  static endDateMessageMustBeGreaterThanStartDateMessage(startDate: string, endDate: string) : boolean{
    return dayjs(new Date(endDate)).isAfter(new Date(startDate));
  }

  static endDateMessageMustBeGreaterThanToday(endDate: string) : boolean{
    return dayjs(new Date(endDate)).isAfter(new Date());
  }

//#region Filter

  private verifySort = (filter: Filter, key: string, value: string) : boolean => {
    if(filter[key] === filter.sort){
      return value == 'asc' || value == 'desc';
    }
    return true;
  }

  private async verifySubscriptionFilter(model: SubscriptionModel, key: any, value:string, navigate: NavigateFunction){
    const { Filters } = model;
    if(!Filters[key] || 
      (Filters[key] === Filters.status && isNaN(+value))){
        await this.waitFor(navigate(model.Path.Home),500);
        return;
    }
  }

  private async verifyProductFilter(model: ProductModel, key: any, value: string, navigate: NavigateFunction){
    const { Filters } = model;
    if(!Filters[key] || 
      (Filters[key] === Filters.status && isNaN(+value))){
        await this.waitFor(navigate(model.Path.Home), 500);
        return;
    }
  }

  private async verifyCustomerFilter(model: CustomerModel, key: any, value:string, navigate: NavigateFunction){
    const { Filters } = model;

    const verifySubscription = () : boolean => {
      if(Filters[key] === Filters.subscription){
        return value.split(',').every((val: string) => !isNaN(+val))
      }
      return true;
    }

    const verifyStatus = () : boolean => {
      if(Filters[key] === Filters.status){
        return value.split(',').every((val: string) => CustomerModel.getStatusByKey(val))
      }
      return true;
    }

    const verifyToggle = () : boolean => {
      if(Filters[key] === Filters.toggle){
        return value == 'yes' || value == 'no';
      }
      return true;
    }

    if(!Filters[key] || !verifySubscription() || !verifyStatus() || !this.verifySort(Filters, key, value) || !verifyToggle()){
      await this.waitFor(navigate(model.Path.Home), 500);
      return;
    }
  }

  private async verifySearchEngineFilter(model: SearchEngineModel, key: any, value:string, navigate: NavigateFunction, allowedValidateTaskHistory: boolean = false) {
    const { Filters } = model;
    if(!Filters[key] || !this.verifySort(Filters, key, value)){
      if(allowedValidateTaskHistory){
        await this.waitFor(navigate(model.CoveoTaskHistoryHome), 500);
      }else
        await this.waitFor(navigate(model.Path.Home), 500);
      return;
    } 
  }

  async setQueryParams(queries: string, model?: AppModel, navigate?: NavigateFunction, allowedValidateTaskHistory: boolean = false) : Promise<boolean> {
    const newQueries = queries.substring(1).split('&');
    const { Filters } = model;
    for(const query of newQueries){
      const q = query.split('=');
      const key = q[0];
      const value = q[1];
      if(!Filters[key] || (Filters[key] === Filters.page && isNaN(+value))){
        if(model instanceof SearchEngineModel && allowedValidateTaskHistory){
          await this.waitFor(navigate(model.CoveoTaskHistoryHome), 500);
        }else
          await this.waitFor(navigate(model.Path.Home), 500);
        return;
      }
      if(model instanceof CustomerModel)
        await this.verifyCustomerFilter(model as CustomerModel, key, value, navigate);
      if(model instanceof SubscriptionModel)
        await this.verifySubscriptionFilter(model as SubscriptionModel, key, value, navigate);
      if(model instanceof ProductModel)
        await this.verifyProductFilter(model as ProductModel, key, value, navigate);
      if(model instanceof SearchEngineModel)
        await this.verifySearchEngineFilter(model as SearchEngineModel, key, value, navigate, allowedValidateTaskHistory);
      model.QueryParam = { key, value };
    }
    return true;
  }

  getConfigParameters(filter: SearchFilter, index: number, value: string | number){
    if(filter){
      for (const [key, val] of Object.entries(filter)) {
        if(key !== 'doSearch'){
          const pos = Object.keys(this._filters).findIndex((k) => k === key);
          this.setParams(pos,val);
        }
      }
    }
		this.setParams(index,value);
	};

  resetPageValue(filter: any) {
    filter.page = FIRST_PAGE;
  }

  getUrlEncode(...args: any[]) : string {
    let parameters: string = '';
    const filters = Object.keys(this.Filters);
    args.forEach((arg: any, i: number) => {
      if(i === 2 || i === 5 || i === 6 || i === 14 || i === 15 || i === 16 || i === 17 || i === 18 || i === 20){
        parameters += arg ? `${filters[i]}=${this.encode(arg)}&` : '';
      }else{
        parameters += arg ? `${filters[i]}=${arg}&` : '';
      }
    });
    return `?${parameters?.slice(0,-1)}`;
  }

  getUrlEncodeMembreSubscription(...args: any[]){
	  return `${this.getUrlEncode(...args)}#members`;
	}
//#endregion
}
