import { Button, Dialog, DialogContent, SvgIcon, TableBody, TableCell, TableFooter, TableHead, TableRow, Typography } from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import { btnSubmit, dialog, FIRST_PAGE, formatDateTime, getEmptyListSearch, ITEMS_PER_PAGE, LawModel, tableRow } from "src/common";
import { CheckSquare } from "react-feather";
import { LawHistoryResource } from "src/common/types";
import LoadingScreen from "src/components/loading/LoadingScreen";
import CaijTable from "src/components/table/CaijTable";
import { CaijTableCell } from 'src/components/table/CaijTableCell';
import CaijAppBar from "src/components/AppBar";
import FirstPageRoundedIcon from '@mui/icons-material/FirstPageRounded';
import LastPageRoundedIcon from '@mui/icons-material/LastPageRounded';
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import { CustomTablePagination } from "src/styles/law";

interface IVersionHistoryDialogProps {
    lawId: number;
    open: boolean;
    onClose: () => void;
    handleSelectVersion: (id: number) => void;
}

const VersionHistoryDialog: FC<IVersionHistoryDialogProps> = ({
    lawId,
    open,
    onClose,
    handleSelectVersion
}) => {
    const model = new LawModel();
    const [versions, setVersions] = useState<LawHistoryResource[]>(null);
    const [loadingVersionHistory, setLoadingVersionHistory] = useState<boolean>(true);
    const headCells = [{label: 'Version publiée'},{label: 'Auteur'},{label: ''}];
    const [page, setPage] = React.useState(FIRST_PAGE);
    const [rowsPerPage, setRowsPerPage] = React.useState(ITEMS_PER_PAGE);

    useEffect(() => {
        const fetchVersionHistory = async () => {
            setLoadingVersionHistory(true);
            const result = await model.getLawHistory(lawId);
            if(!model.error){
                setVersions(result);
                setLoadingVersionHistory(false);
            }
        };
        (async () => await fetchVersionHistory())();
    }, []);

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - versions.length) : 0;
    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null,newPage: number) => setPage(newPage);
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <Dialog
            open={open}
            onClose={(e, reason) => {
                if(reason == 'backdropClick' || reason == 'escapeKeyDown') return;
                onClose();
            }}
            maxWidth="md"
            disableEscapeKeyDown
        >
            <CaijAppBar title="Historique des versions publiées de la page d'acceuil" handleClose={onClose} />
            <DialogContent sx={{...dialog}}>
                { loadingVersionHistory ? <LoadingScreen /> : (
                    <CaijTable>
                            <TableHead>
                                <TableRow>{headCells.map(({label}, index: number) =>  <TableCell key={index}>{label}</TableCell>)}</TableRow>
                            </TableHead>
                            <TableBody>
                            {(rowsPerPage > 0
                                ? versions.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                : versions
                            ).map((row, index) => (
                                <TableRow hover tabIndex={-1} key={index} sx={tableRow}>
                                <CaijTableCell>{formatDateTime(row.modified)}</CaijTableCell>
                                <CaijTableCell>
                                    {row.modifiedBy}
                                    </CaijTableCell>
                                    <CaijTableCell>
                                <Button
                                    onClick={() => handleSelectVersion(row.id)}
                                    variant="contained"
                                    startIcon={<SvgIcon fontSize='small'><CheckSquare /></SvgIcon>}
                                    sx={btnSubmit}
                                >Charger</Button>
                                </CaijTableCell>
                                </TableRow>
                            ))}
                            {emptyRows > 0 && (
                                <tr style={{ height: 34 * emptyRows }}>
                                    <td colSpan={3} aria-hidden />
                                </tr>
                            )}
                            { versions?.length === 0 && getEmptyListSearch(headCells.length) }
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <CustomTablePagination
                                        rowsPerPageOptions={[]}
                                        colSpan={3}
                                        count={versions.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        slotProps={{
                                            select: {
                                            'aria-label': 'rows per page',
                                            },
                                            actions: {
                                            showFirstButton: true,
                                            showLastButton: true,
                                            slots: {
                                                firstPageIcon: FirstPageRoundedIcon,
                                                lastPageIcon: LastPageRoundedIcon,
                                                nextPageIcon: ChevronRightRoundedIcon,
                                                backPageIcon: ChevronLeftRoundedIcon,
                                            },
                                            },
                                        }}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    />
                                </TableRow>
                            </TableFooter>
                        </CaijTable>
                    )
                }
            </DialogContent>
        </Dialog>
    )
}

export default VersionHistoryDialog;