import { useEffect, useReducer, useState } from 'react';
import type { FC } from 'react';
import { Box, Container, Divider, Tab, Tabs } from '@mui/material';
import {
  fetchReperages,
  useLocation,
  _reperage,
  ReperageModel,
  useNavigate,
  AccessModel,
  ITEMS_PER_PAGE,
  setHash,
  root,
  Mode
} from 'src/common';
import type { GetReperagesAsyncOptionalParams } from 'src/common/types';
import Page from 'src/components/Page';
import Results from './Results';
import LoadingScreen from 'src/components/loading/LoadingScreen';
import { BtnOk } from 'src/components/dialog/CaijDialog';
import GenericHeader from 'src/components/header/GenericHeader';

const initialState = {
  reperages: [],
  paged: {
    totalCount: 0,
    pageSize: ITEMS_PER_PAGE,
    currentPage: 1,
    totalPages: 0,
  },
  isLoading: false,
  isEmptyList: true,
  isAuthorize: false
};

const ReperageListView: FC = () => {
  const model = ReperageModel.getInstance();
  const access = new AccessModel(model.ResourceCode);
  const location = useLocation();
  const { hash } = location;
  const [currentTab, setCurrentTab] = useState<string>(hash?.substring(1) || "in-progress");
  const [state, dispatch] = useReducer(_reperage, initialState);
  const [deleted, setDeleted] = useState<number>(0);
  const navigate = useNavigate();
  model.Dialog.Header = 'Supprimer le dossier de repérage';
  model.Dialog.BtnText = BtnOk.DELETED;
  model.Dialog.Name = 'le dossier de repérage';

  const getReperages = async (model: ReperageModel, params: GetReperagesAsyncOptionalParams): Promise<void> => {
    model.PathName = location.pathname;
    const results = await model.getReperages(params);
    if (!model.error) {
      dispatch(fetchReperages(results.list,{...results}, true, !Array.isArray(results.list) || results.list.length === 0, true));
    }
  };

  useEffect(() => {
    (async () => {
      if (access.canRead()) {
        const model = new ReperageModel();
        const { search } = location;
        let params: GetReperagesAsyncOptionalParams = {
          searchTerm: '', 
          pageNumber: 1, 
          sortOptions: 'asc', 
          sortedBy: 'folderId',
          pageSize: ITEMS_PER_PAGE, 
          archived: hash === "#archived" 
        };
        if (search) {
          if (await model.setQueryParams(search, model, navigate)) {
            const { Filters: { page, query, sort, sortedBy } } = model;
            params = {
              ...params,
              pageNumber: +model.getQueryParam(page) + 1,
              searchTerm: model.getQueryParam(query),
              sortOptions: model.getQueryParam(sort),
              sortedBy: model.getQueryParam(sortedBy)
            }
          }
        }
        await getReperages.apply(model,[model,params]);
      }
    })();
  }, [location.search, deleted, hash]);

  useEffect(() => {
    setHash(currentTab, navigate);
  }, [currentTab])

  useEffect(() => {
    if(location.search){
      navigate(model.PathName + location.search + location.hash);
    }
  }, [location.hash])

  const deleteReperage: (id: number) => Promise<void> = async (id) => {
    if (id && access.canDelete()) {
      if (await model.deleteReperage(id)) {
        setDeleted(deleted + 1);
      }
    }
  };

  const { reperages, isEmptyList, isLoading, paged } = state;

  if (!isLoading) {
    return <LoadingScreen />
  }
  model.Title = model.HeaderTitle;
  return (
    <Page sx={root} title={model.PageTitle}>
      <Container maxWidth={false}>
        <GenericHeader headerTitle={model.HeaderTitle} mode={Mode.read} model={model} actions={currentTab != 'archived' ? ['add'] : []}/>
        <Box mt={3}>
          <Tabs textColor="secondary" value={currentTab} onChange={(e, value) => setCurrentTab(value)}>
            <Tab
              label="En Cours"
              value="in-progress"
            />
            <Tab
              label="Archivés"
              value="archived"
            />
          </Tabs>
          <Divider />
          <Box mt={3}>
            <Results
              reperages={reperages}
              isEmptyList={isEmptyList}
              paged={paged}
              model={model}
              onDeleteReperage={(id) => deleteReperage(id)}
              tab={hash.substring(1)}
              navigate={navigate}
              location={location}
            />
          </Box>
        </Box>
      </Container>
    </Page>
  );
};

export default ReperageListView;