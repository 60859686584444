import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import type { SchoolEstablishmentResource } from 'src/common/types';
import {
  Box,
  CardContent,
  Table,
  TableBody,
  Typography
} from '@mui/material';
import { 
  SchoolEstablishmentModel,
  EMAIL_DOMAIN,
  EmptyList
} from 'src/common';
import MyCard from 'src/components/card/MyCard';
import { CaijTableRow } from 'src/components/table/CaijTableRow';
import CardHeaderDetail from 'src/components/card/CardHeaderDetail';

export interface EmailDomainInfoProps {
  model: SchoolEstablishmentModel,
  schoolEstablishment: SchoolEstablishmentResource;
}

const accessWidth = {
	width: '130px'
};

const EmailDomainInfo: FC<EmailDomainInfoProps> = ({
  model,
  schoolEstablishment
}) => {
  const { Domain } = model;
  return (
    <MyCard>
      <CardHeaderDetail title={EMAIL_DOMAIN} />
        <CardContent sx={{ pt: 0 }}>
          { schoolEstablishment.emailDomains.length == 0 ? <EmptyList /> : schoolEstablishment.emailDomains && schoolEstablishment.emailDomains.map((row, index) => (
              <Box key={index} sx={{ mt: index != 0 ? 2 : 0, ml: "20px" }}>
                <Typography sx={{ fontWeight: 'bold' }}>{model.getEmailDomainTitle(++index)}</Typography>
                <Table>
                  <TableBody>
                    <CaijTableRow label={Domain.Label} sxTableCell={accessWidth}>{row.domain}</CaijTableRow>
                  </TableBody>
                </Table>
              </Box>
            ))
          }
        </CardContent>
    </MyCard>
  );
};

EmailDomainInfo.propTypes = {
  schoolEstablishment: PropTypes.object.isRequired
};

export default EmailDomainInfo;
