import React, { useEffect, useReducer } from 'react';
import type { FC } from 'react';
import { Box,Container } from '@mui/material';
import {
  useLocation,
  useParams,
  AccessModel,
  useNavigate,
  root,
  SchoolEstablishmentModel,
  _schoolEstablishment,
  fetchSchoolEstablishment
} from 'src/common';
import Page from 'src/components/Page';
import LoadingScreen from 'src/components/loading/LoadingScreen';
import GenericHeader from 'src/components/header/GenericHeader';
import SchoolEstablishmentEditForm from './SchoolEstablishmentEditForm';

const initialState = {
  isLoading: true,
  schoolEstablishment: {
    name: '',
    validateEmailDomain: false,
    notarialLawMasterOffer: false,
    emailDomains: []
  }
};

const SchoolEstablisshmentEditView: FC = () => {
  const model = new SchoolEstablishmentModel();
  const access = new AccessModel(model.ResourceCode);
  const navigate = useNavigate();
  const location = useLocation();
  const [state, dispatch] = useReducer(_schoolEstablishment, initialState);
  const { id } = useParams();

  useEffect(() => {
    const initialise = async () : Promise<void> => {
      if(access.canEdit(id)){
        const model = new SchoolEstablishmentModel();
        model.PathName =  location.pathname;
        if(id && isNaN(+id)){
          navigate(model.Path.Home);
        }else if (id) {
          const schoolEstablishment = await model.getSchoolEstablishmentById(id);
          if (!model.error) {
            dispatch(fetchSchoolEstablishment(schoolEstablishment, false));
          }
        }else{
          dispatch(fetchSchoolEstablishment(initialState.schoolEstablishment, false));
        }
      }
    }
    (async () => await initialise())();
  }, [id, location]);

  const { schoolEstablishment, isLoading } = state;

  if (isLoading){
    return <LoadingScreen />
  }  

  model.Title = schoolEstablishment.name;

  return (
    <Page sx={root} title={model.getEditPageTitle(id)}>
      <Container maxWidth={false}>
        <GenericHeader id={id} model={model} headerTitle={id ? model.Title : model.BtnAddText} />
        <Box mt={2}>
          <SchoolEstablishmentEditForm
            model={model}
            schoolEstablishment={schoolEstablishment}
          />
        </Box>
      </Container>
    </Page>
  );
};

export default SchoolEstablisshmentEditView;
