import React from 'react';
import {Box,SvgIcon} from '@mui/material';
import {labelConfig, Label} from 'src/common';
import { Eye as EyeIcon } from 'react-feather';
import CaijTypography from 'src/components/typography';
import CaijLabel from 'src/components/label/CaijLabel';
import { FormModel } from 'src/model/Form';

interface IFormModel<F>{
  Searchable: F; 
  Visible: F; 
  IsFrequentlyConsulted?: F;
};

interface IData<R>{
  visible: R;
  searchable: R;
  isFrequentlyConsulted: R;
};

export interface VisibilityInfoProps<T, V> {
  model: T;
  content: V;
};

export default function VisibilityInfo<T extends  Partial<IFormModel<FormModel>>, R extends Partial<IData<boolean>>>({content, model}: VisibilityInfoProps<T, R>) {
  const { visible, searchable, isFrequentlyConsulted } = content;
  return (
    <Box sx={{mb:'2em'}} display='flex'>
      <SvgIcon fontSize='small'>
        <EyeIcon />
      </SvgIcon>
      <Box display='flex' marginLeft='0.5em' flexDirection='column'>
        <Box display='flex' alignItems='center'>
          <CaijTypography sx={{ fontWeight: 'bold' }}>Visibilité</CaijTypography>
        </Box>
        <CaijLabel label={model.Visible.Label}>
          <Label color={visible ? 'success' : 'error'}>
          { visible ? labelConfig.yes : labelConfig.no}
          </Label>
        </CaijLabel>
        <CaijLabel label={model.Searchable.Label}>
          <Label color={searchable ? 'success' : 'error'}>
            { searchable ? labelConfig.yes : labelConfig.no}
          </Label>
        </CaijLabel>
        { model.IsFrequentlyConsulted && (
          <CaijLabel label={model.IsFrequentlyConsulted.Label}>
            <Label color={isFrequentlyConsulted ? 'success' : 'error'}>
              { isFrequentlyConsulted ? labelConfig.yes : labelConfig.no }
            </Label>
          </CaijLabel>
          )
        }
      </Box>
    </Box>
  );
};