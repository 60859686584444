

import { CustomNoRowsOverlayProps } from 'ag-grid-react';

export default function CustomNoRowsOverlay(props: CustomNoRowsOverlayProps & { noRowsMessageFunc: () => string }){
  return (
    <div style={{ backgroundColor: 'background.paper', height: '9%', color: 'red' }}>
      {props.noRowsMessageFunc()}
    </div>
  );
};