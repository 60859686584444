import PropTypes from 'prop-types';
import { FC, ReactNode, useCallback } from 'react';
import { createContext } from 'react';
import useDocumentTypes from 'src/functions/hooks/documentType';

export interface ContentPublicationContextValue {
  getContentTypes: () => JSX.Element[];
}

interface ContentPublicationProviderProps {
  children: ReactNode;
}

const ContentPublicationContext = createContext<ContentPublicationContextValue>({
  getContentTypes: () : JSX.Element[] => []
});

export const ContentPublicationProvider: FC<ContentPublicationProviderProps> = ({ children }) => {
  
  const sDocumentType = useDocumentTypes();
  
  const getContentTypes = useCallback(() : JSX.Element[] => {
    const { allChecked, documentTypes } = sDocumentType;
    if(allChecked) 
      return documentTypes.map(({code, nameFr}) => (
        <option key={code} value={code}>
          {nameFr}
        </option>
      ))       
  },[sDocumentType]);

  return (
    <ContentPublicationContext.Provider
      value={{
        getContentTypes
      }}
    >
      {children}
    </ContentPublicationContext.Provider>
  );
};

ContentPublicationProvider.propTypes = {
  children: PropTypes.any.isRequired
};

export default ContentPublicationContext;
