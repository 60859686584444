import React, { useState, useEffect } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  TableBody,
  Chip
} from '@mui/material';
import {
  ListType,
  getEmptyListSearch,
  ReperageModel,
  formatDateTime,
  ReperageFolderStatus,
  ReperageFolderStatusColor,
  NavigateFunction,
  labelConfig,
  EnhancedTableHead,
} from 'src/common';
import { Paged } from 'src/types/pagination';
import { ReperageResource, SearchFilter, Sort } from 'src/common/types';
import Search from 'src/components/search';
import MyCard from 'src/components/card/MyCard';
import CaijTable from 'src/components/table/CaijTable';
import CaijDialogs from 'src/components/dialog';
import CaijTablePagination from 'src/components/pagination/CaijTablePagination';
import { CaijTableCell } from 'src/components/table/CaijTableCell';
import CaijTableCellAction from 'src/components/table/CaijTableCellAction';
import { CaijTableRowClickable } from 'src/components/table/CaijTableRowClickable';
import useSort from 'src/functions/hooks/sort';

export interface ResultsProps {
  reperages: ReperageResource[];
  paged: Paged;
  isEmptyList: boolean;
  model?: ReperageModel;
  onDeleteReperage: (id: number) => Promise<void>;
  tab: string;
  navigate: NavigateFunction;
  location: {search: string};
}

const Results: FC<ResultsProps> = ({
  reperages,
  paged,
  isEmptyList,
  model,
  onDeleteReperage,
  tab,
  navigate,
  location
}) => {
  const path = model.Path;
  const [selectedRow, setSelectedRow] = useState([]);
  const query = new URLSearchParams(location?.search).get("query");
  const [page, setPage] = useState<number>(0);
  const [filter, setFilter] = useState<SearchFilter>({
    query,
    page: paged?.currentPage - 1,
    sortedBy: '',
    sort: null
  });
  const headCells = model.getHeadCells(labelConfig.status);
  const { order, orderBy, setOrder, setOrderBy, handleRequestSort } = useSort(tab);

  useEffect(() => {
    setOrderBy('folderId');
    setOrder('asc');
  },[tab]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const queryVal = params.get('query');
    const pageVal = params.get('page');
    const sortVal = params.get('sort');
    const sortedByVal = params.get('sortedBy');
    setFilter((prevState: SearchFilter) => {
      return {
        ...prevState,
        query: queryVal,
        page: +pageVal,
        sortedBy: sortedByVal,
        sort: sortVal as Sort
      }
    });
    setPage(+pageVal);
  }, [location]);

  return (
    <MyCard>
      <Box p={2} minHeight={56} display='flex' alignItems='center' justifyContent='space-between' flexGrow={1}>
        <Search
          listType={ListType.Reperage}
          placeholder={`Recherche la/les repérage(s) ${tab === 'in-progress' ? 'en cours' : 'archivés'}`}
          filter={filter}
          width="400px"
          path={path.Home}
        />
      </Box>
      <CaijTable>
        <EnhancedTableHead
          order={order}
          orderBy={orderBy}
          onRequestSort={(e,p) => handleRequestSort<ReperageModel>(e,p,model,filter,navigate)}
          headCells={headCells}
        />
        <TableBody>
          { isEmptyList ? getEmptyListSearch(headCells.length) :
            reperages.map((row: ReperageResource) => (
                <CaijTableRowClickable key={row.id} path={path.getDetail(row.id)}>
                  <CaijTableCell>{row.id}</CaijTableCell>
                  <CaijTableCell>{row.name}</CaijTableCell>
                  <CaijTableCell>{row.createdByLabel}</CaijTableCell>
                  <CaijTableCell>{row.clientLabel}</CaijTableCell>
                  <CaijTableCell>{formatDateTime(row.dateCreated)}</CaijTableCell>
                  <CaijTableCell>{formatDateTime(row.dateModified)}</CaijTableCell>
                  <CaijTableCell>
                    <Chip sx={{ padding: "0 1rem" }} color={ReperageFolderStatusColor[row.status]} label={ReperageFolderStatus[row.status]} />
                  </CaijTableCell>
                  <CaijTableCellAction 
                    pageEditUrl={path.getEdit(row.id)}
                    resourceCode={model.ResourceCode}
                    handleToggle={() => ReperageModel.handleToggle(row.id, selectedRow, setSelectedRow)}
                    disableEdit={row.archived}
                  >
                    <CaijDialogs
                      dialog={model.Dialog}
                      isOpen={selectedRow.indexOf(row.id) !== -1}
                      onSubmit={async () => {
                        await onDeleteReperage(row.id);
                        setSelectedRow([]);
                      }}
                      setSelectedRow={setSelectedRow}
                    />
                  </CaijTableCellAction>
                </CaijTableRowClickable>
              )
            )
          }
        </TableBody>
      </CaijTable>
      <CaijTablePagination 
        paged={paged} 
        model={model} 
        filter={filter} 
        page={page}
        navigate={(model) => navigate(model.getUrlEncode.apply(model, model.getParams()) + (tab ? `#${tab}` : ""))}
      />
    </MyCard>
  );
};

Results.propTypes = {
  reperages: PropTypes.array.isRequired,
  isEmptyList: PropTypes.bool.isRequired,
  onDeleteReperage: PropTypes.func.isRequired,
  model: PropTypes.instanceOf(ReperageModel)
};

export default Results;
