import { Box, CardContent, CardHeader, Table, TableBody, Typography } from "@mui/material";
import { QUICK_ACCESS, SubscriptionModel } from "src/common";
import { SubscriptionGroupResource } from "src/common/types";
import MyCard from "src/components/card/MyCard";
import { CaijTableRow } from "src/components/table/CaijTableRow";

interface QuickAccessProps {
  model: SubscriptionModel;
  group: SubscriptionGroupResource;
}

const accessWidth = {
	width: '130px'
};

const QuickAccess = ({ group, model }: QuickAccessProps) => {
  const { QuickAccessName, QuickAccessUrl } = model;
  return (
    <MyCard>
      <CardHeader title={<Typography variant='h5' sx={{ color: 'text.primary' }}>{QUICK_ACCESS}</Typography>} />
        <CardContent sx={{ pt: 0 }}>
          { group.quickAccess.map((row, index) => (
              <Box sx={{ mt: index != 0 ? 2 : 0, ml: "20px" }}>
                <Typography sx={{ fontWeight: 'bold' }}>{model.getQuickAccessTitle(++index)}</Typography>
                <Table>
                  <TableBody>
                    <CaijTableRow label={QuickAccessName.Label} sxTableCell={accessWidth}>{row.name}</CaijTableRow>
                    <CaijTableRow label={QuickAccessUrl.Label} sxTableCell={accessWidth}>{row.url}</CaijTableRow>
                  </TableBody>
                </Table>
              </Box>
            ))
          }
        </CardContent>
    </MyCard>
  )
};

export default QuickAccess;