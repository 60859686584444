import React, { useState } from 'react';
import type { FC, ChangeEvent } from 'react';
import PropTypes from 'prop-types';
import type { LawHomePageDto, LawResource } from 'src/common/types';
import {
  Box,
  Button,
  Checkbox,
  Grid,
  ListItemText,
  MenuItem,
  SelectChangeEvent
} from '@mui/material';
import {
  CaijCheckbox,
  CaijInput,
  FormikErrors,
  FormikTouched,
  jurisdictionConfig,
  LawType,
  Authorize,
  Mode,
  CaseLaw,
  CaijTextarea,
  LawModel,
  getOptionLabel,
} from 'src/common';
import { $enum } from "ts-enum-util";
import { Edit as EditIcon } from 'react-feather';
import IndexingKeyword from './indexingKeyword';
import LawHomePage from '../lawHomePage';
import CaijMultipleSelectCheckmarks from 'src/components/inputs/CaijMultipleSelectCheckmarks';
import useLawDomainField from 'src/hooks/useLawDomainField';
import { Language } from 'src/enum';

interface LawFormProps {
  model: LawModel;
  law: LawResource;
  errors: FormikErrors<LawResource>;
  touched: FormikTouched<LawResource>;
  handleBlur: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onHandleChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
}

type Compare = [key: string, value: string];

const LawForm: FC<LawFormProps> = ({
  model,
  law,
  errors,
  touched,
  handleBlur,
  onHandleChange,
  setFieldValue,
}) => {
  const [isEditAuth, setIsEditAuth] = useState<boolean>();
  const [open, setOpen] = useState<boolean>();
  const { isAuthorize, isLoading, isEmptyList, lawDomainFields } = useLawDomainField();
  const { Code, Jurisdiction, Type, TitleFr, TitleEn, Abreviation, LawReference, LawReferenceEn, FilterCaseLaw, LawDomain, Published, Annotated, QueryCaseLaw, QueryDoctrine } = model;
  const {
    id,
    code,
    jurisdiction,
    type,
    titleFr,
    titleEn,
    published,
    abreviation,
    filterCaseLaw,
    queryCaseLaw,
    queryDoctrine,
    lawReference,
    lawReferenceEn,
    lawIndexKeywords,
    lawDomain,
    annotated
  } = law;

  const closeDialog = () => setOpen(false)

  const setLawIndexKeyword = (lawIndexKeywords: string[]): void => {
    law.lawIndexKeywords = lawIndexKeywords;
  }

  const getLawHomePage = (lawHomePage: LawHomePageDto, page: string, lang: Language) => {
    if(lawHomePage){
      lawHomePage.page = page;
    }else{ 
      lawHomePage = { lang, lawId: law.id, page };
    }
    return lawHomePage;
  }

  const publish = (pageFr: string, pageEn: string) => {
    let lawHomePages = [];
    if(pageFr){
      lawHomePages.push(getLawHomePage(law.lawHomePages.find(s => s.lang == Language.FR), pageFr, Language.FR));
    }
    if(pageEn){
      lawHomePages.push(getLawHomePage(law.lawHomePages.find(s => s.lang == Language.EN), pageEn, Language.EN));
    }
    if(lawHomePages.length > 0){
      law.lawHomePages = lawHomePages;
    }
  }

  return (
    <>
      { id && (
          <>
          <Grid item md={12} xs={12}>
            <Box textAlign="right">
                <Authorize
                  resourceCode={model.ResourceCode}
                  mode={Mode.edit}
                  onIsAuth={l => setIsEditAuth(l)}
                >
                  <Button
                    disabled={!isEditAuth}
                    startIcon={<EditIcon />}
                    onClick={() => setOpen(true)}
                  >
                    Page d'acceuil
                  </Button>
                </Authorize>
            </Box>
          </Grid>
          <Grid item md={12} xs={12}>
            <CaijCheckbox
              name={Published.Name}
              id={Published.Name}
              checked={published}
              label={Published.Label}
              onHandleChangeCheckBox={() => setFieldValue(Published.Name, !published)}
              inputProps={{ 'data-testid': Published.Name }}
            />
          </Grid>
          </>
        )
      }
      <Grid item md={12} xs={12}>
        <CaijInput
          name={Code.Name}
          id={Code.Name}
          required
          value={code}
          error={Boolean(touched.code && errors.code)}
          helperText={touched.code && errors.code}
          label={Code.Label}
          InputLabelProps={{ shrink: true, required: true }}
          inputAttr={{ maxLength: Code.MaxLength, 'data-testid': Code.Name }}
          onHandleBlur={handleBlur}
          onHandleChange={(e: ChangeEvent<HTMLInputElement>) => {
            const code = e.target.value;
            const codes = Object.assign([], code);
            setFieldValue(Code.Name, codes.join('').toUpperCase());
          }}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <CaijInput
          name={TitleFr.Name}
          id={TitleFr.Name}
          required
          value={titleFr}
          error={Boolean(touched.titleFr && errors.titleFr)}
          helperText={touched.titleFr && errors.titleFr}
          label={TitleFr.Label}
          InputLabelProps={{ shrink: true, required: true }}
          inputAttr={{ maxLength: TitleFr.MaxLength, 'data-testid': TitleFr.Name }}
          onHandleBlur={handleBlur}
          onHandleChange={onHandleChange}
          setFieldValue={setFieldValue}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <CaijInput
          name={TitleEn.Name}
          id={TitleEn.Name}
          required
          value={titleEn}
          error={Boolean(touched.titleEn && errors.titleEn)}
          helperText={touched.titleEn && errors.titleEn}
          label={TitleEn.Label}
          InputLabelProps={{ shrink: true, required: true }}
          inputAttr={{ maxLength: TitleEn.MaxLength, 'data-testid': TitleEn.Name }}
          onHandleBlur={handleBlur}
          onHandleChange={onHandleChange}
          setFieldValue={setFieldValue}
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <CaijCheckbox
          name={Annotated.Name}
          id={Annotated.Name}
          checked={annotated}
          label={Annotated.Label}
          onHandleChangeCheckBox={() => setFieldValue(Annotated.Name, !annotated)}
          inputProps={{ 'data-testid': Annotated.Name }}
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <CaijInput
          required
          label={Jurisdiction.Label}
          name={Jurisdiction.Name}
          onHandleChange={(l) => onHandleChange(l, setFieldValue)}
          select
          value={jurisdiction}
          variant='outlined'
          error={Boolean(touched.jurisdiction && errors.jurisdiction)}
          helperText={touched.jurisdiction && errors.jurisdiction}
          InputLabelProps={{ shrink: true, required: true }}
          inputAttr={{ 'data-testid': Jurisdiction.Name }}
        >
          <option value="" disabled>{getOptionLabel(Jurisdiction.Label, true)}</option>
          {
            jurisdictionConfig && Object.entries(jurisdictionConfig)
              .sort((a: Compare, b: Compare) => a[1].localeCompare(b[1]))
              .map((el) => (
                <option value={el[0]} key={el[0]}>
                  {el[1]}
                </option>
              ))
          }
        </CaijInput>
      </Grid>
      <Grid item md={12} xs={12}>
        <CaijInput
          required
          label={Type.Label}
          name={Type.Name}
          onHandleChange={(l) => onHandleChange(l, setFieldValue)}
          select
          value={type}
          variant='outlined'
          error={Boolean(touched.type && errors.type)}
          helperText={touched.type && errors.type}
          InputLabelProps={{ shrink: true, required: true }}
          inputAttr={{ 'data-testid': Type.Name }}
        >
          <option value="" disabled>{getOptionLabel(Type.Label)}</option>
          {
            $enum(LawType).map((value: string, key: string) => (
              <option value={value} key={key}>
                {value}
              </option>
            ))
          }
        </CaijInput>
      </Grid>
      <Grid item md={12} xs={12}>
        <CaijMultipleSelectCheckmarks
          required
          label={LawDomain.Label}
          data={!isLoading ? lawDomainFields?.map(({id, nameFr}) => ({id : id.toString(), name: nameFr})) : []}
          onHandleChange={(event: SelectChangeEvent<any>) => setFieldValue(LawDomain.Name, typeof event.target.value === 'string' ? event.target.value.split(',') : event.target.value)}
          values={lawDomain?.map(id => id.toString())}
          sx={{margin: '10px 0 20px 0', width: '50%'}}
        >
          { (!isLoading && isAuthorize && !isEmptyList && lawDomainFields) && lawDomainFields.map(({id, nameFr}) => (
            <MenuItem key={id} value={id.toString()}>
              <Checkbox checked={lawDomain?.findIndex(lawDomainId => lawDomainId.toString() === id.toString()) > -1} />
              <ListItemText primary={nameFr} />
            </MenuItem>
            ))
          }
        </CaijMultipleSelectCheckmarks>
      </Grid>
      <Grid item md={12} xs={12}>
        <CaijInput
          name={Abreviation.Name}
          id={Abreviation.Name}
          value={abreviation}
          error={Boolean(touched.abreviation && errors.abreviation)}
          helperText={touched.abreviation && errors.abreviation}
          label={Abreviation.Label}
          InputLabelProps={{ shrink: true }}
          inputAttr={{ maxLength: Abreviation.MaxLength, 'data-testid': 'abreviation' }}
          onHandleBlur={handleBlur}
          onHandleChange={onHandleChange}
          setFieldValue={setFieldValue}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <CaijInput
          name={LawReference.Name}
          id={LawReference.Name}
          required
          value={lawReference}
          error={Boolean(touched.lawReference && errors.lawReference)}
          helperText={touched.lawReference && errors.lawReference}
          label={LawReference.Label}
          InputLabelProps={{ shrink: true, required: true }}
          inputAttr={{ maxLength: LawReference.MaxLength, 'data-testid': LawReference.Name }}
          onHandleBlur={handleBlur}
          onHandleChange={onHandleChange}
          setFieldValue={setFieldValue}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <CaijInput
          name={LawReferenceEn.Name}
          id={LawReferenceEn.Name}
          required
          value={lawReferenceEn}
          error={Boolean(touched.lawReferenceEn && errors.lawReferenceEn)}
          helperText={touched.lawReferenceEn && errors.lawReferenceEn}
          label={LawReferenceEn.Label}
          InputLabelProps={{ shrink: true, required: true }}
          inputAttr={{ maxLength: LawReferenceEn.MaxLength, 'data-testid': LawReferenceEn.Name }}
          onHandleBlur={handleBlur}
          onHandleChange={onHandleChange}
          setFieldValue={setFieldValue}
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <CaijInput
          label={FilterCaseLaw.Label}
          name={FilterCaseLaw.Name}
          onHandleChange={(l) => onHandleChange(l, setFieldValue)}
          select
          value={filterCaseLaw || ''}
          variant='outlined'
          error={Boolean(touched.filterCaseLaw && errors.filterCaseLaw)}
          helperText={touched.filterCaseLaw && errors.filterCaseLaw}
          InputLabelProps={{ shrink: true }}
          inputAttr={{'data-testid': FilterCaseLaw.Name}}
        >
          <option value=''>{getOptionLabel(FilterCaseLaw.Label, true)}</option>
          {
            $enum(CaseLaw).map((value: string, key: string) => (
              <option value={value} key={key}>
                {value}
              </option>
            ))
          }
        </CaijInput>
      </Grid>
      <Grid item md={12} xs={12}>
        <CaijTextarea
          name={QueryCaseLaw.Name}
          rows='4'
          label={QueryCaseLaw.Label}
          value={queryCaseLaw}
          inputProps={{ 'data-testid': QueryCaseLaw.Name }}
          InputLabelProps={{ shrink: true }}
          onHandleBlur={handleBlur}
          onHandleChange={onHandleChange}
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <CaijTextarea
          name={QueryDoctrine.Name}
          rows='4'
          label={QueryDoctrine.Label}
          value={queryDoctrine}
          inputProps={{ 'data-testid': QueryDoctrine.Name }}
          InputLabelProps={{ shrink: true }}
          onHandleBlur={handleBlur}
          onHandleChange={onHandleChange}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <IndexingKeyword
          model={model}
          lawIndexKeywords={lawIndexKeywords}
          lawId={id}
          setLawIndexKeyword={(l) => setLawIndexKeyword(l)}
        />
      </Grid>
      <LawHomePage
        open={open}
        law={law}
        handleClose={closeDialog}
        publish={(pageFr,pageEn) => {
          publish(pageFr,pageEn);
          closeDialog();
        }}
      />
    </>
  );
};

LawForm.propTypes = {
  law: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  handleBlur: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  onHandleChange: PropTypes.func.isRequired
};

export default LawForm;
